module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'table-chart/base' && templateVars.config ?
				templateVars.config :
				{
	"tooltipOptions": {
		"theme": "tooltipster-table-chart",
		"side": "top",
		"content": "default",
		"trigger": "custom",
		"animationDuration": 0
	}
};