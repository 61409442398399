//базовый класс компонента
const config = require('./config.yaml');
const CBod = require('../../index.js');

/**
 * Реализует логику варианта bod-gallery компонента bod
 */
class CBod_gallery extends CBod {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация событий
	 */
	init() {
		this.$bod = $('.js-bod--gallery');
		// проверка существования компонента
		if (this.$bod.length) {
			// контейнер блока бода
			this.$bodMain = this.$bod.find('.js-bod-main-item');
			// контейнер контента бодов
			this.$bodContentExpandableItem = this.$bod.find('.js-bod-expandable-content');
			// контент бодов (скрытый вначале, раскрывающийся)
			this.$bodContentExpandable = this.$bod.find('.js-bod-expandable-content-inner');
			// Кнопка закрытия раскрывающегося контента
			this.$bodContentExpandableClose = this.$bodContentExpandable.find('.js-bod-expandable-content-close-button');
			// получаем кнопки пагинации
			this.buttons = {
				$all: $('.js-bod-btn'),
				$prev: $('.js-bod-btn--prev'),
				$next: $('.js-bod-btn--next')
			};
			// метод по клику на бода
			this.$bodMain.on('click', $.proxy(this, 'onToggle'));
			// метод по клику на кнопку закрытия
			this.$bodContentExpandableClose.on('click', $.proxy(this, 'onCloseButtonClick'));
			// проверка хэша
			this.checkHash();
			// провешивание обработчика на кнопки пагинации
			this.buttons.$all.on('click', $.proxy(this, 'onClickPagination'));
		}
	}

	/**
	 * Смена состояния объекта
	 */
	onToggle(event) {
		// текущий эелемент
		const $currentItem = $(event.currentTarget);

		if (this.$bod.hasClass('is-freeze')) {
			return false;
		} else {
			this.$bod.addClass('is-freeze');
		}

		// проверка открыт ли элемент
		if ($currentItem.hasClass('is-expand')) {
			//триггер глобального события начала закрытия элемента
			AR.events.emit('onBodGalleryCloseStart', $currentItem);
			// вызов закрытия
			this
				.close($currentItem)
				.then(() => {
					//триггер глобального события окончания закрытия элемента
					AR.events.emit('onBodGalleryCloseEnd', $currentItem);
				});
			// снятие хэша страницы
			this.removeHash();
		} else {
			// раскрытый элемент
			const $expandableItem = this.getActiveBodItem();
			// проверка существования раскрытого элемента
			if ($expandableItem.length) {
				// вызов метода закрытия
				this
					.close($expandableItem)
					.then(() => {
						// промис метода раскрытия
						this.open($currentItem);
					});
			} else {
				//триггер глобального события начала раскрытия элемента
				AR.events.emit('onBodGalleryOpenStart', $currentItem);
				// вызов метода раскрытия текущего элемента
				this
					.open($currentItem)
					.then(() => {
						//триггер глобального события окончания раскрытия элемента
						AR.events.emit('onBodGalleryOpenEnd', $currentItem);
					});
			}
		}
	}

	/**
	 * Метод раскрытия элемента
	 * добавление классов is-expand у эелемнета бодов и у контента,
	 * раскрытие контента, скролл к контенту, простановка слушателя
	 * кнопки ESC по завершению анимации
	 *
	 * @param $object
	 * @return промис анимации
	 */
	open($object) {
		// добавление класса is-expand элементу бода
		$object.addClass('is-expand');
		const $item = $object.parents('.js-bod-item');
		const top = $item.offset().top;
		let left = $item.offset().left;
		let $last = $item;
		$('.js-bod-item').each(function () {
			let $this = $(this);
			if ($this.offset().top == top && $this.offset().left > left) {
				left = $this.offset().left;
				$last = $this;
			}
		});
		$last.after($('.js-bod-expandable-content'));
		// простановка хэша
		this.setHash($object);
		// подмена контента
		this.setContent();
		// добавление класса is-expand контенту
		this.$bodContentExpandable.addClass('is-expand');
		// вызов метода удаление/добавление кликабельности кнопкам пагинации
		this.setPaginationState($object);
		// анимация ракскрытия контента, вначале происходит скролл к контенту, вконце простановка слушателя кнопки ESC
		return $.Velocity.animate(this.$bodContentExpandable, 'slideDown', {
			duration: this.config.bodContentToggleAnimationDuration,
			complete: () => {
				$(document).on('keydown', $.proxy(this, 'onEscKeyDownListener'));
				this.scrollTo(this.$bodContentExpandableItem);

				this.$bod.removeClass('is-freeze');
			}
		});
	}

	/**
	 * Метод закрытия элемента
	 * снятие классов is-expand у эелемнета бодов и у контента,
	 * скрытие контента,
	 * @param $object
	 * @return промис анимации
	 */
	close($object) {
		// снятие класса is-expand у элемента бодов
		$object.removeClass('is-expand');

		// снятие класса is-expand у контента
		this.$bodContentExpandable.removeClass('is-expand');
		// анимация ракскрытия контента, вконце снятие слушателя кнопки ESC
		return $.Velocity.animate(this.$bodContentExpandable, 'slideUp', {
			duration: this.config.bodContentToggleAnimationDuration,
			complete: () => {
				$(document).off('keydown', $.proxy(this, 'onEscKeyDownListener'));
				$('.b-bod-main').before($('.js-bod-expandable-content'));

				this.$bod.removeClass('is-freeze');
			}
		});

	}

	/**
	 * Обработчик события нажатия на кнопку закрытия контента
	 */
	onCloseButtonClick() {
		const $expandedItem = this.$bod.find('.js-bod-main-item.is-expand');
		this.close($expandedItem);
		this.removeHash();
	}

	/**
	 * Получение контента Бодов из текущего бода
	 * @return [объект с параметрами]
	 */
	getContent() {
		// активный бод
		const item = this.getActiveBodItem();
		// контейнер с фотографией бода
		const $itemPhotoContainer = item.find('.js-bod-main-photo');
		// объект с параметрами
		return {
			// аттрибут src для фото
			photoSrc: $itemPhotoContainer.find('img').attr('src'),
			// аттрибут alt для фото
			photoAlt: $itemPhotoContainer.find('img').attr('alt'),
			// html содержимое имени бода
			name: item.find('.js-bod-main-name').html(),
			// html содержимое имени бода
			subname: item.find('.js-bod-main-subname').html(),
			// html содержимое должности бода
			post: item.find('.js-bod-main-post').html(),
			// html содержимое биографии бода
			biography: item.find('.js-bod-main-biography').html(),
			// html содержимое контента бода
			content: item.find('.js-bod-main-text').html()
		};
	}

	/**
	 * Простановка контента Бодов в блок с контентом
	 */
	setContent() {
		// полученный контент бода
		const personData = this.getContent();
		// раскрывающийся блок с контентом
		const $itemExp = this.$bod.find('.js-bod-expandable-content');
		// простановка аттрибута src для фото
		$itemExp
			.find('.js-bod-expandable-content-photo')
			.attr('src', personData.photoSrc);
		// простановка аттрибута alt для фото
		$itemExp
			.find('.js-bod-expandable-content-photo')
			.attr('alt', personData.photoAlt);
		// простановка содержимого имени бода
		$itemExp
			.find('.js-bod-expandable-content-name')
			.html(personData.name);
		// простановка содержимого имени бода
		$itemExp
			.find('.js-bod-expandable-content-subname')
			.html(personData.subname);
		// простановка содержимого должности бода
		$itemExp
			.find('.js-bod-expandable-content-post')
			.html(personData.post);
		// простановка содержимого контента бода
		$itemExp
			.find('.js-bod-expandable-content-biography')
			.html(personData.biography);
		$itemExp
			.find('.js-bod-expandable-content-text')
			.html(personData.content);
	}

	// слушатель нажатия кнопки ESC
	onEscKeyDownListener(event) {
		// проврека нажатия кнопки ESC
		if (event.keyCode == 27) {
			this.onCloseButtonClick();
		}
	}

	/**
	 * Метод, инициализирующий удаление/добавление кликабельности кнопкам пагинции
	 * @param {[object]} $bodItem [следующий блок от текущего]
	 */
	setPaginationState($bodItem) {
		// Получаем блок с бодом следующего элемента
		const $bodCol = $bodItem.closest('.js-bod-item');
		// Удаляем с обеих кнопок пагинации атрибут - disabled
		this.buttons.$all.removeAttr('disabled', 'disabled');
		// Если следующего элемента от активного элемента не существует, при нажатии кнопки назад
		if (!$bodCol.prev('.js-bod-item').length) {
			// Добавить кнопке - prev атрибут - disable
			this.buttons.$prev.attr('disabled', 'disabled');
		// Иначе, если следующего элемента от активного элемента не существует, при нажатии кнопки вперед
		} else if (!$bodCol.next('.js-bod-item').length) {
			// Добавить кнопке - next атрибут - disabled
			this.buttons.$next.attr('disabled', 'disabled');
		}
	}

	/**
	 * Метод, вызывающий метод, инициализирующий переключение бодов
	 * @param  {[object]} event [Нажатая кнопка]
	 */
	onClickPagination(event) {
		this.clickPagination(event);
	}

	/**
	 * Метод, инициализирующий переключение бодов
	 * @param  {[object]} event [Нажатая кнопка]
	 */
	clickPagination(event) {
		// Получить блоки с бодами
		const $array = $('.js-bod-item');
		// Получить нажатую кнопку
		const $this = $(event.currentTarget);
		// Получить блоки, у которых есть класс is-expand
		const $activeElem = $('.js-bod-main-block').find('.is-expand');
		let $elem;
		// Если текущая нажатая кнопка имеет класс - js-bod-btn--next
		if ($this.hasClass('js-bod-btn--next')) {
			// Получить следующий элемент в правую сторону, от активного элемента
			const $nextBodItem = $activeElem.closest('.js-bod-item').next();
			// Получить блок с бодом следующего элемента
			$elem = $nextBodItem.find('.js-bod-main-item');
		} else {
			// получить следующий элемент в левую сторону, от активного элемента
			const $prevBodItem = $activeElem.closest('.js-bod-item').prev();
			// Получить блок с бодом следующего элемента
			$elem = $prevBodItem.find('.js-bod-main-item');
		}
		// Вызвать метод, инициализирующий удаление/добавление кликабельности кнопкам пагинции
		this.setPaginationState($elem);
		// Если блок с бодом следующего элемента существует
		if ($elem && $elem.length) {
			// Добавить в адресную строку хеш, из текущего элемента
			window.location.hash = $elem.data('hash');
			// Удалить класс - is-expand с предыдущего элемента
			$activeElem.removeClass('is-expand');
			// Добавить класс is-expand текущему элементу
			$elem.addClass('is-expand');
			// Вызвать метод получения коннета бода
			this.getContent();
			// Вызвать метод простановки контента в блок с контентом
			this.setContent();
		}
	}
}

AR.waitComponents([], () => {
	const cBod_gallery = new CBod_gallery();
	// Вызов метода, инициализирующего все существующие события
	cBod_gallery.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cBod_gallery, 'cBod_gallery');
});
