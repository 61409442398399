const text = require('./text.yaml')[templateVars.lang];
const config = require('./config.yaml');
const CHistory = require('../../index.js');
const dayjs = require('dayjs');
// На странице теста history
const isNotHistoryTest = (global.testComponentName && global.testComponentName !== 'history/base');
// Не нужно отображать history на странице (при служебном запуске сервера)
const noHistory = !isNotHistoryTest && templateVars.noUserInteraction;

require('dayjs/locale/ru');

/**
* Реализует логику варианта base компонента history
*/
class CHistory_base extends CHistory {
	constructor() {
		super();
		this.config = config;
		this.text = text;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$historyWrapper = $('.js-history-wrapper');

		// Очистка истории на страницах тестов
		if (isNotHistoryTest) {
			this.cleanHistory();
		}

		Promise.resolve()
			.then(async () => {
				dayjs.locale(config.locale[templateVars.lang]);

				if (templateVars.page && !templateVars.page.noHistory && !templateVars.page.isSystem && !isNotHistoryTest) {
					await this.initStorage();
					return this.add();
				}
			})
			.then(async () => {
				if (this.$historyWrapper.length) {
					this.$history = this.$historyWrapper.find('.js-history');
					this.$historyMockup = this.$historyWrapper.find('.js-history-mockup');
					this.$button = this.$historyWrapper.find('.js-history-btn');

					await this.checkStorageExist();
					await this.renderList();
				}
			});
	}

	/**
	 * Сгенерить список истории
	 */
	renderList() {
		return this.getLength()
			.then(length => {
				if (length) {
					this.$button.on('click', () => {
						this.cleanHistoryPage();
					});
					return this.showList();
				} else {
					AR.components.cPreloader_base.disablePreloaderInItem(this.$historyWrapper);
					return Promise.resolve();
				}
			});
	}

	/**
	 * Проверка на инициализацию хранилища
	 */
	checkStorageExist() {
		if (!this.localforage) {
			return this.initStorage();
		}

		return Promise.resolve();
	}

	/**
	 * Инициализация хранилища
	 */
	initStorage() {
		return this._getLocalForage()
			.then(localforage => {
				return this.localforage = localforage.createInstance({
					name: `history_${templateVars.site}_${templateVars.lang}`,
					driver: this.getDriver(this.config.driver, localforage) || [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE]
				});
			});
	}

	/**
	 * Получение библиотеки хранилища
	 */
	async _getLocalForage() {
		const lib = await import(/* webpackChunkName: "localforage" */ 'localforage');
		return AR.getEsm(lib);
	}

	/**
	 * Получить драйвера для хранилища
	 * @param {object, string} driver драйвер
	 * @param {object} localforage библиотека хранилища
	 */
	getDriver(driver, localforage) {
		if (Array.isArray(driver)) {
			return driver.map(item => localforage[item]);
		} else if (typeof driver == 'string') {
			return localforage[driver];
		}
		return false;
	}

	/**
	 * Очистить историю
	 */
	cleanHistoryPage() {
		this.cleanHistory()
			.then((e) => {
				this.$historyMockup.find('.js-history-mockup-message').text(this.text.clearedText);

				this.hideElement(this.$history)
					.then(() => this.showElement(this.$historyMockup));

				// this.$history
				// 	.velocity(this.config.animation['hide']['effect'], {
				// 		duration: this.config.animation['hide']['duration'],
				// 		complete: () => {
				// 			this.$historyMockup
				// 				.velocity(this.config.animation['show']['effect'], this.config.animation['show']['duration']);
				// 		}
				// 	});
			});
	}

	/**
	 * Показать элемент (блок мокапа / истории)
	 * @param {object} $elem элемент для показа
	 */
	showElement($elem) {
		return $.Velocity($elem, this.config.animation['show']['effect'], this.config.animation['show']['duration']);
	}

	/**
	 * Скрыть элемент (блок мокапа / истории)
	 * @param {object} $elem элемент для скрытия
	 */
	hideElement($elem) {
		return $.Velocity($elem, this.config.animation['hide']['effect'], this.config.animation['hide']['duration']);
	}

	/**
	 * Загрузить историю
	 */
	showList() {
		return this.getList()
			.then(list => {
				let prevDate = '',
					listCount = -1;
				//Скрыть блок с информацией о пустой истории и показать историю
				this.hideElement(this.$historyMockup)
					.then(() => this.showElement(this.$history));
				// this.$historyMockup.hide();
				// this.$history.show();
				//Вывод истории.
				list.forEach(item => {
					const itemTime = dayjs(item.time);
					const date = itemTime.format(this.config.timeFormat[templateVars.lang]);
					const time = itemTime.format('HH:mm');

					//Если дата текущего элемента отличается от предыдущей, то создать отдельный список и добавить в него первый пункт.
					//Иначе, т.е если дата текущего элемента совпадает с предыдущим, то добавить пункт в последний созданный список.
					if (prevDate != date) {
						this.$history.append(`<h4 class="b-history__date">${date}</h4>
							<ul class="b-history__list no-markers js-history-list"></ul>`);

						listCount++;
					}

					this.$history.find('.js-history-list')
						.eq(listCount)
						.append(`<li class="b-history__item">
							<time class="b-history__time" datetime="${itemTime}">${time}</time>
							<a class="b-history__link" href="${item.link}" title="${item.title}" target="_blank">${item.title}</a>
						</li>`);

					prevDate = date;
				});

				this.$history.addClass('js-history--rendered').triggerHandler('renderComplete');
				AR.components.cPreloader_base.disablePreloaderInItem(this.$historyWrapper);
				return Promise.resolve();
			});
	}

	/**
	 * Добавить элемент в историю
	 */
	add() {
		const time = dayjs();
		const link = templateVars.siteUrl.path;
		const itemId = this.config.outputType === 'all' ? String(time) : link + time.format('DD MMMM YYYY');

		return this.localforage.setItem(itemId, {
			type: 'history',
			site: templateVars.site,
			lang: templateVars.lang,
			title: templateVars.page.shortTitle ? templateVars.page.shortTitle : templateVars.page.title,
			link,
			time: String(time)
		})
			.then(() => {
				return this.checkQuantityOfEls();
			});
	}

	/**
	 * Проверка колчиества элементов в истории, если больше заданного параметра, то лишние удалить
	 */
	checkQuantityOfEls() {
		return this.getLength()
			.then(dbLength => {
				if (dbLength > this.config.maxElements) {
					return this.getList();
				}
			})
			.then(list => {
				if (list) {
					const itemId = list.pop().key;

					return this.removeItem(itemId)
						.then(() => this.checkQuantityOfEls());
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

	/**
	 * Удалить элемент истории
	 * @param {string} key ключ элемента истории
	 */
	removeItem(key) {
		return this.localforage.removeItem(key);
	}

	/**
	 * Очистить историю
	 */
	async cleanHistory() {
		await this.checkStorageExist();
		return this.localforage.clear();
	}

	stringDateToISO(time) {
		return new Date(time).toISOString();
	}

	/**
	 * Получить историю
	 * @return {object} массив значений истории
	 */
	getList() {
		return this.getLength()
			.then(dbLength => {
				var itemsArray = [];
				return this.localforage.iterate((value, key, iterationNumber) => {
					value.key = key;
					itemsArray.push(value);
					if (iterationNumber === dbLength) {
						return itemsArray;
					}
				});
			})
			.then((result) => {
				//Отсортировать по убыванию
				result.sort((a, b) => {
					try {
						if (dayjs(b.time).isAfter(a.time)) {
							return 1;
						} else {
							return -1;
						}
					} catch (err) {
						console.error(err);
					}
					return 0;
				});
				return result;
			});
	}

	/**
	 * Получить длинну истории
	 * @return {number} длинна истории
	 */
	getLength() {
		return this.localforage.length();
	}
}

if (!noHistory) {
	AR.waitComponents(['cPreloader_base'], () => {
		const cHistory_base = new CHistory_base();
		// Вызов метода со всеми событиями
		cHistory_base.init();
		// Добавление в глобальный объект AR.components
		AR.pushComponent(cHistory_base, 'cHistory_base');
	});
}
