module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/horizontal' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": "shortTitle",
	"depthLevel": 3,
	"itemsNum": 9,
	"trackerParamsKeyConfig": "topMenu",
	"hoverable": false,
	"hoverableDelay": 200,
	"colspanSecondLevel": 2,
	"fixedMenu": false,
	"animation": {
		"main": {
			"open": {
				"transition": "transition.slideLeftIn",
				"duration": 200
			},
			"close": {
				"transition": "fadeOut",
				"duration": 200
			}
		},
		"inner": {
			"open": {
				"toLeft": {
					"transition": "fadeIn",
					"duration": 200
				},
				"toRight": {
					"transition": "fadeIn",
					"duration": 200
				}
			},
			"close": {
				"transition": "fadeOut",
				"duration": 200
			}
		}
	}
};