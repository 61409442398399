/**
 * Класс реализует базовую логику для компонента logo
 */
class CLogo {
	constructor() {

	}
}

module.exports = CLogo;
