module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'search-form/result' && templateVars.config ?
				templateVars.config :
				{
	"searchMessageBottom": true,
	"speechRecognition": false,
	"animation": {
		"message": {
			"show": {
				"effect": "transition.slideDownIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideUpOut",
				"duration": 200
			}
		}
	}
};