import inliner from 'svg-style-inliner';
const Promise = require('bluebird');
const AR = require('component/core/.variant/base/index.js');
const {throttle, debounce} = require('throttle-debounce');
//базовый класс компонента
const CSvg = require('../../index.js');
//Класс расчета размеров
const Sizes = require('./Sizes.js');
const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
class CSvg_base extends CSvg {
	constructor() {
		super();
		this.config = config;
		//Создание экземпляра класса расчета размеров
		this.sizes = new Sizes;
		//Вызвать событие resize
		this.triggerResize = debounce(500, () => {
			$(window).trigger('resize');
		});
	}

	/**
	 * Инициализация рендера
	 */
	init() {
		//Рендер SVG
		this.renderSvg();

		//На событие pageUpdate вызвать рендер SVG
		$(window).on('pageUpdate', this.renderSvg);
	}

	/**
	 * Получение кода SVG
	 * @param  {String} filename Имя файла SVG
	 * @return {Object}          Код SVG
	 */
	getSvg(filename) {
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: filename,
				dataType: 'html'
			})
				.done(resolve)
				.fail(reject);
		});
	}

	/**
	 * Выставить размеры SVG
	 * @param {Object} $svg   SVG
	 * @param {Number} width  Ширина
	 * @param {Number} height Высота
	 */
	setSvgSize($svg, width, height) {
		// Если передана ширина или высота
		if (width || height) {
			// Если не передана ширина или высота
			if (!width || !height) {
				// Получить размеры для сторон
				const sizes = this.sizes.getFittedSvgSize($svg, {width, height});

				width = sizes.width;
				height = sizes.height;
			}

			// Выставить размеры
			$svg.attr('width', width);
			$svg.attr('height', height);
		}
	}

	/**
	 * Продублировать параметры размеров в стили (для IE)
	 * @param  {Object} $elem Контейнер SVG
	 */
	sizeToStyles($elem) {
		let width = $elem.attr('width');
		let height = $elem.attr('height');

		//Если значение ширины задано без единиц измерения
		if (Number(width) == width) {
			//Добавление единиц измерения
			width = width + 'px';
		}

		//Если значение высоты задано без единиц измерения
		if (Number(height) == height) {
			//Добавление единиц измерения
			height = height + 'px';
		}

		//Выставление размеров
		$elem
			.css('width', width)
			.css('height', height);
	}

	/**
	 * Добавиить font-weight
	 * @param {Object} $svg SVG
	 */
	setFontWeight($svg) {
		$svg.find('[font-family]').each((i, elem) => {
			const $this = $(elem);
			const fontFamily = $this.attr('font-family');
			const currentWeight = $this.attr('font-weight');

			if (fontFamily && !currentWeight) {
				const fontWeights = this.config['fontWeights'];
				const expValue = fontWeights.map(weightObj => {
					return weightObj.entryPart;
				}).join('|');
				const regExp = new RegExp(expValue, 'i');
				let matchedWeight = fontFamily.match(regExp);
				let resultWeight = 400;

				if (matchedWeight) {
					matchedWeight = matchedWeight[0].toLowerCase();

					fontWeights.forEach(weightObj => {
						if (weightObj.entryPart == matchedWeight) {
							resultWeight = weightObj.weight;
						}
					});
				}

				$this.attr('font-weight', resultWeight);
			}
		});
	}

	fixIERenderSvg($loadedSvgs) {
		const randomValue = Math.random() * (0.1 - 0.01) + 0.01;
		const randomParam = Math.round(Math.random() * (4 - 1) + 1);
		let param = '';

		switch (randomParam) {
			case 1:
				param = 'margin-left';
				break;
			case 2:
				param = 'margin-right';
				break;
			case 3:
				param = 'margin-top';
				break;
			case 4:
				param = 'margin-bottom';
				break;
			default:
				param = 'margin-left';
				break;
		}

		$loadedSvgs.each((i, item) => {
			$(item).css(param, `${randomValue}px`);
			$(item).parent().css(param, `${randomValue}px`);
		});
	}

	/**
	 * Рендер SVG
	 */
	renderSvg() {
		const loadedSvgs = [];
		const svgArray = $('[data-svg]').toArray();
		const promiseArray = [];

		return Promise.resolve(svgArray).each(elem => {
			return promiseArray.push(new Promise((resolve, reject) => {
				//Блок с дата атрибутом
				const $svgDiv = $(elem);
				//Путь к файлу SVG
				const svgFilename = $svgDiv.attr('data-svg');
				//Путь к файлу PNG
				const pngFilename = svgFilename.replace('.svg', '.png');


				// Если происходит создание offline версии сайта
				if (AR.offline) {
					//Если есть поддержка SVG
					if (AR.features.svg) {
						//Вывод SVG
						$svgDiv.html(`<img src="${svgFilename}" width="100%">`);
					} else {
						//Вывод PNG
						$svgDiv.html(`<img src="${pngFilename}" width="100%">`);
					}

					resolve();
				} else {
					//Если есть поддержка SVG
					if (AR.features.svg) {
						//Получение кода SVG
						this.getSvg(svgFilename)
							.then((svg) => {
								//Вставка кода SVG в элемент
								$svgDiv.html(svg);
								//SVG
								const $svg = $svgDiv.find('svg');
								//Ширина контейнера SVG
								let divWidth = parseFloat($svgDiv.attr('width'));
								//Высота контейнера SVG
								let divHeight = parseFloat($svgDiv.attr('height'));
								//Необходимо ли выставить фиксированные размеры SVG
								const isFixed = $svgDiv.attr('data-fixed') === 'true';
								//Дублирование размеров контейнера в стили(для IE)
								this.sizeToStyles($svgDiv);

								//Если размеры SVG будут не фиксированными
								if (!isFixed) {
									//Растягивает SVG по ширине внешнего контейнера
									this.sizes.fitSvgSizeToWidth($svgDiv, $svg);
								} else {
									//Выставить размеры SVG
									this.setSvgSize($svg, divWidth, divHeight);
								}
								//Дублирование размеров SVG в стили(для IE)
								this.sizeToStyles($svg);

								const fontWeight = $svgDiv.attr('data-font-weight');
								const fontFamily = $svgDiv.attr('data-font-family');

								// Добавление font-weight
								if (fontWeight == 'true') {
									this.setFontWeight($svg);
								}

								// Замена font-family, заданных инлайном на значение параметра data-font-family
								if (fontFamily) {
									$svg.find('*').each(function () {
										if ($(this).attr('font-family')) {
											$(this).attr('font-family', fontFamily);
										}
									});
								}

								// Обработка повторящиюся градиентных ID в разных SVG
								$svg.find('*').each(function () {
									if ($(this).attr('id')) {
										let id = $(this).attr('id');

										let $similar = $svg.find(`[fill="url(#${id})"]`);
										if ($similar.length) {
											let uniqueID = String(id) + String(Date.now());
											$(this).attr('id', uniqueID);

											$similar.each(function () {
												$(this).attr('fill', `url(#${uniqueID})`);
											});
										}
									}
								});

								//Если нужно все стили при загрузке SVG пихать в инлайн-аттрибуты
								if ($svgDiv.attr('data-inline-styles')) {
									inliner($svg[0]);
								}

								//Вернуть отрендеренный SVG
								return $svg;
							})
							//Вызов события на завершение рендера SVG и его прокидываение
							.then($svg => {
								AR.events.emit('svgRendered', $svg);

								loadedSvgs.push($svg);
							})
							// .then(() => this.triggerResize())
							.then(resolve)
							.catch(err => {
								console.error(err.statusText);

								resolve();
							});
					} else {
						//Вывод PNG
						$svgDiv.html(`<img src="${pngFilename}" width="100%">`);

						resolve();
					}
				}
			})
				.then(() => {
					//Блок с дата атрибутом
					const $svgDiv = $(elem);
					//Путь к файлу SVG
					const svgFilename = $svgDiv.attr('data-svg');
					//Путь к файлу PNG
					const pngFilename = svgFilename.replace('.svg', '.generated.png');

					let $this = $(this);
					let $graphic = $svgDiv.closest('.graphic');
					let $graphicFigcaption = $graphic.children('figcaption');
					let hiddenTitle = '';

					if ($graphicFigcaption.length) {
						hiddenTitle = `<span class="hidden">${$graphicFigcaption.text().trim()}</span>`;
					} else if ($graphic.parents().parent('.content-area').length) {
						hiddenTitle = `<span class="hidden">${$graphic.parents().prevAll(':header:first').text().trim()}</span>`;
					} else {
						hiddenTitle = `<span class="hidden">${$graphic.prevAll(':header:first').text().trim()}</span>`;
					}

					let $graphicParent = $svgDiv.parents('.graphic[data-download-on-mobile]');
					let $chartParent = $svgDiv.parents('.chart[data-download-on-mobile]');
					let mobileOnSVG = $svgDiv.attr('data-download-on-mobile');

					if ($graphicParent.length) {
						if (!$graphicParent.find('.figure-buttons.js-buttons').length) {
							$graphicParent.append('<div class="figure-buttons js-buttons"></div>');
						}

						$graphicParent.find('.js-buttons').append(`<a class="button button--image" target="_blank" download href="${pngFilename}">${text.downloadImage}${hiddenTitle}</a>`);
					} else if ($chartParent.length) {
						if (!$chartParent.find('.figure-buttons.js-buttons').length) {
							$chartParent.append('<div class="figure-buttons js-buttons"></div>');
						}

						$chartParent.find('.js-buttons').append(`<a class="button button--image" target="_blank" download href="${pngFilename}">${text.downloadImage}${hiddenTitle}</a>`);
					} else if (mobileOnSVG) {
						if (!$svgDiv.find('.figure-buttons.js-buttons').length) {
							$svgDiv.append('<div class="figure-buttons js-buttons"></div>');
						}

						$svgDiv.find('.js-buttons').append(`<a class="button button--image" target="_blank" download href="${pngFilename}">${text.downloadImage}${hiddenTitle}</a>`);
					}
				}));
		})
			.then(() => {
				Promise.all(promiseArray).then(() => {
					const $loadedSvgs = $(loadedSvgs);
					AR.events.emit('svgRenderedAll', $loadedSvgs);
					$loadedSvgs.addClass('svg-rendered');

					if ($('html').hasClass('browser-ie')) {
						$(window).on('scroll', throttle(50, () => this.fixIERenderSvg($loadedSvgs)));

						setTimeout(() => {
							this.fixIERenderSvg($loadedSvgs);
						}, 450);
					}
				});
			});
	}
}

const cSvg_base = new CSvg_base();

cSvg_base.init();

AR.pushComponent(cSvg_base, 'cSvg_base');
