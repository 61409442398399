module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'history/base' && templateVars.config ?
				templateVars.config :
				{
	"outputType": "unique",
	"maxElements": 200,
	"timeFormat": {
		"ru": "dddd, D MMMM YYYY",
		"en": "dddd, D MMMM YYYY"
	},
	"animation": {
		"show": {
			"effect": "fadeIn",
			"duration": 300
		},
		"hide": {
			"effect": "fadeOut",
			"duration": 300
		}
	},
	"locale": {
		"ru": "ru",
		"en": "en"
	}
};