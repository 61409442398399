/**
 * Класс реализует базовую логику для компонента Notification
 */
class CNotification {
	constructor() {

	}
}

module.exports = CNotification;
