//Инструменты для работы с SVG
const Tools = require('./Tools.js');

/**
 * Класс реализует базовую логику для компонента SVG
 */
class CSvg {
	constructor() {
		//Создание экземпляра класса инструментов
		this.tools = new Tools;
	}
}

module.exports = CSvg;
