module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/vertical-expandable' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"titleById"
	],
	"showFromSecondLevel": false,
	"depthLevel": 2,
	"hoverable": false,
	"hoverableDelay": 200,
	"fixedMenu": false,
	"animation": {
		"open": {
			"transition": "transition.slideLeftIn",
			"duration": 300
		},
		"close": {
			"transition": "transition.slideLeftOut",
			"duration": 300
		}
	}
};