/**
 * Класс реализует базовую логику для компонента mobile-menu
 */
class CMobileMenu {
	constructor() {

	}
}

module.exports = CMobileMenu;
