const $search = $('.search.search--result');
const config = require('./config.yaml');
const CSearchForm = require('../../index.js');

/**
 * Реализует логику варианта result компонента search-form
 */
class CSearchForm_result extends CSearchForm {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$searchForm = $('.js-search--result');

		if (this.$searchForm.length) {
			this.$searchForm.each((i, elem) => {
				const $form = $(elem);
				const formObj = {
					$form: $form,
					$searchInput: $form.find('.js-search-input'),
					$searchMessage: $form.find('.js-search-message')
				};

				if (this.config.speechRecognition && $('html').hasClass('feature-speechrecognition')) {
					formObj.$searchSpeechButton = formObj.$form.find('.js-search-button-speech');

					this.initSpeechRecognition(formObj);
				}

				formObj.$searchInput.on('blur', $.proxy(this, 'onBlurInput', formObj));

				formObj.$form.on('submit', $.proxy(this, 'onFormSubmit', formObj));
			});
		}
	}

	/**
	 * Callback на расфокус поля ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие расфокуса
	 */
	onBlurInput(formObj, event) {
		this.setBlurState(formObj, event);
	}

	/**
	 * Установить поле поиска в состояние расфокуса
	 * Н: Очистить текст запроса от пробелов по краям
	 * @param {object} formObj объект формы
	 */
	setBlurState(formObj) {
		const inputText = formObj.$searchInput.val();

		if (inputText.length) {
			formObj.$searchInput.val(this.trimText(inputText));
		}
	}

	/**
	 * На ввод текста в поле поиска при необходимой длинне запроса
	 * скрыть сообщение о коротком запросе и удалить слушателя события на ввод текста в поле поиска
	 * @param {object} formObj объект формы
	 */
	onInputTyping(formObj, event) {
		super.onInputTyping(formObj, event);
	}

	/**
	 * Показать сообщение о том, что запрос слшиком короткий
	 * @param {string} formObj объект формы
	 */
	showMessage(formObj) {
		super.showMessage(formObj);
		$(document).on('click', $.proxy(this, 'onDocumentListenerMessage', formObj));
	}

	/**
	 * Скрыть сообщение о том, что запрос слшиком короткий
	 * @param {string} formObj объект формы
	 */
	hideMessage(formObj) {
		super.hideMessage(formObj);
		$(document).off('click', $.proxy(this, 'onDocumentListenerMessage'));
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * @param {string} formObj объект формы
	 * @param {object} event событие клика на документ
	 */
	onDocumentListenerMessage(formObj, event) {
		if (!$(event.target).closest(formObj.$form).length) {
			this.hideMessage(formObj);
		}
	}
}

AR.waitComponents([], () => {
	const cSearchForm_result = new CSearchForm_result();
	// Вызов метода, инициализирующего все существующие события
	cSearchForm_result.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSearchForm_result, 'cSearchForm_result');
});
