const config = require('./config.yaml');
const CPageNav = require('../../index.js');

/**
 * Реализует логику варианта buttons компонента page-nav
 */
class CPageNav_buttons extends CPageNav {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$pagenavButtons = $('.js-pagenav-buttons');
		if (this.$pagenavButtons.length) {
			this.$upBtn = this.$pagenavButtons.find($('.js-scrollup'));
			this.$pagenavLink = this.$pagenavButtons.find($('.js-pagenav-link'));
			this.scrollTopValue = this.config.scrollTopValue;
			this.toggleShowUpButton();
			$(window).on('scroll', $.proxy(this, 'toggleShowUpButton'));
			this.$upBtn.on('click', $.proxy(this, 'scrollUp'));
			// $(document).on('keydown', $.proxy(this, 'checkKeyDown'));
			// $(document).on('touchstart', $.proxy(this, 'removeTouchClass'));
			// this.$upBtn.on('touchstart', $.proxy(this, 'touchStart'));
			// this.$pagenavLink.on('touchstart', $.proxy(this, 'touchStart'));
			this.addHelpKey();
		}
	}

	/**
	 * Установить состояние кнопки наверх
	 */
	toggleShowUpButton() {
		let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
		let disabledClass = 'b-pagenav__link--disabled';

		if (scrollTop < this.scrollTopValue && !this.$upBtn.hasClass(disabledClass)) {
			this.$upBtn.addClass(disabledClass);
		} else if (scrollTop > this.scrollTopValue && this.$upBtn.hasClass(disabledClass)) {
			this.$upBtn.removeClass(disabledClass);
		}

		if ($('.l-page__nav-controls').length) {
			if (scrollTop > $('.l-page__nav-controls').offset().top) {
				this.$pagenavButtons.addClass('fixed');
			} else {
				this.$pagenavButtons.removeClass('fixed');
			}
		}
	}

	/**
	 * Скрол в начало страницы
	 */
	scrollUp() {

		if (Boolean(window.bodyInstance)) {
			window.bodyInstance.scroll(0, 500);
		} else {
			$('html').velocity('scroll', {axis: '0'});

		}
	}

}

AR.waitComponents([], () => {
	const cPageNav_buttons = new CPageNav_buttons();
	// Вызов метода, инициализирующего все существующие события
	cPageNav_buttons.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPageNav_buttons, 'cPageNav_buttons');
});
