/**
 * Класс реализует базовую логику для компонента social-services
 */
class CSocialServices {
	constructor() {

	}
}

module.exports = CSocialServices;
