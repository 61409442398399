/**
 * Класс реализует базовую логику для компонента download-center
 */
class CMyReport {
	constructor() {}

	init() {}
}

module.exports = CMyReport;

AR.waitComponents([], () => {
	const cMyReport = new CMyReport();
	// Вызов метода со всеми событиями
	cMyReport.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMyReport, 'cMyReport');
});
