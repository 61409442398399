class CStrategyEvolution_base {
	constructor() {}

	init() {
		this.page = $('.body--s-e-base');

		if (this.page.length) {
			AR.events.on('svgRenderedAll', () => {
				let animateSvg = function () {
					let $blocks = $('.js-strat');
					let windowH = document.documentElement.clientHeight;
					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper').height();

					$blocks.each((i, el) => {

						let $block = $(el);
						let offsetTop = $block.offset().top;

						if (!$block.hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
								$block.addClass('block-show');
								$block.velocity('transition.slideDownBigIn');
							}
						}

						let check;

						if ($('html').hasClass('browser-ie')) {
							$blocks.each(function () {
								check = $(this).hasClass('block-show');
							});
						} else {
							check = [...$blocks].every(el => {//IE не переваривает такие конструкции
								//console.log(el.classList);
								return el.classList.contains('block-show');
							});
						}

						if (check) {
							$(window).off('scroll mousewheel', animateSvg);
						}
					});
				};

				$(window).on('scroll mousewheel', animateSvg);

				this.creatTooltips();

			});
		}
	}

	creatTooltips() {
		let tooltips = [
			{
				btn: '.horizons-1-btn',
				content: '#js-tool-cont__horizons-1-btn'
			},
			{
				btn: '.horizons-2-btn',
				content: '#js-tool-cont__horizons-2-btn'
			},
			{
				btn: '.horizons-3-btn',
				content: '#js-tool-cont__horizons-3-btn'
			},
		];

		$.each(tooltips, function (i, tool) {
			AR
				.components
				.cTooltips_base
				.createTooltips($(tool.btn), {
					side: 'bottom',
					content: $(tool.content),
					functionPosition: function (instance, helper, position) {
						position.coord.top -= 8;
						position.coord.left -= (position.size.width / 2 - 8);
						return position;
					}
				}, false);
		});
	}
}

AR.waitComponents(['cTooltips_base'], () => {
	const cStrategyEvolution = new CStrategyEvolution_base();

	// Вызов метода, инициализирующего все существующие события
	cStrategyEvolution.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cStrategyEvolution, 'cStrategyEvolution');
});
