const config = require('./config.yaml');
const CMobileMenu = require('../../index.js');
const {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} = require('body-scroll-lock');

/**
 * Реализует логику варианта base компонента verical-accordeon
 */
class CMobileMenu_verticalAccordeon extends CMobileMenu {
	constructor() {
		super();
		this.config = config;
	}

	initScrollbars() {
		this.$mainMenuPanel = $('.js-mobile-menu-panel');
		this.mainMenuInstance = this.$mainMenuPanel.overlayScrollbars({
			className: 'os-theme-rvc-main-menu',
			overflowBehavior: {
				x: 'hidden',
				y: 'scroll'
			},
		}).overlayScrollbars();
	}

	/**
	 * Инициализация
	 */
	init() {
		// Меню
		this.$menu = $('.js-mobile-menu--vertical-accordion .js-nav--mobile');

		// Если меню на странице существует и необходимо раскрывать и закрывать
		// по клику на управляющий элемент
		if (this.$menu.length) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-nav-link');
			// Управляющий элемент
			this.$linkController = this.$menu.find('.js-nav-controller');
			this.$trigger = $('.js-mobile-menu-burger');
			this.$panel = $('.js-mobile-menu-panel');
			this.$body = $('body');
			this.panelScroll = 0;
			this.defaultRelativeTriggerPos = {};
			// Класс списков (выпадашек)
			this.listClass = '.js-nav-list';
			this.initScrollbars();

			// По клику / тапу на управляющий элемент инициализировать раскрытие
			this.$linkController.on('click', $.proxy(this, 'toggleMenuItem'));

			this.$trigger.on('click', $.proxy(this, 'togglePanel'));
		}
	}

	/**
	 * Получить текущие координаты бургера
	 */
	getTriggerPos() {
		const position = {};

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			if (this.config.triggerPosInPanel[key]) {
				const scrollTop = $(window).scrollTop();
				let triggerPropValue;

				if (key == 'right' && !this.config.triggerPosInPanel['left']) {
					triggerPropValue = $(window).width() - (this.$trigger[0].getBoundingClientRect().right);
				} else if (scrollTop && key == 'top') {
					triggerPropValue = this.$trigger.offset()[key] - scrollTop;
				} else {
					triggerPropValue = this.$trigger.offset()[key];
				}

				position[key] = triggerPropValue ? triggerPropValue : '';
			}
		});

		return position;
	}

	/**
	 * Получить дефолтное состояние бургера (при закрытой панели меню)
	 */
	getDefaultTriggerPos() {
		const position = {};

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			position[key] = this.$trigger.css(key);
		});

		return position;
	}

	/**
	 * Получить разницу координат бургера между текущим состоянием (при открытой панели меню)
	 * и дефолтным состоянием (закрытый)
	 */
	getDiffPos() {
		const position = {};
		const relativePos = this.getTriggerPos();

		Object.keys(this.config.triggerPosInPanel).forEach(key => {
			position[key] = relativePos[key] - this.defaultTriggerPos[key];
		});

		return position;
	}

	/**
	 * Переключение состояния списков
	 * @param {Object} event Событие
	 */
	togglePanel(event) {
		const $this = $(event.currentTarget);

		if ($this.hasClass('is-expand')) {
			this.closePanel();
		} else {
			this.openPanel();
		}
	}

	/**
	 * Оставлять бургер на своем месте при скроле при раскрытой панели меню
	 */
	scrollFreezeBurger() {
		this.panelScroll = this.$panel.scrollTop();
		const burgerTopValue = this.triggerTop - this.panelScroll;
		this.$trigger.css('top', burgerTopValue);
	}

	/**
	 * Открыть панель с меню
	 */
	openPanel() {
		if (window.innerWidth < 799) {
			disableBodyScroll(document.querySelector('#layout-wrapper'));
			let start, end, move;
			let that = this;
			let getScreenYbyE = function (e) {
				if (e.touches.length == 0) {
					return false;
				}
				return Boolean(e.touches) ? e.touches[0].screenY : e.targetTouches[0].screenY;
			};
			document.addEventListener('touchstart', function (e) {
				start = getScreenYbyE(e);
			});
			document.addEventListener('touchend', function (e) {
				end = getScreenYbyE(e);
				if (!end) {
					return;
				}
				move = start - end;
				let scroll;
				if (move > 0) {
					scroll = '+=' + move;
				} else {
					scroll = '-=' + Math.abs(move);
				}
				that.mainMenuInstance.scroll({y: scroll});
			});
			document.addEventListener('touchmove', function (e) {
				end = getScreenYbyE(e);
				if (!end) {
					return;
				}
				move = start - end;
				let scroll;
				if (move > 0) {
					scroll = '+=' + move;
				} else {
					scroll = '-=' + Math.abs(move);
				}
				that.mainMenuInstance.scroll({y: scroll});
				start = end;
			}, {
				passive: false
			});
		}
		this.$panel
			.addClass('is-expand')
			.velocity(this.config.animation.panel.open.transition, {
				duration: this.config.animation.panel.open.duration,
				begin: () => {
					this.$body.css('overflow', 'hidden');

					if (this.config.triggerPosInPanel) {
						const triggerPosition = $.extend({}, this.config.triggerPosInPanel);
						this.defaultRelativeTriggerPos = this.getDefaultTriggerPos();
						this.defaultTriggerPos = this.getTriggerPos(this.$trigger);
						Object.keys(triggerPosition).forEach((key) => !triggerPosition[key] && delete triggerPosition[key]);

						if (this.panelScroll && triggerPosition.top) {
							triggerPosition.top = parseFloat(triggerPosition.top) - this.panelScroll;
						}

						this.$trigger
							.css(this.defaultTriggerPos)
							.velocity(triggerPosition, this.config.animation.panel.open.duration);
					}

					this.$trigger.addClass('is-expand');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenStart', this.$panel);
				},
				complete: () => {
					this.triggerTop = (this.config.triggerPosInPanel.top ? this.$trigger.position().top : 0) + this.panelScroll;
					this.$panel.on('scroll', $.proxy(this, 'scrollFreezeBurger'));

					AR.events.emit('onMobileMenuVerticalAccordeonPanelOpenEnd', this.$panel);
				}
			});
	}

	/**
	 * Закрыть панель с меню
	 */
	closePanel() {
		clearAllBodyScrollLocks();
		this.$panel
			.removeClass('is-expand')
			.velocity(this.config.animation.panel.close.transition, {
				duration: this.config.animation.panel.close.duration,
				begin: () => {
					if (this.config.triggerPosInPanel) {
						const position = this.getDiffPos();

						this.$trigger
							.removeClass('is-expand')
							.css(position)
							.velocity(this.defaultRelativeTriggerPos, this.config.animation.panel.close.duration);
					} else {
						this.$trigger.removeClass('is-expand');
					}

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseStart', this.$panel);
				},
				complete: () => {
					this.$trigger
						.velocity('stop', true)
						.removeAttr('style')
						.removeClass('is-expand');
					this.$body.css('overflow', '');

					AR.events.emit('onMobileMenuVerticalAccordeonPanelCloseEnd', this.$panel);
				}
			});

		this.$panel.off('scroll', $.proxy(this, 'onScrollFreezeBurger'));
	}

	/**
	 * Переключение состояния списков
	 * @param {Object} event Событие
	 */
	toggleMenuItem(event) {
		const $this = $(event.currentTarget);

		// Если списки закрываемы и список у текущего пункта не раскрыт
		if ($this.hasClass('is-expand')) {
			event.preventDefault();
			if (this.config.hideInnerOnClose) {
				// Раскрытые списки внутри текущего
				const $expandedElements = $this
					.closest('.js-nav-item')
					.find(`.js-nav-controller.is-expand`);
				// Закрыть списки
				this.closeMenuItem($expandedElements, true);
			}
			// Закрыть список
			this.closeMenuItem($this);
		} else if (!$this.hasClass('is-expand')) {
			event.preventDefault();

			// Если необходимо закрывать список на текщуем уровне при открытии другого
			if (this.config.hidePreviousListOnExpand) {
				// Раскрытые списки на текущем уровне
				const $expandedElements = $this
					.closest('.js-nav-item')
					.siblings('.js-nav-item')
					.find(`.js-nav-controller.is-expand`);
				// Закрыть списки
				this.closeMenuItem($expandedElements, true);
			}

			// Открыть список
			this.openMenuItem($this, event);
		}
	}

	/**
	 * Закрытие списка
	 * @param {Object}  $element Контроллер
	 */
	openMenuItem($element) {
		$element
			.addClass('is-expand')
			.siblings('.js-nav-link')
			.addClass('is-expand')
			.closest('.js-nav-item')
			.children(this.listClass)
			.addClass('is-expand')
			.velocity(this.config.animation.menuItem.open.transition, {
				duration: this.config.animation.menuItem.open.duration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit('onMobileMenuVerticalAccordeonOpenStart', $element),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit('onMobileMenuVerticalAccordeonOpenEnd', $element)
			});
	}

	/**
	 * Закрытие списка
	 * @param {Object}  $element     Контроллер
	 * @param {Boolean} closeForNext Делается ли закрытие списка перед открытием следующего
	 */
	closeMenuItem($element, closeForNext) {
		$element
			.removeClass('is-expand')
			.siblings('.js-nav-link')
			.removeClass('is-expand')
			.closest('.js-nav-item')
			.children(this.listClass)
			.removeClass('is-expand')
			.velocity(this.config.animation.menuItem.close.transition, {
				duration: this.config.animation.menuItem.close.duration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit('onMobileMenuVerticalAccordeonCloseStart', $element),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit('onMobileMenuVerticalAccordeonCloseEnd', $element)
			});
	}
}

AR.waitComponents([], () => {
	const cMobileMenu_verticalAccordeon = new CMobileMenu_verticalAccordeon();
	// Вызов метода, инициализирующего все существующие события
	cMobileMenu_verticalAccordeon.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMobileMenu_verticalAccordeon, 'cMobileMenu_verticalAccordeon');
});
