/**
 * Класс реализует базовую логику для компонента Glossary
 */
class CGlossary {
	constructor() {

	}
}

module.exports = CGlossary;
