class CKeyEvents_base {
	constructor() {}

	init() {
		this.page = $('.body--key-events');

		if (this.page.length) {

			$('.js-svg-hide').css({opacity: 0});

			AR.events.on('svgRenderedAll', () => {
				let windowH = document.documentElement.clientHeight;
				let windowW = document.documentElement.clientWidth;

				$('.js-svg-hide').css({opacity: 1});

				let $blocks;

				if (windowW > 798) {
					$blocks = $('.hide-sm .js-accel--highlights');
				} else {
					$blocks = $('.show-sm .js-accel--highlights');
				}

				$blocks.css({opacity: 0});

				$($blocks[0]).addClass('block-show');
				$($blocks[0]).velocity('transition.fadeIn');

				$($blocks[1]).addClass('block-show');

				$($blocks[1]).velocity('transition.slideDownBigIn', {delay: 200});

				function onScroll() {
					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper').height();

					$blocks.each((i, block) => {
						let offsetTop = $(block).offset().top;

						if (!$(block).hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
								$(block).addClass('block-show');
								$(block).velocity('transition.slideDownBigIn');
							}
						}
					});

					let check = [...$blocks].every(el => {
						return el.classList.contains('block-show');
					});

					if (check) {
						$(window).off('scroll', onScroll);
					}
				}

				$(window).on('scroll', onScroll);
			});
		}
	}
}

AR.waitComponents([], () => {
	const cKeyEvents_base = new CKeyEvents_base();

	// Вызов метода, инициализирующего все существующие события
	cKeyEvents_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cKeyEvents_base, 'cKeyEvents_base');
});

// module.exports = CKeyEvents_base;
