module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'old-browser/base' && templateVars.config ?
				templateVars.config :
				{
	"buttonClass": "b-noty__close",
	"isStatic": false,
	"browsers": {
		"Chrome": {
			"version": 67
		},
		"Opera": {
			"version": 56
		},
		"Firefox": {
			"version": 64
		},
		"Safari": {
			"version": 11
		},
		"Microsoft Edge": {
			"version": 17
		},
		"IE": {
			"version": 10
		}
	},
	"closableOld": true,
	"closableIe": true
};