import config from './config.yaml';
import CSlider from '../../index.js';

/**
 * Реализует логику варианта side-зagination компонента slider
 */
class CSlider_multi extends CSlider {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.sliderWrapper = document.querySelector(`.js-slider-wrapper--${config.name}`);
		this.subSliders = {};

		if (this.sliderWrapper) {
			this.initSlider(this.sliderWrapper)
				.then(() => {
					this.initSubSliders();
					this.goToSlideByHash();
				});
		}

		AR.events.on('onInitIsMain', () => {
			$('.js-slider').css({top: '100%'});
			let $e1 = $('.b-slider-item__title');
			let $e2 = $('.b-slider-item__link');
			let $e3 = $('.b-slider-item__next');
			let speed = this.config.options.speed;
			var mySequence = [
				{e: $e1, p: {opacity: 1, top: ['0px', $e1.height() + 'px']}, o: {duration: 1500}},
				{e: $e2, p: {opacity: 1, top: ['0px', 3 * $e2.height() + 'px']}, o: {duration: 1500}},
				{e: $e3, p: {opacity: 1, top: ['0px', 0.5 * $e3.height() + 'px']}, o: {duration: 1500}}
			];
			$.Velocity.RunSequence(mySequence);

			// подготовка стилей
			$('.l-slider-pagination-wrapper').css({right: '-3vw', opacity: 0});
			$('.b-slider-item:not(.b-slider-item--0),.js-slider-button--prev,.js-slider-button--next,.l-header-top-nav').css({opacity: 0});
			$('.l-slider-pagination-wrapper').removeClass('show').css({right: '-3vw'});
			$('body').css({overflow: 'hidden'});

			// убираем первый псевдослайд
			$('.b-slider-item--0, .b-slider-item__next-wrapper').on('mousewheel.myInit scroll.myInit click.myInit wheel.myInit', () => {
				$('.b-slider-item--0, .b-slider-item__next-wrapper').off('.myInit');

				setTimeout(() => {//IE
					$('.b-slider-item--0')
						.css({'z-index': 0});
				});

				$('.b-slider-item--0')
					.velocity({top: '-100%'}, {duration: speed, complete: () => {
						//$('.b-slider-item--0').css({left: '100%'});
					}});

				$('.b-slider-wrapper__image-wrapper--int')
					//.css({transform: 'translateY(-90px)'})
					.velocity({top: ['0%', '100%']}, {duration: speed});
				$('.js-slider').addClass('show').velocity({top: '0%'}, {duration: speed});

				// хедер
				let $header = $('.l-header-top-line');
				$header.css({transition: 'none'}).velocity({translateY: ['0px', window.innerHeight + 'px'], opacity: 1}, {duration: speed});

				let index = this.swiper.realIndex;
				this.animateSlideContent($('[data-slide-id="' + index + '"].js-slider-item'));

				if (window.innerWidth <= 768) {
					$('.l-layout-wrapper__header').velocity({
						opacity: 1,
						translateY: ['0px', window.innerHeight + 'px']
					}, {duration: speed});
					$('.js-slider-button--prev,.js-slider-button--next').css({opacity: 1});
				}

				$('.b-slider-item--0').off('scroll.initA');


				$(document).on('mousewheel wheel', '.js-slider', (e) => {
					let delta = e.originalEvent.deltaY;
					let index = (this.swiper.realIndex);
					if (index > 0) {
						$('.b-slider-item--0').css({left: '100%'});
					}

					if (this.swiper && this.swiper.mousewheel.enabled) {
						if (delta > 0) {
							this.swiper.slideNext();
						} else {
							this.swiper.slidePrev();
						}
					}
					return;
				});
			});
			window.bodyInstance.destroy();
		});

		AR.events.on('onSlideChangeTransitionStartIsMain', () => {
			let index = (this.swiper.realIndex);
			let previousIndex = this.swiper.previousIndex;
			let $last = $('[data-slide-id="' + previousIndex + '"]:not(.js-sub-slider-item)');
			let $slide = $('[data-slide-id="' + index + '"]:not(.js-sub-slider-item)').css({opacity: 1});
			let $footer = $('.l-layout-wrapper__footer');
			let $headerWrapper = $('.l-layout-wrapper__header');
			let $image = $('.b-slider-wrapper__image-wrapper--int');

			let lastAnimate = {opacity: [0, 1]};
			let slideAnimate = {};
			let speed = this.config.options.speed;

			this.swiper.mousewheel.enabled = true;
			//анимация контента в слайде
			$('.l-slider-item__inner').css({top: '0px'});
			if (previousIndex < index) {
				$('[data-slide-id="' + previousIndex + '"] .l-slider-item__inner')
					.velocity({top: [-window.innerHeight, 0]}, {duration: speed});
			} else {
				$('[data-slide-id="' + previousIndex + '"] .l-slider-item__inner')
					.velocity({top: [window.innerHeight, 0]}, {duration: speed});
			}

			if ($last.length) {
				//анимация предыдущего слайда
				var translate = this.getTranslateY($last);

				//ЗАСТАВЛЯЕМ ВЕЛОСИТИ ЗАПОМНИТЬ ТЕКУЩЕЕ ЗНАЧЕНИЕ transform И НЕ ТРОГАТЬ ЕГО ПРИ АНИМАЦИИ ДРУГИХ СВОЙСТВ
				$.Velocity.hook($last, 'translateY', translate + 'px'); // Must provide unit type


				$last
					.velocity(lastAnimate, {
						duration: speed,
						complete: () => {
							//console.log($last.css('transform'))
						}
					});
			}

			var translate = this.getTranslateY($footer);
			$.Velocity.hook($footer, 'translateX', '-50%'); // Must provide unit type

			//анимация последнего слайда и футера
			if (index == 5) {
				$('.js-fake-image').show();
				$footer
					.addClass('show')
					.velocity({opacity: [1, 0]}, {duration: speed});
				$('.b-slider-pagination__item,.js-slider-button--prev,.js-slider-button--next').addClass('color-blue');
			} else {
				$image.css({top: '0%'});
				$('.js-fake-image').hide();
				$footer
					.velocity({opacity: [0, 1]}, {duration: speed, complete: () => {
						$footer.removeClass('show');
					}});
				$('.b-slider-pagination__item,.js-slider-button--prev,.js-slider-button--next').removeClass('color-blue');
			}

			$('[data-slide-id="' + index + '"] .l-slider-item__inner').css({top: 0});
			this.animateSlideContent($slide);
		});

		objectFitPolyfill($('.js-object-fit'));

		$(document).on('click', '.b-slider-item__up', function () {
			$(this).parents('.os-viewport').animate({scrollTop: 0});//у велосити нет рабочего аналога
		});

		$('.b-slider-item__next-wrapper').on('click', () => {
			//this.swiper.slideTo(1);
		});

		// скроллбары
		AR.events.on('onRealInitSlider', () => {
			$(window).on('resize', () => {
				if (window.innerWidth <= 510) {
					this.scrollInstance = $('.l-slider-item__inner').css({overflow: 'scroll'}).overlayScrollbars({
						overflowBehavior: {
							x: 'hidden',
							y: 'scroll'
						},
						callbacks: {
							onScroll: function () {
								let $content = $(this.getElements().content);
								if (this.scroll().position.y) {
									$content.find('.b-slider-item__up').show();
								} else {
									$content.find('.b-slider-item__up').hide();
								}
							},
							onInitialized: function () {
								let $content = $(this.getElements().content);
								$content.find('.b-slider-item__up').hide();
							}
						}
					}).overlayScrollbars();
					this.swiper.allowTouchMove = false;
				} else {
					$('.l-slider-item__inner').css({overflow: ''});
					if (this.scrollInstance) {
						$(this.scrollInstance).each(function () {
							this.destroy();
						});

					}
					this.swiper.allowTouchMove = true;
				}

				if ($('.js-slider').hasClass('show')) {
					if (window.innerWidth <= 768) {
						$('.l-layout-wrapper__header').css({
							opacity: 1,
							transform: 'translateY(0%)'
						});
						$('.js-slider-button--prev,.js-slider-button--next').css({opacity: 1});
					} else {
						$('.l-layout-wrapper__header').css({
							opacity: 1,
							transform: 'none'
						});
					}

					if (window.innerWidth <= 510) {
						$('.l-slider-pagination-wrapper').css({transform: ''});
						$('.l-header-top-nav').css({opacity: 1});
					}

				}

			});
			$(window).trigger('resize');
		});

		AR.events.on('onSlideChangeTransitionStart', () => {

			let previousIndex = this.subSliders[5].swiper.previousIndex ;
			let activeIndex = this.subSliders[5].swiper.activeIndex;
			let $prev = $('[data-slide-id="5"] [data-slide-id="' + previousIndex + '"].js-sub-slider-item');
			let $now = $('[data-slide-id="5"] [data-slide-id="' + activeIndex + '"].js-sub-slider-item');
			let text = $now.find('.b-slider-item__text-sub').html();
			$('.b-sub-slider__block')
				.velocity({opacity: [0, 1]}, function () {
					$('.b-sub-slider__block').html(text).velocity({opacity: [1, 0]});
				});

			if (previousIndex < activeIndex) {
				$prev.css({
					transform: 'translate3d(' + ($prev.width()) + 'px, 0px, 0px)',
					'transition-duration': config.options.speed + 'ms'
				});
			} else {

				$prev.css({
					transform: 'translate3d(-' + ($prev.width()) + 'px, 0px, 0px)',
					'transition-duration': config.options.speed + 'ms',
				});

				$now.css({
					'z-index': 10
				});
			}

		});

		AR.events.on('onSlideChangeTransitionEnd', () => {
			{
				$('[data-slide-id="5"] .js-sub-slider-item').css({
					transform: '',
					'transition-duration': '0ms',
					'z-index': ''
				});
			}

		});

	}

	getTranslateY($e) {
		let result = $e.css('transform').replace(/[a-z()]/g, '').split(',');
		let Y = result[13] ? result[13] : result[5];
		//console.log($e.css('transform'),$e,result);
		return Y;
	}


	animateSlideContent($slide) {
		var translate = this.getTranslateY($slide);
		//ЗАСТАВЛЯЕМ ВЕЛОСИТИ ЗАПОМНИТЬ ТЕКУЩЕЕ ЗНАЧЕНИЕ transform И НЕ ТРОГАТЬ ЕГО ПРИ АНИМАЦИИ ДРУГИХ СВОЙСТВ
		$.Velocity.hook($slide, 'translateY', translate + 'px'); // Must provide unit type

		$slide.velocity({opacity: [1, 0]});

		let e1Animate = {right: ['3vw', '-3vw'], opacity: 1};
		let navAnimate = {translateX: ['0%', '-100%'], opacity: [1, 0]};
		let $e1 = $('.l-slider-pagination-wrapper');
		let $nav = $('.l-header-top-nav').css({transition: 'none'});
		let $e2 = $slide.find('.b-slider-item__left')
			.velocity('stop');
		let $e3 = $slide.find('.b-slider-item__right')
			.velocity('stop');
		let $e4 = $slide.find('.b-slider-item__inset,.quote__content,.b-slider-item__button-wrapper')
			.velocity('stop');

		if ($e1.hasClass('show')) {
			e1Animate = {right: ['3vw', '3vw'], opacity: 1};
			navAnimate = {translateX: '0%', opacity: 1};
		} else {
			$e1.addClass('show');
		}

		if ($('.js-slider.show').length == 0) {
			e1Animate = {right: '-3vw', opacity: 0};
			navAnimate = {translateX: '-100%', opacity: 0};
		}

		$e2.css({left: -.1 * $e2.width() + 'px', opacity: 0});
		$e3.css({left: -.1 * $e3.width() + 'px', opacity: 0});
		$e4.css({left: '-100px', opacity: 0});
		let aSpeed = 1000;

		var mySequence = [
			{e: $e1, p: e1Animate, o: {duration: aSpeed}},
			{e: $nav, p: navAnimate, o: {duration: 300, delay: aSpeed, sequenceQueue: false}},
			{e: $e2, p: {opacity: 1, left: '0px'}, o: {duration: aSpeed}},
			{e: $e3, p: {opacity: 1, left: '0px'}, o: {duration: aSpeed, complete: () => {
				this.swiper.allowSlidePrev = true;
				this.swiper.allowSlideNext = true;
			}}},
			{e: $e4, p: {opacity: 1, left: '0px'}, o: {duration: aSpeed, sequenceQueue: false}}
		];


		//ЗАСТАВЛЯЕМ ВЕЛОСИТИ ЗАПОМНИТЬ ТЕКУЩЕЕ ЗНАЧЕНИЕ transform И НЕ ТРОГАТЬ ЕГО ПРИ АНИМАЦИИ ДРУГИХ СВОЙСТВ
		var translate = this.getTranslateY($e1);
		$.Velocity.hook($e1, 'translateY', translate + 'px'); // Must provide unit type

		this.swiper.allowSlidePrev = false;
		this.swiper.allowSlideNext = false;
		$.Velocity.RunSequence(mySequence);
		if ($slide.data('slide-id') == 5) {// на последнем слайде нечего анимировать
			setTimeout(() => {
				this.swiper.allowSlidePrev = true;
				this.swiper.allowSlideNext = true;
			}, aSpeed * 3);
		}
	}

	/**
	 * Инициализировать слайдер
	 * @param {object} sliderWrapper обертка слайдера
	 * @param {object} customConfig кастомный конфиг слайдера
	 */
	async initSlider(sliderWrapper, customConfig = {}) {
		this.sliderWrapper = sliderWrapper;
		this.slider = this.sliderWrapper.querySelector('.js-slider');

		if (this.slider) {
			this.CSwiper = await this.getSwiper();
			this.poly = await this.getPoly();
			this.Tools = await this.getTools();

			this.Tools.disableTab(this.slider.querySelectorAll('*'));
			this.swiper = new this.CSwiper($(this.slider), this.getConfig(customConfig));
			AR.events.emit('onRealInitSlider');
		}
	}


	/**
	 * callback на инициализацию свайпеа
	 */
	onInit(isMain) {
		AR.events.emit('onInit' + ((isMain) ? 'IsMain' : ''));

		const html = document.querySelector('html');

		if (html && (html.classList.contains('feature-no-flexbox') || html.classList.contains('browser-safari-10') || html.classList.contains('browser-safari-9'))) {
			this.fixContainerHeight();
		}

		//	Отключить прелоадер
		AR.components.cPreloader_base.disablePreloaderInItem($(this.sliderWrapper));

	}

	/**
	 * Инициализировать вложенные слайдеры
	 */
	initSubSliders() {
		AR.getElemsArr(this.swiper.slides).forEach((slide, index) => {
			const subslider = slide.querySelector(`[data-subslider-id]`);

			if (subslider) {
				const subsliderName = subslider.dataset.subsliderId;
				const subSliderConfig = this.config.subSliders.filter(elem => elem.name == subsliderName);

				if (subsliderName && subSliderConfig) {
					this.subSliders[index] = {
						name: subSliderConfig[0].name,
						lockBetween: subSliderConfig[0].lockBetween,
						swiper: new this.CSwiper($(subslider), this.getConfig(subSliderConfig[0].options, false))
					};
				}
			}
		});
	}

	/**
	 * Получить конечный конфиг слайдера
	 * @param {object} customConfig кастомный конфиг слайдера
	 * @param {boolean} isMain главный слайдер
	 */
	getConfig(customConfig, isMain = true) {
		const defaultConfig = AR.rExtend(this.config.options, {
			pagination: this.getPagination(),
			on: {
				slideChangeTransitionStart: () => {
					this.onSlideChangeTransitionStart(isMain);
				},
				slideChangeTransitionEnd: () => {
					this.onSlideChangeTransitionEnd(isMain);
					const slideHash = this.getHashBySlideID(this.swiper.activeIndex);

					if (slideHash) {
						this.Tools.setPageHash(slideHash);
					}
				},
				autoplay: () => {
					this.onAutoplay(isMain);
				},
				autoplayStart: () => {
					this.onAutoplayStart(isMain);
				},
				autoplayStop: () => {
					this.onAutoplayStop(isMain);
				},
				init: () => {
					this.onInit(isMain);
				},
				fromEdge: () => {
					this.onFromEdge(isMain);
				},
				reachBeginning: () => {
					this.onReachBeginning(isMain);
				},
				reachEnd: () => {
					this.onReachEnd(isMain);
				}
			}
		});

		const newConfig = AR.rExtend(defaultConfig, customConfig);

		return newConfig;
	}

	/**
	 * Возвращает хеш глобального слайда + хеш текущего элемента из интерактивного содержимого
	 * @param {number} mainSlideID - id основного элемента слайда
	 */
	getHashBySlideID(mainSlideID) {
		let targetSlide = this.swiper.slides[mainSlideID];

		if (targetSlide) {
			let mainSlideHash = targetSlide.dataset.hash;
			let subSlideHash = '';
			let subSlider = this.subSliders[mainSlideID];

			if (subSlider) {
				const subSlideID = subSlider.swiper.activeIndex;
				const activeSubSlide = subSlider.swiper.slides[subSlideID];

				if (activeSubSlide) {
					subSlideHash = activeSubSlide.dataset.hash;
				}
			}

			if (subSlideHash) {
				return this.Tools.jointHashByDelimiter([mainSlideHash, subSlideHash]);
			} else {
				return mainSlideHash;
			}
		} else {
			return false;
		}
	}

	/**
	 * Перейти к нужному слайду по хэшу
	 * @param {string} hash хэш
	 * @param {boolean} isMain главный слайдер
	 */
	goToSlideByHash(hash, isMain = true) {
		const hashes = this.Tools.divideHashByDelimiter();
		const curHash = hash ? hash : hashes[0];
		const swiper = isMain ? this.swiper : this.subSliders[this.swiper.activeIndex].swiper;
		//получение ID слайда по хешу
		const hashSlideID = AR.getElemsArr(swiper.slides).reduce((result, slide, index) => {
			if (!result && curHash == slide.dataset.hash) {
				return index;
			} else {
				return result;
			}
		}, 0);

		swiper.slideTo(hashSlideID, 0);

		//определяем и переключаем слайд саблайдера
		if (isMain && hashes[1] && this.subSliders[this.swiper.activeIndex]) {
			this.goToSlideByHash(hashes[1], false);
		}
	}

	/**
	 * Проверять / выставлять состояние блокировки смены слайдов основного слайдера
	 */
	// checkSliderLock() {
	// 	const slideSubslider = this.subSliders[this.swiper.activeIndex];

	// 	if (slideSubslider && slideSubslider.lockBetween) {
	// 		const subSlider = slideSubslider.swiper;
	// 		const activeIndex = subSlider.activeIndex;
	// 		const endSlideIndex = subSlider.slides.length - 1;

	// 		if (activeIndex > 0 == activeIndex < endSlideIndex) {
	// 			this.swiper.allowSlidePrev = false;
	// 			this.swiper.allowSlideNext = false;
	// 		} else if (activeIndex == endSlideIndex) {
	// 			this.swiper.allowSlidePrev = false;
	// 			this.swiper.allowSlideNext = true;
	// 		} else if (activeIndex == 0) {
	// 			this.swiper.allowSlidePrev = true;
	// 			this.swiper.allowSlideNext = false;
	// 		} else {
	// 			this.swiper.allowSlidePrev = true;
	// 			this.swiper.allowSlideNext = true;
	// 		}
	// 	} else {
	// 		this.swiper.allowSlidePrev = true;
	// 		this.swiper.allowSlideNext = true;
	// 	}
	// }

	/**
	 * callback на начало смены слайда
	 */
	onSlideChangeTransitionStart(isMain = true) {
		AR.events.emit('onSlideChangeTransitionStart' + ((isMain) ? 'IsMain' : ''));
	}

	/**
	 * callback на завершение смены слайда
	 */
	onSlideChangeTransitionEnd(isMain = true) {
		super.onSlideChangeTransitionEnd();
	}

	/**
	 * callback при смене слайда с первого / последнего слайда
	 */
	onFromEdge(isMain = true) {
		super.onFromEdge();
	}

	/**
	 * callback смене на первый слайд
	 */
	onReachBeginning(isMain = true) {
		super.onReachBeginning();
	}

	/**
	 * callback при смене на последний слайд
	 */
	onReachEnd(isMain = true) {
		super.onReachEnd();
	}

	/**
	 * callback при смене слайда автоплеем (автоматическая смена слайдов)
	 */
	onAutoplay(isMain = true) {
		super.onAutoplay();
	}

	/**
	 * callback при начале автоплея
	 */
	onAutoplayStart(isMain = true) {
		super.onAutoplayStart();
	}

	/**
	 * callback при остановке автоплея
	 */
	onAutoplayStop(isMain = true) {
		super.onAutoplayStop();
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_multi = new CSlider_multi();
	// Вызов метода, инициализирующего все существующие события
	cSlider_multi.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSlider_multi, 'cSlider_multi');
});
