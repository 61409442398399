/**
 * Класс реализует базовую логику для компонента Tooltips
 */
class CTooltips {
	constructor() {

	}
}

module.exports = CTooltips;
