const CButtonsPanel = require('../../index.js');
const config = require('./config.yaml');
const text = require('./text.yaml');
const {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} = require('body-scroll-lock');

/**
 * Реализует логику варианта in-column компонента buttons-panel
 */
class CButtonsPanel_inColumn extends CButtonsPanel {
	constructor() {
		super();
		this.config = config;
		this.text = text;
	}

	initScrollbars() {
		this.$mainMenuPanel = $('.js-buttons-panel-list');
		let that = this;
		$('.js-buttons-panel-list').each(function () {

			if ($(this).parents('.l-buttons-area__buttons-panel').length) {
				that.mainMenuInstanceMobile = $(this).overlayScrollbars({
					className: 'os-theme-rvc-main-menu',
					overflowBehavior: {
						x: 'hidden',
						y: 'scroll'
					},
				}).overlayScrollbars();
			} else {
				that.mainMenuInstance = that.$mainMenuPanel.overlayScrollbars({
					className: 'os-theme-rvc-main-menu',
					overflowBehavior: {
						x: 'hidden',
						y: 'scroll'
					},
				}).overlayScrollbars();
			}


		});

	}

	init() {
		const panelClass = `.js-buttons-panel--${this.config['modifier']}`;
		const $panel = $(panelClass);

		if ($panel.length) {
			$panel.each((i, elem) => {
				const panelObj = {};
				panelObj.$panel = $(elem);
				const isCompact = panelObj.$panel.hasClass('js-buttons-panel--compact');
				const $shareLinks = panelObj.$panel.find('[data-share-link]');
				panelObj.panelClass = panelClass;
				panelObj.listOptions = this.config['animation']['list'];
				panelObj.sublistOptions = this.config['animation']['sublist'];
				panelObj.classesObj = {
					buttonsTrigger: '.js-buttons-panel-trigger',
					buttonsList: '.js-buttons-panel-list',
					sublistTrigger: '.js-buttons-panel-sublist-trigger',
					sublistLink: '.js-buttons-panel-sublist-link',
					sublist: '.js-buttons-panel-sublist',
					compare: '.js-buttons-panel-sublist-trigger--compare',
					compareList: '.js-buttons-panel-sublist--compare'
				};
				panelObj.$buttonsList = panelObj.$panel.find(panelObj.classesObj['buttonsList']);
				panelObj.$buttonsTrigger = panelObj.$panel.find(panelObj.classesObj['buttonsTrigger']);
				panelObj.$sublistTrigger = panelObj.$panel.find(panelObj.classesObj['sublistTrigger']);
				panelObj.$sublist = panelObj.$panel.find(panelObj.classesObj['sublist']);
				panelObj.$compare = panelObj.$panel.find(panelObj.classesObj['compare']);
				panelObj.$compareList = panelObj.$panel.find(panelObj.classesObj['compareList']);
				panelObj.events = {
					list: this.config['hoverablePanel'] ? this.config['events']['list'].hover : this.config['events']['list'].click,
					sublist: this.config['hoverableSublist'] ? this.config['events']['sublist'].hover : this.config['events']['sublist'].click
				};
				panelObj.tourSelector = '.hopscotch-bubble-container';
				// const type = panelObj.$panel.data('type');
				// const panelTypedClass = `${panelClass}[data-type="${type}"]`;
				// panelObj.tourSelector = [panelTypedClass, '.hopscotch-nav-button'].join(', ');
				// panelObj.dontCollapseSelector = [
				// 	panelObj.classesObj['sublistTrigger'],
				// 	`${panelTypedClass}[data-type="${type}"] ${panelObj.classesObj['buttonsTrigger']}`,
				// 	`${panelObj.classesObj['sublistLink']}:not([data-popup-trigger-id])`,
				// 	'.js-add-page',
				// 	'.js-del-page',
				// 	'.js-no-collapse'
				// ].join(', ');

				// if (this.config['hoverablePanel']) {
				// 	panelObj.dontCollapseSelector += ` ,${`.js-buttons-panel--${this.config['modifier']}`}`;
				// }

				panelObj.tour = null;
				if (config.tools.showTour && panelObj.$panel.attr('data-show-tour')) {
					const tourConfig = require('../../tourConfig.js')(this.text[templateVars.lang]);
					const sorting = config.tools.sorting[templateVars.lang];
					let tourSteps = tourConfig.steps;
					tourSteps = this.sortTourSteps(tourSteps, sorting);
					tourSteps[0].delay = '100';
					tourConfig.id += this.config['modifier'];

					tourSteps.forEach(step => {
						step.target = `${panelClass}[data-show-tour] ${step.target}`;
					});

					panelObj.tour = AR.components.cTour_base.createTour(tourConfig);
				}


				if (panelObj.$compare.length) {
					this.fixCompare(panelObj);
				}
				this.checkForItems(panelObj);
				this.initMyReportButtons(panelObj);

				//Показать и скрыть панель по табу
				this.showAndHideByTab(panelObj);

				$shareLinks.on('click', $.proxy(this, 'openShareLink'));
				panelObj.$sublistTrigger.on(panelObj.events.sublist.show, $.proxy(this, 'toggleHandler', panelObj));

				if (isCompact) {
					panelObj.$buttonsTrigger.on(panelObj.events.list.show, $.proxy(this, 'toggleHandler', panelObj));
				}

				this.initScrollbars();
			});
		}
	}

	toggleHandler(panelObj, event) {
		event.preventDefault();
		const $this = $(event.currentTarget);
		const isSublist = $this.hasClass(panelObj.classesObj['sublistTrigger'].replace('.', ''));
		const $targetList = isSublist ? $this.siblings('.js-buttons-panel-sublist') : panelObj.$buttonsList;
		const animationOptions = isSublist ? panelObj.sublistOptions : panelObj.listOptions;
		const hoverable = isSublist ? this.config.hoverableSublist : this.config.hoverablePanel;

		this.hideSublists($this, panelObj);

		if (hoverable) {
			if (!$targetList.hasClass('is-expand')) {
				this.showList($this, panelObj, animationOptions, isSublist);
			}
		} else {
			this.toggleList($this, panelObj, animationOptions, $targetList, isSublist);
		}
	}


	showList($elem, panelObj, options, isSublist) {
		this.enableDocumentListener(panelObj, isSublist);

		if (!$elem.hasClass('b-buttons-panel__trigger--download-pdf')) {
			$('body').trigger(`buttons-panel-in-column-open`).css({overflow: 'hidden'}).addClass('open-bp');
		}
		let that = this;
		setTimeout(() => {
			$('body').css({overflow: 'hidden'});
			let scrollTop = $('body.os-host .os-viewport').scrollTop();
			// Boolean(window.bodyInstance) ? window.bodyInstance.destroy() : null;
			$('html,body').scrollTop(scrollTop);

			$('.js-buttons-panel-list').css({overflow: 'scroll'});
			if (1) {
				disableBodyScroll(document.querySelector('#layout-wrapper'));
				let start, end, move;
				let getScreenYbyE = function (e) {
					if (e.touches.length == 0) {
						return false;
					}
					return Boolean(e.touches) ? e.touches[0].screenY : e.targetTouches[0].screenY;
				};
				document.addEventListener('touchstart', function (e) {
					start = getScreenYbyE(e);
				});
				document.addEventListener('touchend', function (e) {
					end = getScreenYbyE(e);
					if (!end) {
						return;
					}
					move = start - end;
					let scroll;
					if (move > 0) {
						scroll = '+=' + move;
					} else {
						scroll = '-=' + Math.abs(move);
					}
					that.mainMenuInstanceMobile.scroll({y: scroll});
				});
				document.addEventListener('touchmove', function (e) {
					end = getScreenYbyE(e);
					if (!end) {
						return;
					}
					move = start - end;
					let scroll;
					if (move > 0) {
						scroll = '+=' + move;
					} else {
						scroll = '-=' + Math.abs(move);
					}
					that.mainMenuInstanceMobile.scroll({y: scroll});
					start = end;
				}, {
					passive: false
				});
			}

		});

		$elem
			.addClass('is-expand')
			.siblings('.js-buttons-panel-list, .js-buttons-panel-sublist')
			.addClass('is-expand')
			.velocity(options.show.effect, {
				duration: options.show.duration,
				complete: () => {
					if (panelObj.tour && panelObj.$panel.closest('.js-buttons-panel--tools')) {
						AR.components.cTour_base.startFirstTime(panelObj.tour);
					}
				}
			});
	}


	hideList($elem, panelObj, options, $list, isSublist) {
		this.disableDocumentListener(panelObj, isSublist);

		if (!$elem.hasClass('js-share')) {
			$('body').css({overflow: ''}).trigger('initBodyInstance').removeClass('open-bp');
		}
		setTimeout(function () {
			if (Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform)) {
				clearAllBodyScrollLocks();
			}
		}, 1);

		$elem
			.removeClass('is-expand')
			.siblings('.js-buttons-panel-list, .js-buttons-panel-sublist')
			.removeClass('is-expand');

		$list
			.velocity(options.hide.effect, {
				duration: options.hide.duration,
				begin: () => {
					if (panelObj.tour && panelObj.$panel.closest('.js-buttons-panel--tools')) {
						AR.components.cTour_base.skipTours(panelObj.tour.config);
					}
				}
			});
	}

	initMyReportButtons(panelObj) {
		const $addBtn = panelObj.$panel.find('.js-buttons-panel-link--add');
		const $delBtn = panelObj.$panel.find('.js-buttons-panel-link--del');
		const $myReportBtn = panelObj.$panel.find('.js-buttons-panel-link--myreport');
		const $counter = $myReportBtn.find('.js-buttons-panel-counter');

		if ($myReportBtn.length) {
			this.setMyReportLength($counter);
		}

		if ($myReportBtn.length && $addBtn.length && $delBtn.length) {
			const pageKey = templateVars.siteUrl.path;

			AR.components.cMyReport_base.checkPageOnExist(pageKey)
				.then((value) => {
					if (value) {
						$addBtn.removeClass('is-visible').parents('.js-buttons-panel-item').hide();
						$delBtn.addClass('is-visible').parents('.js-buttons-panel-item').show();
					} else {
						$delBtn.removeClass('is-visible').parents('.js-buttons-panel-item').hide();
						$addBtn.addClass('is-visible').parents('.js-buttons-panel-item').show();
					}
				});

			$addBtn.on('click', () => {
				AR.components.cMyReport_base
					.addPage()
					.then(() => this.setMyReportLength($counter));

				$addBtn.removeClass('is-visible').parents('.js-buttons-panel-item').hide();
				$delBtn.addClass('is-visible').parents('.js-buttons-panel-item').show();
			});

			$delBtn.on('click', () => {
				AR.components.cMyReport_base
					.removeItem(pageKey)
					.then(() => this.setMyReportLength($counter));

				$addBtn.addClass('is-visible').parents('.js-buttons-panel-item').show();
				$delBtn.removeClass('is-visible').parents('.js-buttons-panel-item').hide();
			});
		}
	}

	onDocumentListenerSublist(panelObj, event) {
		super.onDocumentListenerSublist(panelObj, event);
	}

	disableDocumentListener(panelObj, isSublist) {
		super.disableDocumentListener(panelObj, isSublist);
	}

	onDocumentListener(panelObj, event) {
		super.onDocumentListener(panelObj, event);
	}
}

AR.waitComponents(['cMyReport_base', 'cTour_base'], () => {
	const cButtonsPanel_inColumn = new CButtonsPanel_inColumn();
	// Вызов метода, инициализирующего все существующие события
	cButtonsPanel_inColumn.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cButtonsPanel_inColumn, 'cButtonsPanel_inColumn');
});
