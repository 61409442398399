module.exports = function (text) {
	return {
		id: 'tools--',
		isTourBubble: false,
		steps: [{
			target: '.js-add-page.is-visible',
			id: 'addToReport',
			placement: 'bottom',
			title: text.addToReport,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.addToReportAbout}`
		}, {
			target: '.js-buttons-panel-link--myreport',
			id: 'myReport',
			placement: 'bottom',
			title: text.myReport,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.myReportAbout}`
		}, {
			target: '.js-print',
			id: 'printVersion',
			placement: 'bottom',
			title: text.printVersion,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.printVersionAbout}`
		}, {
			target: '.js-get-pdf',
			id: 'pdf',
			placement: 'bottom',
			title: text.pdf,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.pdfAbout}`
		}, {
			target: '.js-share',
			id: 'share',
			placement: 'bottom',
			title: text.share,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.shareAbout}`
		}, {
			target: '.js-buttons-panel-link--history',
			id: 'history',
			placement: 'bottom',
			title: text.history,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.historyAbout}`
		}, {
			target: '.js-feedback-form-button',
			id: 'feedback',
			placement: 'bottom',
			title: text.feedback,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.feedbackAbout}`
		}, {
			target: '.js-buttons-panel-link--download',
			id: 'download',
			placement: 'bottom',
			title: text.download,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.downloadAbout}`
		}, {
			target: '.js-buttons-panel-link--analysis',
			id: 'analysis',
			placement: 'bottom',
			title: text.analysis,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.analysisAbout}`
		}, {
			target: '.js-compare',
			id: 'compare',
			placement: 'bottom',
			title: text.compare,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `
				<div class="tour-compare-report__section">
					<span class="tour-compare-report__icon tour-compare-report__icon--compare"></span>
					<span class="tour-compare-report__text">
						(${text.compare}) - ${text.buttonCompareText}
					</span>
				</div>
				<div class="tour-compare-report__section">
					<span class="tour-compare-report__icon tour-compare-report__icon--see"></span>
					<span class="tour-compare-report__text">
						(${text.see}) - ${text.buttonSeeText}
					</span>
				</div>`
		}, {
			target: '.js-compare',
			id: 'compare',
			placement: 'bottom',
			title: text.compare,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `
				<div class="tour-compare-report__section">
					<span class="tour-compare-report__icon tour-compare-report__icon--compare"></span>
					<span class="tour-compare-report__text">
						(${text.compareList}) - ${text.listCompareText}
					</span>
				</div>
				<div class="tour-compare-report__section">
					<span class="tour-compare-report__icon tour-compare-report__icon--see"></span>
					<span class="tour-compare-report__text">
						(${text.seeList}) - ${text.listSeeText}
					</span>
				</div>
				<div class="tour-compare-report__section">
					<span class="tour-compare-report__icon tour-compare-report__icon--previous-reports"></span>
					<span class="tour-compare-report__text">
						(${text.previousReports}) - ${text.listPreviousReportsText}
					</span>
				</div>`
		}, {
			target: '.js-buttons-panel-link--site-map',
			id: 'siteMap',
			placement: 'bottom',
			title: text.siteMap,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.siteMapAbout}`
		}, {
			target: '.js-buttons-panel-link--contacts',
			id: 'contacts',
			placement: 'bottom',
			title: text.contacts,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.contactsAbout}`
		}, {
			target: '.js-buttons-panel-link--glossary',
			id: 'glossary',
			placement: 'bottom',
			title: text.glossary,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.glossaryAbout}`
		}, {
			target: '.js-buttons-panel-link--disclaimer',
			id: 'disclaimer',
			placement: 'bottom',
			title: text.disclaimer,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.disclaimerAbout}`
		}, {
			target: '.js-buttons-panel-link--archive-reports',
			id: 'archive',
			placement: 'bottom',
			title: text.archive,
			width: 400,
			xOffset: 0,
			yOffset: 0,
			content: `${text.archiveAbout}`
		}]
	};
};

