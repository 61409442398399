class Tools {
	constructor() {}

	/**
	 * Анимирует отрисовку контуров(path)
	 * @param  {d3 selector} paths Селектор кривых в d3
	 * @param  {Number}   duration Продолжительность анимации в милисекундах
	 * @param  {Function} done     Фукнция, которая выполнится по окончании анимации
	 */
	animatePaths(paths, duration = 3000, done) {
		require.ensure(['./metronome.js'], (require) => {
			const metronome = require('./metronome.js');

			//Анимируем каждый переданный контур
			paths.each(function () {
				//Контур
				var path = this;
				//Длинна контура
				var length = path.getTotalLength();

				//Выставляем длинну пунктиров и расстояние между ними равные длинне контура
				path.style.strokeDasharray = length + ' ' + length;
				//Смещаем пунктирную обводку на длинну контура
				path.style.strokeDashoffset = length;

				//Вызываем анимацию контура
				metronome({
					// Тип способа анимирования
					type: 'duration',
					//Скорость анимирования
					duration: duration,
					//Плавность
					easing: 'linear',
					//Отрисовка контура
					draw: function (handle, progress) {
						path.style.strokeDashoffset = Math.floor(length * (1 - progress));
					},
					//Вызов функции по завершению отрисовки контура
					complete: done
				});
			});
		});
	}

	/**
	 * Добавление ссылки на элемент SVG
	 * @param {Object} element           Элемент, на который нужно повесить ссылку
	 * @param {String} url               Ссылка, которая будет повешена на элемент
	 * @param {String} [target='_blank'] Место где будет открыта новая страницы
	 */
	addLink(element, url, target = '_blank') {
		//При клике на элемент
		element.on('click', function () {
			//Открываем ссылку
			global.open(url, target);
		});
	}

	/**
	 * Анимация пульсирования свойства
	 * @param  {Object} elements           Элемент, на который нужно анимировать
	 * @param  {String} prop               Свойство, которое будет анимировано
	 * @param  {String} [value1='default'] Начальное значение свойства
	 * @param  {String} value2             Конечное значение свойства
	 * @param  {Number} [duration=800]     Время от начального до конечного значения свойства
	 * @param  {String} [easing='linear']  Плавность
	 */
	makePulsingAttr(elements, prop, value1 = 'default', value2, duration = 800, easing = 'linear') {
		//Если указано начальное значение
		if (value1 !== 'default') {
			//Выставить начальное значение
			elements.css(prop, value1);
		}

		//Анимрование
		elements.velocity({[prop]: value2}, {
			//Зацикливание
			loop: true,
			//Продолжительность от value1 до value2
			duration,
			//Плавность
			easing
		});
	}

	/**
	 * Анимация пульсирования свойства fill
	 * @param  {Object} elements Элемент, на который нужно анимировать
	 * @param  {String} color1   Начальный цвет
	 * @param  {String} color2   Конечный цвет
	 */
	makePulsingFill(elements, color1, color2) {
		//Вызов метода пульсации свойства
		this.makePulsingAttr(elements, 'fill', color1, color2);
	}
}

module.exports = Tools;
