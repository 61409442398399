const CCaptcha = require('../../index.js');

/**
 * Реализует логику варианта base компонента captcha
 */
class CCaptcha_base extends CCaptcha {
	constructor() {
		super();
	}
	// Вызов всех существующих событий (обновление капчи, выделение поля ввода по клику)
	init() {
		// Получаем блок с капчей
		this.$captchaForm = $('.js-captcha');
		// Если блок с капчей присутствует
		if (this.$captchaForm.length) {
			// Получить кнопку для обновления капчи
			this.$reloadButton = this.$captchaForm.find('.js-captcha-reload');
			// Получить поле ввода капчи
			this.$captchaText = this.$captchaForm.find('.js-captcha-text');
			// Провесить обработчик на кнопку обновления капчи
			this.$reloadButton.on('click', $.proxy(this, 'onClickReload'));
			// Провесить обработчик на поле ввода капчи
			this.$captchaText.on('click', $.proxy(this, 'onClickSelect'));
			// Добавить валидатор капчи
			this.addValidator();
			// Вызов метода обновления капчи
			this.initCaptcha();
		}
	}

	/**
	 * Метод обновления капчи
	 * @param  {[type]} $captcha [description]
	 */
	reloadCaptcha($captcha) {
		// Получить случайное значение для капчи
		const captchaId = Math.random().toString(36).substr(2, 10);
		// Получить путь к картинке
		const captchaUrl = `/captcha/${captchaId}.jpg`;
		// Получить значение id капчи
		$captcha.find('.js-captcha-id').val(captchaId);
		// Получить значение атрибута src у блока - .js-captcha__image
		$captcha.find('.js-captcha-image').attr('src', captchaUrl);
	}

	/**
	 * Метод проверки валидности введенной капчи
	 * @param {[object]} $captcha [блок капчи]
	 * @param {[boolean]} state [значение, для проверки валидности]
	 */
	setValidState($captcha, state) {
		$captcha.find('.js-captcha-text').attr('data-parsley-captcha', String(state));
	}

	// Метод, инициализирующий выделение поля ввода капчи
	onClickSelect() {
		this.$captchaText.select();
	}

	// Инициализация капчи (для того, чтобы картинка капчи появилась сразу, без нажатия на кнопку - обновить)
	initCaptcha() {
		this.$captchaForm.each((i, elem) => {
			// Вызов метода обновления капчи
			this.reloadCaptcha($(elem));
		});
	}

	/**
	 * Метод обновления капчи (для клика по кнопке)
	 * @param  {[object]} e [Нажатая кнопка]
	 */
	onClickReload(e) {
		// Получить
		const $captcha = $(e.currentTarget).parents(this.$captchaForm);
		// Вызов метода обновления капчи
		this.reloadCaptcha($captcha);
	}
}

AR.waitComponents([], () => {
	const cCaptcha_base = new CCaptcha_base();
	// Вызов метода со всеми событиями
	cCaptcha_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cCaptcha_base, 'cCaptcha_base');
});
