module.exports = function (text) {
	return {
		id: 'analysis_tour',
		steps: [{
			target: '.analysis__preset',
			placement: 'bottom',
			title: text.presets,
			content: text.presetsInfo,
		}, {
			target: '.analysis__items-list',
			placement: 'top',
			title: text.indicators,
			content: text.indicatorsInfo,
			onNext: function () {
				global.zebraAnalysis.selectItems([1, 2, 3]);
			}
		}, {
			target: '.analysis__chart-section',
			placement: 'top',
			title: text.diagram,
			content: text.diagramInfo,
		}, {
			target: '.analysis__table',
			placement: 'top',
			title: text.data,
			content: text.dataInfo,
		}, {
			target: '.analysis__years-list',
			placement: 'top',
			title: text.years,
			content: text.yersInfo,
		}],
		onStart: function () {
			window.location.hash = 'analysis__section-0';
		}
	};
};
