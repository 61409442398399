//конфиг текста компонента
const text = require('./text.yaml')[templateVars.lang];
//конфиг шаблона компонента
const config = require('./config.yaml');
//библиотека уведомлений
// const Noty = require('noty');
//базовый класс компонента
const CNotification = require('../../index.js');
const isTest = global.testComponentName;

/**
 * Реализует логику варианта base компонента notification
 */
class CNotification_base extends CNotification {
	constructor() {
		super();
		this.config = config;
		//Хранилище уведмолений
		this.notifications = {};
	}

	/**
	 * Преобработка / инициализация библиотеки уведомлений
	 * @param  {object} options опции для создания уведомления
	 * @return  {object} noty объект noty
	 */
	createNotification(options) {
		if (this.Noty) {
			return Promise.resolve()
				.then(() => {
					return this.initNotification(options);
				});
		} else {
			return this.getNoty()
				.then(Noty => {
					this.Noty = Noty;
					this.Noty.setMaxVisible(this.config.setMaxVisible);
					return this.initNotification(options);
				});
		}
	}

	/**
	 * Создание уведомления на базе переданных опций или стандартных, если какие-либо опции отсутствуют
	 * @param  {object} options опции для создания уведомления
	 * @return  {object} noty объект noty
	 */
	initNotification(options) {
		//Если нет текста уведомления приостановить выполнение
		if (!options.message.length) {
			return;
		}

		//Если уведомление с переданым ID уже существует, то приостановить выполнение
		if (this.notifications[options.id]) {
			console.error(`Уведомление с ID ${options.id} уже существует`);

			return;
		}

		//Инстанс класса.
		const self = this;
		//Доп. класс для тела уведомления (на всю ширину)
		const narrowClass = this.config.narrow ? 'b-noty__body--narrow' : '';
		//Продолжительность анимаций
		const duration = isTest ? 0 : this.config.animation.duration;
		//Создание уведомления
		const noty = new this.Noty({
			//Уникальный идентификатор уведомления. При значении false идентификатор генерируется автоматически
			id: options.id || false,
			//Обёртка уведомлений. От типа зависит местоположение появления. Уведомления с одинаковым параметром обёртки добавляются в первый созданный
			//Принимаемые значения: top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight или кастомный.
			layout: options.layout || 'default',
			//Тип уведомления. Отвечает за тип отображения.
			//Принимаемые значения: alert, success, warning, error, info/information или кастомный
			type: options.type || 'notification',
			//text: options.message,
			//Событие по которому будет закрыто уведомление
			//Принимаемые значения: click, button
			closeWith: options.closeWith || ['click'],
			//Тема уведомления. Добавляет класс контейнеру уведомления(noty_theme__${имя темы})
			//Принимаемые значения: relax, mint, metroui или кастомный
			theme: options.theme || `default b-noty ${options.themeModifier ? `b-noty--${options.themeModifier}` : 'b-noty--default'} ${narrowClass}`,
			//Время после, которого уведомление закроется автоматически
			timeout: options.timeout || false,
			//Анимация появления и закрытия.
			//Передается объект с параметрами open, close, которым присваивается фунция анимации(velocity, обращение к this.barDom) или класс, который будет добавляться и удаляться(анимирование на css, keyframes)
			animation: options.animation || {
				open: function (promise) {
					const $barDom = $(this.barDom);

					$.Velocity($barDom, {height: 0}, 0);
					$.Velocity($barDom, 'reverse', {
						duration,
						easing: 'ease',
						complete: function () {
							$barDom.height('auto');

							promise(function (resolve) {
								resolve();
							});
						}
					});
				},
				close: function (promise) {
					$.Velocity(this.barDom, {
						height: 0
					}, {
						duration,
						easing: 'ease',
						complete: function () {
							promise(function (resolve) {
								resolve();
							});
						}
					});
				}
			},
			//События
			callbacks: {
				//перед началом показа уведомления
				beforeShow: function () {
					self.beforeShow(this, options.beforeShow);
				},
				//при начале показа уведомления
				onShow: function () {
					self.onShow(this, options.onShow);
				},
				//после показа уведомления
				afterShow: function () {
					self.afterShow(this, options.afterShow);
				},
				//на закрытие уведомления
				onClose: function () {
					self.onClose(this, options.onClose);
					//Удаление уведомления из хранилища
					self._removeFromStore(this);
				},
				//Передается кастомный шаблон в options.template в виде функции: (options) => {return `{шаблон}`}. При пустом значении применяется шаблон, который возвращает метод '_getTemplate()'
				onTemplate: function () {
					this.barDom.innerHTML = typeof options.onTemplate == 'function' && options.onTemplate(options) || self._getTemplate(options);
				}
			},
		});

		//Показать созданное уведомление
		noty.show();

		//Записать созданное уведомление в хранилище
		this._storeNotification(noty);

		//Возвращает созданное уведомление
		return noty;
	}

	/**
	 * Получение библиотеки уведомлений
	 */
	async getNoty() {
		const lib = await import(/* webpackChunkName: "noty" */ 'noty');
		return AR.getEsm(lib);
	}

	/**
	 * Возвращает шаблон уведомления, который будет применен, если не будет передан кастомный
	 * @param  {object} options опции, из которых будут использоваться параметры для увдомления(заголовк, сообщение)
	 * @return {string} шаблонная строка
	 */
	_getTemplate(options) {
		return `<div class="b-noty__body noty_body">
			<span class="b-noty__title">${options.title || text.notificationTitle}</span>
			<span class="b-noty__message">${options.message}</span>
		</div>`;
	}

	/**
	 * Возращает уведомление из хранилища
	 * @param  {string} id идентификатор уведомления
	 * @return {object} объект noty
	 */
	_getNotyById(id) {
		//Получение уведомления из хранилища по идентификатору
		const noty = this.notifications[id];

		//Если уведомление с переданным идентификатором существует, то возвращаем его
		if (noty) {
			return noty;
		} else {
			throw `Уведомление с ID ${id} не найдено`;
		}
	}

	/**
	 * Закрыть уведомление
	 * @param  {object, string} noty объект noty или идентификатор уведомления
	 */
	close(noty) {
		noty = typeof noty == 'string' ? this._getNotyById(noty) : noty;
		noty.close();
	}

	/**
	 * Изменить текст уведомления
	 * @param  {object, string} noty объект noty или идентификатор уведомления
	 */
	setText(noty, text = '') {
		noty = typeof noty == 'string' ? this._getNotyById(noty) : noty;
		noty.setText(text);
	}

	/**
	 * Изменить тип уведомления
	 * @param  {object, string} noty объект noty или идентификатор уведомления
	 */
	setType(noty, type = '') {
		noty = typeof noty == 'string' ? this._getNotyById(noty) : noty;
		noty.setType(type);
	}

	/**
	 * Изменить тему уведомления
	 * @param  {object, string} noty объект noty или идентификатор уведомления
	 */
	setTheme(noty, theme = '') {
		noty = typeof noty == 'string' ? this._getNotyById(noty) : noty;
		noty.setTheme(theme);
	}

	/**
	 * Закрыть все уведомления
	 * @param  {Boolean, string} queueName название очереди
	 */
	closeAll(queueName = false) {
		this.Noty.closeAll(queueName);
	}

	/**
	 * Тригер кастомного события на beforeShow уведомления и вызов кастомной функции
	 * @param  {object} noty объект noty
	 * @param  {function} customHandler кастомная функция, которая была передана в options
	 */
	beforeShow(noty, customHandler) {
		AR.events.emit('onNotificationBeforeShow', noty);

		if (typeof customHandler == 'function') {
			customHandler();
		}
	}

	/**
	 * Тригер кастомного события на onShow уведомления и вызов кастомной функции
	 * @param  {object} noty объект noty
	 * @param  {function} customHandler кастомная функция, которая была передана в options
	 */
	onShow(noty, customHandler) {
		AR.events.emit('onNotificationShow', noty);

		if (typeof customHandler == 'function') {
			customHandler();
		}
	}

	/**
	 * Тригер кастомного события на afterShow уведомления и вызов кастомной функции
	 * @param  {object} noty объект noty
	 * @param  {function} customHandler кастомная функция, которая была передана в options
	 */
	afterShow(noty, customHandler) {
		AR.events.emit('onNotificationAfterShow', noty);

		if (typeof customHandler == 'function') {
			customHandler();
		}
	}

	/**
	 * Тригер кастомного события на onClose уведомления и вызов кастомной функции
	 * @param  {object} noty объект noty
	 * @param  {function} customHandler кастомная функция, которая была передана в options
	 */
	onClose(noty, customHandler) {
		AR.events.emit('onNotificationClose', noty);

		if (typeof customHandler == 'function') {
			customHandler();
		}
	}

	/**
	 * Удаление уведомления из хранилища и тригер кастомного события
	 * @param  {object} noty объект noty
	 */
	_removeFromStore(noty) {
		delete this.notifications[noty.id];
		AR.events.emit('onNotificationRemove', noty);
	}

	/**
	 * Запись уведомления в хранилище и тригер кастомного события
	 * @param  {object} noty объект noty
	 */
	_storeNotification(noty) {
		this.notifications[noty.id] = noty;
		AR.events.emit('onNotificationStore', noty);
	}
}

AR.waitComponents([], () => {
	const cNotification_base = new CNotification_base();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cNotification_base, 'cNotification_base');
});

