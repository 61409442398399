module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'social-services/static-in-line' && templateVars.config ?
				templateVars.config :
				{
	"animationClose": "transition.slideDownOut",
	"animationCloseDuration": 200,
	"animationOpen": "transition.slideUpIn",
	"animationOpenDuration": 200,
	"links": {
		"ru": [
			{
				"modifier": "facebook",
				"targetBlank": true,
				"url": "https://www.facebook.com/NornickelRU/",
				"text": "Facebook"
			},
			{
				"modifier": "twitter",
				"targetBlank": true,
				"url": "https://twitter.com/NornikOfficial",
				"text": "Twitter"
			},
			{
				"modifier": "vkontakte",
				"targetBlank": true,
				"url": "https://vk.com/nornickel_official",
				"text": "ВКонтакте"
			},
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/NornikOfficial",
				"text": "YouTube"
			},
			{
				"modifier": "instagram",
				"targetBlank": true,
				"url": "https://www.instagram.com/nornickel_official/",
				"text": "Instagram"
			}
		],
		"en": [
			{
				"modifier": "facebook",
				"targetBlank": true,
				"url": "https://www.facebook.com/nornickelofficial/",
				"text": "Facebook"
			},
			{
				"modifier": "twitter",
				"targetBlank": true,
				"url": "https://twitter.com/NornickelGroup",
				"text": "Twitter"
			},
			{
				"modifier": "vkontakte",
				"targetBlank": true,
				"url": "https://vk.com/nornickel_official",
				"text": "ВКонтакте"
			},
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/NornikOfficial",
				"text": "YouTube"
			},
			{
				"modifier": "instagram",
				"targetBlank": true,
				"url": "https://www.instagram.com/nornickel_official/",
				"text": "Instagram"
			}
		]
	}
};