module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'bod/bod-gallery' && templateVars.config ?
				templateVars.config :
				{
	"bodContentToggleAnimationDuration": 300,
	"scrollToElementDuration": 300,
	"mobileHA": false,
	"directorsPerRow": 6,
	"gridClass": "col-3 col-md-4 col-sm-6 col-xs-12",
	"directors": [
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD&nbsp;Director Name1",
			"post": "BOD Director post1",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name2",
			"post": "BOD Director post2",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name3",
			"post": "BOD Director post3",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name4",
			"post": "BOD Director post4",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name5",
			"post": "BOD Director post5",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name6",
			"post": "BOD Director post6",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name7",
			"post": "BOD Director post7",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name8",
			"post": "BOD Director post8",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name9",
			"post": "BOD Director post9",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name10",
			"post": "BOD Director post10",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name11",
			"post": "BOD Director post11",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name12",
			"post": "BOD Director post12",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name13",
			"post": "BOD Director post13",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name14",
			"post": "BOD Director post14",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name15",
			"post": "BOD Director post15",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name16",
			"post": "BOD Director post16",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name17",
			"post": "BOD Director post17",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name18",
			"post": "BOD Director post18",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name19",
			"post": "BOD Director post19",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		},
		{
			"photo": "/images/tzar_bod.jpeg",
			"name": "BOD Director Name20",
			"post": "BOD Director post20",
			"biography": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>",
			"text": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut volutpat massa eu dictum interdum. Ut in tincidunt augue. Nulla ac facilisis dolor. Nunc et quam aliquam nibh tempus sagittis. Curabitur efficitur scelerisque metus, quis egestas urna porttitor ut. Quisque porta eros quis elit mollis condimentum. Donec ultricies quis risus ultricies suscipit. Nunc et aliquam quam. Donec varius velit quam, in fermentum dui molestie non. Integer a nisl ac nulla sagittis vulputate a vitae erat. Vestibulum nibh quam, tincidunt ac pulvinar quis, bibendum sed quam. Proin et quam vitae sapien tempor maximus nec sed nisi.</p>"
		}
	]
};