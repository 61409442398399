class CStrategyEvolution_highlights {
	constructor() {
		this.tooltips = [
			{
				btn: '.highlights-1-btn',
				content: '#js-tool-cont__highlights-1-btn'
			},
			{
				btn: '.highlights-2-btn',
				content: '#js-tool-cont__highlights-2-btn'
			},
		];
	}

	init() {
		this.page = $('.body--highlights');

		if (this.page.length) {

			let $accArticleCollection = $('.js-accordion-article');

			AR.events.on('svgRenderedAll', () => {
				let windowH = document.documentElement.clientHeight;
				let $blocks = $('.js-highlights');

				this.creatTooltips();

				function onScroll() {

					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper').height();

					$blocks.each(function (i, block) {
						let offsetTop = $(block).offset().top;

						if (!$(block).hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
								$(block).addClass('block-show');
								$(block).velocity('transition.slideDownBigIn');
							}
						}
					});

					let check;

					if ($('html').hasClass('browser-ie')) {
						$blocks.each(function () {
							check = $(this).hasClass('block-show');
						});
					} else {
						check = [...$blocks].every(el => {//IE не переваривает такие конструкции
							//console.log(el.classList);
							return el.classList.contains('block-show');
						});
					}

					if (check) {
						$(window).off('scroll', onScroll);
					}

				}
				onScroll();

				$(window).on('scroll', onScroll);

				// очистка display: none аккордеонов
				$(window).on('resize', accClear);

				function accClear() {
					let windowW = document.documentElement.clientWidth;

					if (windowW > 768) {

						$accArticleCollection.each((i, el) => {
							el.removeAttribute('style');
						});
					}
				}

			});

			AR.events.on('onSVGZoomInComplete', $.proxy(this, 'creatTooltips', true));
		}
	}

	creatTooltips(context) {
		let modal = context ? '#colorbox ' : '';

		this.tooltips.forEach(el => {
			const $target = $(`${modal}${el.btn}`);

			if ($target.length) {
				AR
					.components
					.cTooltips_base
					.createTooltips($target, {
						// TODO на мобилке ломало
						// side: 'bottom',
						content: $(el.content),
						// functionPosition: function (instance, helper, position) {
						// 	position.coord.top -= 8;
						// 	position.coord.left -= (position.size.width / 2 - 8);
						// 	return position;
						// }
					}, false);
			}
		});
	}
}

AR.waitComponents(['cTooltips_base'], () => {
	const cStrategyEvolution_highlights = new CStrategyEvolution_highlights();

	// Вызов метода, инициализирующего все существующие события
	document.addEventListener('DOMContentLoaded', () => {
		cStrategyEvolution_highlights.init();
	});
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cStrategyEvolution_highlights, 'cStrategyEvolution_highlights');
});
