const CDownloadCenter = require('../../index.js');

/**
 * Класс реализует вариант компонета page для компонента download-center
 */
class CDownloadCenter_page extends CDownloadCenter {
	constructor() {
		super();

		this.$dc = null;
		this.$rows = null;
		this.$triggers = null;
		this.$downloadButton = null;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$dc = $('.js-download-center'); //глобальная обертка download-center

		if (this.$dc.length) {
			this.$rows = this.$dc.find('.js-row'); //линии
			this.$triggers = this.$dc.find('.js-checkbox-item'); //все чекбоксы
			this.$downloadButton = this.$dc.find('.js-download-selected');
			this.$downloadAllButton = this.$dc.find('.js-download-all');

			this.initCheckboxesTriggers();
			this.initDownloadButton();
			this.initDownloadAllButton();
			this.checkDownloadButtonAccess();
		}
	}

	/**
	 * Провесить событие смены состояния чекбоксов
	 */
	initCheckboxesTriggers() {
		$.each(this.$triggers, (i, trigger) => {
			let $trigger = $(trigger);

			$trigger.on('change', $.proxy(this, 'onCheckboxTriggerChange'));
		});
	}

	/**
	 * Получить чекбоксы по ID секции в секции
	 * @param {string} sectionID ID секции
	 * @param {boolean} checkedStatus получить только отмеченные чекбоксы внутри секции
	 */
	getTriggersBySectionID(sectionID, checkedStatus) {
		return this.$triggers.filter((i, item) => {
			const $item = $(item);

			if (typeof checkedStatus != 'undefined') {
				return ($item.attr('data-in-section') == sectionID && $item.prop('checked') == checkedStatus);
			} else {
				return ($item.attr('data-in-section') == sectionID);
			}
		});
	}

	/**
	 * Получить чекбоксы по ID группы в группе
	 * @param {string} sectionID ID секции
	 */
	getGroupsTriggersBySectionID(sectionID) {
		return this.$triggers.filter((i, item) => {
			const $item = $(item);

			if (typeof checkedStatus != 'undefined') {
				return ($item.attr('data-group-trigger') == sectionID && $item.prop('checked') == checkedStatus);
			} else {
				return ($item.attr('data-group-trigger') == sectionID);
			}
		});
	}

	/**
	 * Callback на событие смены состояния чекбокса
	 * @param {object} e событие смены состояния чекбокса
	 */
	onCheckboxTriggerChange(e) {
		const $trigger = $(e.currentTarget);
		const isGroupTrigger = typeof $trigger.attr('data-group-trigger') != 'undefined';
		const sectionID = (isGroupTrigger) ? $trigger.attr('data-group-trigger') : $trigger.attr('data-in-section'); //чексбокс-родитель

		//если мы кликнули на родителя
		if (isGroupTrigger && typeof sectionID != 'undefined' && sectionID !== false) {
			let $accordionTrigger = $trigger
				.closest('.js-row')
				.find('.js-accordion-button')
				.first();

			$trigger
				.closest('.b-checkbox')
				.removeClass('is-partly-selected');

			//прочекиваем всех потомков, либо снимаем чек
			if ($trigger.prop('checked')) {
				this
					.getTriggersBySectionID(sectionID)
					.prop('checked', true);

				if (!$accordionTrigger.hasClass('is-expand')) {
					AR.components.cAccordions_base.open($accordionTrigger);
				}
			} else {
				this
					.getTriggersBySectionID(sectionID)
					.prop('checked', false);
			}
			//иначе если мы кликнули на потомка
		} else if (!isGroupTrigger && typeof sectionID != 'undefined' && sectionID !== false) {
			//считаем общее количество всех потомков
			let totalCount = this
				.getTriggersBySectionID(sectionID)
				.length;

			if ($trigger.prop('checked')) {
				//считаем общее количество чекнутых потомков
				let checkedCount = this
					.getTriggersBySectionID(sectionID, true)
					.length;

				//если чекнут хотя бы один, то чекаем родителя
				if (checkedCount > 0) {
					const $groupTrigger = this.getGroupsTriggersBySectionID(sectionID);

					if (checkedCount != totalCount) {
						$groupTrigger
							.closest('.b-checkbox')
							.addClass('is-partly-selected');
					} else {
						$groupTrigger
							.closest('.b-checkbox')
							.removeClass('is-partly-selected');

						$groupTrigger.prop('checked', true);
					}
				}
			} else {
				let uncheckedCount = this
					.getTriggersBySectionID(sectionID, false)
					.length;

				const $groupTrigger = this.getGroupsTriggersBySectionID(sectionID);

				//если все не чекнуты - снимаем чек с родителя
				if (totalCount === uncheckedCount) {
					$groupTrigger
						.closest('.b-checkbox')
						.removeClass('is-partly-selected');

					$groupTrigger.prop('checked', false);
				} else {
					$groupTrigger
						.closest('.b-checkbox')
						.addClass('is-partly-selected');

					$groupTrigger.prop('checked', false);
				}
			}
		}

		this.checkDownloadButtonAccess();
	}
}

AR.waitComponents(['cAccordions_base'], () => {
	const cDownloadCenter_page = new CDownloadCenter_page();
	// Вызов метода со всеми событиями
	cDownloadCenter_page.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cDownloadCenter_page, 'cDownloadCenter_page');
});
