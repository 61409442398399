const CTabs = require('../../index.js');

/**
 * Реализует логику варианта vertical компонента tabs
 */
class CTabs_vertical extends CTabs {
	constructor() {
		super();
	}
}

AR.waitComponents([], () => {
	const cTabs_vertical = new CTabs_vertical();
	// Вызов метода, инициализирующего все существующие события
	cTabs_vertical.init('vertical');
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTabs_vertical, 'cTabs_vertical');
});
