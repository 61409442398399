const platform = require('platform');

/**
 * Класс реализует базовую логику для компонента page-nav
 */
class CPageNav {
	constructor() {
		this.osName = platform.os.family;
		this.KEYCODE_LEFT = 37;
		this.KEYCODE_RIGHT = 39;
		this.$pagenav = $('.js-pagenav');
		this.$pagenavLink = this.$pagenav.find($('.js-pagenav-link'));
		this.$scrollUpButton = this.$pagenav.find($('.js-scrollup'));
		this.$pagenavHelpKey = this.$pagenav.find($('.js-help-key'));
	}

	init() {
		$(document).on('keydown', $.proxy(this, 'checkKeyDown'));
	}

	/**
	 * Скрол в начало страницы
	 */
	scrollUp() {
		$('html').velocity('scroll', {axis: '0'});
	}

	/**
	 * Добавить клавишу для быстрого переключения в текст кнопок
	 */
	addHelpKey() {
		if (this.osName == 'OS X') {
			this.$pagenavHelpKey.html('Alt');
		} else if (this.osName == 'Android' || this.osName == 'iOS') {
			$('.b-pagenav-help').remove();
		} else {
			this.$pagenavHelpKey.html('Ctrl');
		}
	}

	/**
	 * Перелистывать страницу при нажатии горячих клавиш
	 * @param {object} event событие нажатия на клавишу
	 */
	checkKeyDown(event) {
		const eventKey = this.osName == 'OS X' ? event.altKey : event.ctrlKey;

		if (eventKey && event.keyCode == this.KEYCODE_LEFT) {
			this.toLeft(event);
		} else if (eventKey && event.keyCode == this.KEYCODE_RIGHT) {
			this.toRight(event);
		}
	}

	/**
	 * Удалить тач классы
	 */
	removeTouchClass() {
		if (this.$pagenavLink.hasClass('is-touch') || $('.js-scrollup').hasClass('is-touch')) {
			this.$pagenavLink.removeClass('is-touch');
			this.$scrollUpButton.removeClass('is-touch');
		}
	}

	/**
	 * callback на событие тача
	 * @param {object} e событие тача
	 */
	touchStart(e) {
		const $this = $(e.currentTarget);
		if ($this.hasClass('.b-pagenav__link--disabled')) {
			e.preventDefault();
			return false;
		} else {
			if ($this.hasClass('is-touch') && !$this.hasClass('js-scrollup')) {
				document.location.href = $this.attr('href');
				return false;
			} else if ($this.hasClass('js-scrollup') && $this.hasClass('is-touch')) {
				this.scrollUp();
			} else {
				$this.addClass('is-touch');
				this.$pagenavLink.not($this).removeClass('is-touch');
				e.preventDefault();
				return false;
			}
		}
	}

	/**
	 * Перейти на предыдущую страницу
	 * @param {object} event событие нажатия на клавишу
	 */
	toLeft(event) {
		event.preventDefault(event);

		if (templateVars.prev && templateVars.prev.link) {
			document.location.href = templateVars.prev.link;
		}
	}

	/**
	 * Перейти на следующую страницу
	 * @param {object} event событие нажатия на клавишу
	 */
	toRight(event) {
		event.preventDefault(event);

		if (templateVars.next && templateVars.next.link) {
			document.location.href = templateVars.next.link;
		}
	}
}

module.exports = CPageNav;

AR.waitComponents([], () => {
	const cPageNav = new CPageNav();
	// Вызов метода со всеми событиями
	cPageNav.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPageNav, 'cPageNav');
});
