/**
 * Класс реализует базовую логику для компонента UsefulLinks
 */
class CUsefulLinks {
	constructor() {

	}
}

module.exports = CUsefulLinks;
