const config = require('./config.yaml');
const CUsefulLinks = require('../../index.js');

/**
 * Класс CUsefulLinks_compact, наследник CUsefulLinks
 * Реализует логику варианта compact компонента UsefulLinks
*/
class CUsefulLinks_compact extends CUsefulLinks {
	constructor() {
		super();
		// Компонент перекрестных ссылок
		this.$seemore = $('.js-useful-links-compact');
		// Кнопка для выпадающего списка с ссылками
		this.$btn = this.$seemore.find('.js-useful-links-btn');
		// Файл config.yaml (содержит параметры для анимации)
		this.config = config;
		// Вызов события клика по кнопке
		this.$btn.on('click', $.proxy(this, 'onInitClickEvents'));
	}

	/**
	* Смена состояния кнопки
	*/
	toggle($object) {
		const $list = $object.siblings('.js-useful-links-list');
		// Сменить класс у объекта на класс is-active
		$object.toggleClass('is-active');
		// Если элемемнт имеет класс - is-active
		if ($object.hasClass('is-active')) {
			// Вызов события начала раскрытия списка
			AR.events.emit('onUsefulLinksCompactOpenStart', $object);
			// Вызов метода - open, раскрывающий выпадающий список
			this
				.open($list)
				// Вызов функции после раскрытия списка
				.then(() => {
					// Вызов события завершения раскрытия списка
					AR.events.emit('onUsefulLinksCompactOpenEnd', $object);

					if (config.documentClickClose) {
						// Запуск события при клике по документу
						$(document).on('click', $.proxy(this, 'onDocumentListener'));
					}
				});
		} else {
			// Вызов события начала закрытия списка
			AR.events.emit('onUsefulLinksCompactCloseStart', $object);

			if (config.documentClickClose) {
				// Удаление события при клике на document
				$(document).off('click', $.proxy(this, 'onDocumentListener'));
			}
			// Вызов метода - close, закрывающий выпадающий список
			this
				.close($list)
				// Вызов функции после скрытия списка
				.then(() => {
					// Вызов события завершения закрытия списка
					AR.events.emit('onUsefulLinksCompactCloseEnd', $object);
				});
		}
		global.blurOnLeave(this);
	}

	/**
	  * Инициализация события раскрытия выпадающего списка
	*/
	open($list, $object) {
		// Velocity promise для анимации открытия
		return $.Velocity.animate($list, this.config.animationOpen, this.config.animationOpenDuration);
	}

	/**
	* Инициализация события закрытия выпадающего списка
	*/
	close($list, $object) {
		// Velocity promise для анимации закрытия
		return $.Velocity.animate($list, this.config.animationClose, this.config.animationCloseDuration);
	}

	/**
	* Инициализация события клика на кнопку
	*/
	onInitClickEvents(event) {
		this.toggle($(event.currentTarget));
	}

	/**
	* Инициализация события клика по документу
	*/
	onDocumentListener(event) {
		// Если у нажатого элемента нет родителя с классом, закрывается выпадающий список
		if (!$(event.target).closest('.js-useful-links-compact').length) {
			this.toggle(this.$btn);
		}
	}
}

const cUsefulLinks_compact = new CUsefulLinks_compact();
// Добавление в глобальный объект AR.components
AR.pushComponent(cUsefulLinks_compact, 'cUsefulLinks_compact');
