class COverview_base {
	constructor() {}

	init() {

		this.$blocks = $('.body--overview .js-accel--overview');
		this.animated = 0;

		AR.events.on('svgRenderedAll', () => {
			let windowW = document.documentElement.clientWidth;

			for (let i = 1; i < 5; i++) {
				AR
					.components
					.cTooltips_base
					.createTooltips($(`.tooltip-icon--${i}`), {
						content: $(`#tooltip-content-${i}`)
					}, false);
			}

			if (windowW > 798 && this.$blocks.length) {

				$(window).on('scroll', scrollFunction);

				var target = this;

				function scrollFunction() {
					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper .l-page').height();

					target.$blocks.each(function (i, block) {
						let offsetTop = $(block).offset().top;
						let windowH = document.documentElement.clientHeight;

						if (!$(block).hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
								$(block).addClass('block-show');
								$(block).velocity('transition.slideDownBigIn');

								target.animated++;

								if (target.$blocks.length == target.animated) {
									$(window).off('scroll', scrollFunction);
								}
							}
						}
					});
				}
			}
		});
	}
}

AR.waitComponents([], () => {
	const cOverview_base = new COverview_base();

	// Вызов метода, инициализирующего все существующие события
	cOverview_base.init();

	function check() {
		scrollFunction(cOverview_base);
	}

	// Добавление в глобальный объект AR.components
	AR.pushComponent(cOverview_base, 'cOverview_base');
});
