import AR from 'component/core/.variant/base/index.js';
import addExcelExport from 'component/excel-export/.variant/base';
import config from './config.yaml';

// Графики, для которых необходимо сформировать табличную легенду
const chartsWithTableLegend = (config.chartsWithTableLegend.length)
	? config.chartsWithTableLegend
	: [];

/**
 * Метод для отрисовки данных легенды в виде таблицы
 * @param items
 */
function addElementWithDataFunc(items) {
	const chart = this;

	items
		.html(function (d) {
			return chart.getGroupValues(d).map(function (value) {
				return `<div class="legend__text legend__value">${value || ''}</div>`;
			}).join('\n');
		});
	items.append('div')
		.classed('legend__pict', true)
		.style('min-width', chart.markHeight + 'px')
		.style('min-height', chart.markHeight + 'px')
		.style('background-color', function (d) {
			return chart.getColor(d);
		});
	items.append('p').classed('legend__text', true)
		.html(String);

	let width;
	items.select('.legend__text.legend__value')
		.each(function (selection) {
			width = this.getBoundingClientRect().width;
		});
	// var svg = d3.select($(items.node()).parents('.chart').find('svg')[0]);
	for (let i = 1; i < chart.data.categories.length; i++) {
		chart.svg.append('line')
			.attr({
				x1: width * i,
				y1: chart.options.height,
				x2: width * i,
				y2: chart.options.height - 35,
				class: 'chart__line--gpn'
			});
	}
}

/**
 * Метод инициализации графиков по данным, полученным из charts.json
 * @param callback
 */
function initCharts(callback) {
	require.ensure(['zebra-charts', './charts.json'], function (require) {
		const ZebraCharts = require('zebra-charts');
		const zebraCharts = new ZebraCharts(AR.lang);
		const charts = require('./charts.json');

		charts.filter(chart => chartsWithTableLegend.indexOf(chart.container) > -1)
			.forEach(chart => chart.addElementFunc = addElementWithDataFunc);

		global.zebraCharts = zebraCharts;
		zebraCharts.makeCharts(charts, callback);
	});
}

/**
 * Инициализация графика по ID его контейнера
 * @param dataContainerID - ID контейнера, по которому мы возьмем данные из конфига
 * @param htmlContainerID - ID контейнера, в который мы пихнем новый график
 * @param callback
 */
function initChartByContainerID(dataContainerID, htmlContainerID = dataContainerID, callback) {
	require.ensure(['zebra-charts', './charts.json'], function (require) {
		const ZebraCharts = require('zebra-charts');
		const zebraCharts = new ZebraCharts(AR.lang);
		global.zebraCharts = zebraCharts;

		const charts = require('./charts.json');
		const needleChart = charts.filter(chart => chart.container == dataContainerID);

		if (needleChart.length) {
			let cloneNeedleChart = JSON.parse(JSON.stringify(needleChart));

			cloneNeedleChart.filter(chart => chartsWithTableLegend.indexOf(chart.container) > -1)
				.forEach(chart => chart.addElementFunc = addElementWithDataFunc);

			cloneNeedleChart[0].container = htmlContainerID;
			zebraCharts.makeCharts(cloneNeedleChart, callback);
		}
	});
}

const chartElements = $('.chart');
if (chartElements.length) {
	initCharts(function () {
		const $charts = $('.graphic').filter(function (index, el) {
			if ($(this).find('.chart').length) {
				return true;
			}
		});

		$charts.each((i, elem) => {
			const $elem = $(elem);

			if (!$elem.find('.figure-buttons.js-buttons').length) {
				$elem.append('<div class="figure-buttons js-buttons"></div>');
			}
		});

		addExcelExport($charts);
	});
}

export default initChartByContainerID;
