module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'analysis/base' && templateVars.config ?
				templateVars.config :
				{
	"config": {
		"colors": [
			"#009FE3",
			"#133A60",
			"#F9B234",
			"#7AC05E",
			"#434343",
			"#00589C",
			"#939393",
			"#FCEE18",
			"#9FA738",
			"#879DB2",
			"#567483",
			"#183642",
			"#BCBEC0",
			"#231F20",
			"#ff9896",
			"#9467bd",
			"#c5b0d5",
			"#8c564b",
			"#c49c94",
			"#e377c2",
			"#f7b6d2",
			"#7f7f7f",
			"#c7c7c7",
			"#bcbd22",
			"#dbdb8d",
			"#17becf"
		],
		"selectionMaximum": 7,
		"size": {
			"height": 350,
			"width": 530
		}
	},
	"data": {
		"presets": [
			{
				"id": 1,
				"title": {
					"ru": "Выручка",
					"en": "Revenue"
				},
				"items": [
					1,
					10
				]
			},
			{
				"id": 2,
				"title": {
					"ru": "EBITDA",
					"en": "EBITDA"
				},
				"items": [
					2,
					3,
					13,
					14
				]
			},
			{
				"id": 3,
				"title": {
					"ru": "Чистая прибыль",
					"en": "Net porfit"
				},
				"items": [
					4,
					16
				]
			}
		],
		"years": [
			2014,
			2015,
			2016,
			2017,
			2018,
			2019
		],
		"units": {
			"млн долл. США": {
				"ru": "млн долл. США",
				"en": "US $ million"
			},
			"%": {
				"ru": "%",
				"en": "%"
			},
			"-": {
				"ru": "",
				"en": ""
			},
			"долл. США": {
				"ru": "долл. США",
				"en": "US $"
			},
			"тонны": {
				"ru": "тонны",
				"en": "Tons"
			},
			"тыс.тр. унций": {
				"ru": "тыс.тр. унций",
				"en": "Thousand troy ounces"
			},
			"чел.": {
				"ru": "чел.",
				"en": "people"
			},
			"тыс. чел.": {
				"ru": "тыс. чел.",
				"en": "Thousand people"
			},
			"тыс. тонн": {
				"ru": "тыс. тонн",
				"en": "тыс. тонн"
			},
			"млн т": {
				"ru": "млн т",
				"en": "Million tons"
			},
			"млн  м3": {
				"ru": "млн  м3",
				"en": "Million m3"
			},
			"млн ч": {
				"ru": "на 1 млн часов",
				"en": "per million hours worked"
			}
		},
		"groups": [
			{
				"id": 1,
				"title": {
					"ru": "Ключевые финансовые показатели",
					"en": "Key financial indicators"
				},
				"items": [
					{
						"id": 1,
						"title": {
							"ru": "Выручка",
							"en": "Revenue"
						},
						"units": "млн долл. США",
						"values": [
							11869,
							8542,
							8259,
							9146,
							11670,
							13563
						]
					},
					{
						"id": 2,
						"title": {
							"ru": "EBITDA",
							"en": "EBITDA"
						},
						"units": "млн долл. США",
						"values": [
							5681,
							4296,
							3899,
							3995,
							6231,
							7923
						]
					},
					{
						"id": 3,
						"title": {
							"ru": "Рентабельность EBITDA",
							"en": "EBITDA margin"
						},
						"units": "%",
						"values": [
							47.9,
							50.3,
							47.2,
							43.7,
							53.4,
							58.4
						]
					},
					{
						"id": 4,
						"title": {
							"ru": "Чистая прибыль",
							"en": "Net profit"
						},
						"units": "млн долл. США",
						"values": [
							2000,
							1716,
							2531,
							2123,
							3059,
							5966
						]
					},
					{
						"id": 5,
						"title": {
							"ru": "Капитальные затраты",
							"en": "Сapital expenditures"
						},
						"units": "млн долл. США",
						"values": [
							1298,
							1654,
							1714,
							2002,
							1553,
							1324
						]
					},
					{
						"id": 6,
						"title": {
							"ru": "Свободный денежный поток",
							"en": "Free cash flow"
						},
						"units": "млн долл. США",
						"values": [
							4725,
							2405,
							1591,
							-173,
							4931,
							4889
						]
					},
					{
						"id": 7,
						"title": {
							"ru": "Чистый долг",
							"en": "Net debt"
						},
						"units": "млн долл. США",
						"values": [
							3537,
							4212,
							4530,
							8201,
							7051,
							7060
						]
					},
					{
						"id": 8,
						"title": {
							"ru": "Чистый долг /12M EBITDA",
							"en": "Net debt /12M EBITDA"
						},
						"units": "-",
						"values": [
							0.6,
							1,
							1.2,
							2.1,
							1.1,
							0.9
						],
						"axis_y_precision_format": ".1f"
					},
					{
						"id": 9,
						"title": {
							"ru": "Выплаченные дивиденд на акцию",
							"en": "Dividend paid per share"
						},
						"units": "долл. США",
						"values": [
							20.7,
							18.1,
							7.8,
							18.8,
							21.3,
							26.3
						]
					}
				]
			},
			{
				"id": 2,
				"title": {
					"ru": "Отчет о прибылях и убытках",
					"en": "Profits and Losses Report"
				},
				"items": [
					{
						"id": 10,
						"title": {
							"ru": "Выручка",
							"en": "Revenue"
						},
						"units": "млн долл. США",
						"values": [
							11869,
							8542,
							8259,
							9146,
							11670,
							13563
						]
					},
					{
						"id": 11,
						"title": {
							"ru": "Валовая прибыль",
							"en": "Gross profit"
						},
						"units": "млн долл. США",
						"values": [
							6195,
							4761,
							4118,
							4575,
							6543,
							8370
						]
					},
					{
						"id": 12,
						"title": {
							"ru": "Прибыль от операционной деятельности",
							"en": "Operating profit"
						},
						"units": "млн долл. США",
						"values": [
							4746,
							3506,
							3281,
							3123,
							5416,
							7036
						]
					},
					{
						"id": 13,
						"title": {
							"ru": "EBITDA",
							"en": "EBITDA"
						},
						"units": "млн долл. США",
						"values": [
							5681,
							4296,
							3899,
							3995,
							6231,
							7923
						]
					},
					{
						"id": 14,
						"title": {
							"ru": "Рентабельность EBITDA",
							"en": "EBITDA margin"
						},
						"units": "%",
						"values": [
							47.9,
							50.3,
							47.2,
							43.7,
							53.4,
							58.4
						]
					},
					{
						"id": 15,
						"title": {
							"ru": "Прибыль до налогов",
							"en": "Profit before taxes"
						},
						"units": "млн долл. США",
						"values": [
							2660,
							2244,
							3276,
							2844,
							3902,
							7524
						]
					},
					{
						"id": 16,
						"title": {
							"ru": "Чистая прибыль",
							"en": "Net profit"
						},
						"units": "млн долл. США",
						"values": [
							2000,
							1716,
							2531,
							2123,
							3059,
							5966
						]
					}
				]
			},
			{
				"id": 3,
				"title": {
					"ru": "Отчет о финансовом положении",
					"en": "Statement of financial position"
				},
				"items": [
					{
						"id": 18,
						"title": {
							"ru": "Совокупные Активы",
							"en": "Total assets"
						},
						"units": "млн долл. США",
						"values": [
							13149,
							13371,
							16523,
							16635,
							15251,
							19474
						]
					},
					{
						"id": 19,
						"title": {
							"ru": "Акционерный капитал",
							"en": "Total equity"
						},
						"units": "млн долл. США",
						"values": [
							4793,
							2261,
							3896,
							4658,
							3473,
							4287
						]
					},
					{
						"id": 20,
						"title": {
							"ru": "Совокупные обязательства",
							"en": "Total liabilities"
						},
						"units": "млн долл. США",
						"values": [
							8356,
							11110,
							12627,
							11977,
							11778,
							15187
						]
					},
					{
						"id": 21,
						"title": {
							"ru": "Совокупный долг",
							"en": "Gross debt"
						},
						"units": "млн долл. США",
						"values": [
							6330,
							8266,
							7855,
							9053,
							8439,
							9844
						]
					},
					{
						"id": 22,
						"title": {
							"ru": "Чистый долг",
							"en": "Net debt"
						},
						"units": "млн долл. США",
						"values": [
							3537,
							4212,
							4530,
							8201,
							7051,
							7060
						]
					},
					{
						"id": 23,
						"title": {
							"ru": "Оборотный капитал",
							"en": "Working capital"
						},
						"units": "млн долл. США",
						"values": [
							1087,
							1030,
							445,
							2149,
							867,
							985
						]
					}
				]
			},
			{
				"id": 4,
				"title": {
					"ru": "Отчет о движении денежных средств",
					"en": "Statement of cash flows"
				},
				"items": [
					{
						"id": 24,
						"title": {
							"ru": "Денежные средства от операционной деятельности, нетто",
							"en": "Net cash generated from operating activities"
						},
						"units": "млн долл. США",
						"values": [
							5947,
							3705,
							3492,
							1763,
							6493,
							6009
						]
					},
					{
						"id": 25,
						"title": {
							"ru": "Денежные средства, направленные на инвестиционную деятельность, нетто",
							"en": "Net cash used in investing activities"
						},
						"units": "млн долл. США",
						"values": [
							-1222,
							-1300,
							-1901,
							-1936,
							-1562,
							-1120
						]
					},
					{
						"id": 26,
						"title": {
							"ru": "Денежные средства, направленные на финансовую деятельность, нетто",
							"en": "Net cash used in financing activities"
						},
						"units": "млн долл. США",
						"values": [
							-2979,
							-998,
							-2399,
							-2237,
							-4304,
							-1623
						]
					},
					{
						"id": 27,
						"title": {
							"ru": "Свободный денежный поток",
							"en": "Free cash flow"
						},
						"units": "млн долл. США",
						"values": [
							4725,
							2405,
							1591,
							-173,
							4931,
							4889
						]
					},
					{
						"id": 28,
						"title": {
							"ru": "Изменение оборотного капитала",
							"en": "Change in working capital"
						},
						"units": "млн долл. США",
						"values": [
							-1931,
							-57,
							-575,
							1694,
							-1282,
							118
						]
					},
					{
						"id": 29,
						"title": {
							"ru": "Дивиденды выплаченные Компанией",
							"en": "Dividends paid by the Company"
						},
						"units": "млн долл. США",
						"values": [
							3281,
							2859,
							1232,
							2971,
							3369,
							4166
						]
					},
					{
						"id": 30,
						"title": {
							"ru": "Капитальные затраты",
							"en": "Сapital expenditures"
						},
						"units": "млн долл. США",
						"values": [
							1298,
							1654,
							1714,
							2002,
							1553,
							1324
						]
					}
				]
			},
			{
				"id": 5,
				"title": {
					"ru": "Дивиденды",
					"en": "Dividends"
				},
				"items": [
					{
						"id": 31,
						"title": {
							"ru": "Дивиденды объявленные за период",
							"en": "Dividends declared for the period"
						},
						"units": "млн долл. США",
						"values": [
							4798,
							2148,
							2339,
							2162,
							3739,
							3600
						]
					},
					{
						"id": 32,
						"title": {
							"ru": "Дивиденды выплаченные Компанией",
							"en": "Dividends paid by the Company"
						},
						"units": "млн долл. США",
						"values": [
							3281,
							2859,
							1232,
							2971,
							3369,
							4166
						]
					},
					{
						"id": 33,
						"title": {
							"ru": "Выплаченные дивиденд на акцию",
							"en": "Dividend paid per share"
						},
						"units": "долл. США",
						"values": [
							20.7,
							18.1,
							7.8,
							18.8,
							21.3,
							26.3
						]
					},
					{
						"id": 34,
						"title": {
							"ru": "Прибыль на акцию",
							"en": "Margin per share"
						},
						"units": "долл. США",
						"values": [
							12.7,
							11,
							16.1,
							13.5,
							19.5,
							36.5
						]
					}
				]
			},
			{
				"id": 6,
				"title": {
					"ru": "Производство товарных металлов",
					"en": "Saleable metals production"
				},
				"items": [
					{
						"id": 35,
						"title": {
							"ru": "Никель",
							"en": "Nickel"
						},
						"units": "тонны",
						"values": [
							274248,
							266406,
							235749,
							217112,
							218770,
							228687
						]
					},
					{
						"id": 36,
						"title": {
							"ru": "Медь",
							"en": "Copper"
						},
						"units": "тонны",
						"values": [
							368008,
							369426,
							360217,
							401081,
							473654,
							499119
						]
					},
					{
						"id": 37,
						"title": {
							"ru": "Палладий",
							"en": "Palladium"
						},
						"units": "тыс.тр. унций",
						"values": [
							2752,
							2689,
							2618,
							2780,
							2729,
							2922
						]
					},
					{
						"id": 38,
						"title": {
							"ru": "Платина",
							"en": "Platinum"
						},
						"units": "тыс.тр. унций",
						"values": [
							662,
							656,
							644,
							670,
							653,
							702
						]
					}
				]
			},
			{
				"id": 7,
				"title": {
					"ru": "Доли на рынке металлов",
					"en": "Shares in the Metal Market"
				},
				"items": [
					{
						"id": 39,
						"title": {
							"ru": "Никель (по производству первичного никеля)",
							"en": "Nickel (in primary nickel production)"
						},
						"units": "%",
						"values": [
							13,
							14,
							12,
							10,
							11,
							9
						]
					},
					{
						"id": 40,
						"title": {
							"ru": "Медь",
							"en": "Copper"
						},
						"units": "%",
						"values": [
							2,
							2,
							2,
							2,
							2,
							2
						]
					},
					{
						"id": 41,
						"title": {
							"ru": "Палладий",
							"en": "Palladium"
						},
						"units": "%",
						"values": [
							44,
							39,
							40,
							40,
							39,
							41
						]
					},
					{
						"id": 42,
						"title": {
							"ru": "Платина",
							"en": "Platinum"
						},
						"units": "%",
						"values": [
							14,
							11,
							11,
							11,
							10,
							11
						]
					}
				]
			},
			{
				"id": 8,
				"title": {
					"ru": "Показатели устойчивого развития",
					"en": "Indicators of sustainable development"
				},
				"items": [
					{
						"id": 43,
						"title": {
							"ru": "Среднесписочная численность сотрудников",
							"en": "Average number of employees"
						},
						"units": "чел.",
						"values": [
							81855,
							83624,
							82006,
							78950,
							75901,
							73715
						]
					},
					{
						"id": 44,
						"title": {
							"ru": "Средняя зарплата работников Группы в России",
							"en": "Average salary of the Group's employees in Russia"
						},
						"units": "долл. США",
						"values": [
							1963,
							1393,
							1405,
							1784,
							1780,
							1835
						]
					},
					{
						"id": 45,
						"title": {
							"ru": "Текучесть персонала",
							"en": "Staff turnover"
						},
						"units": "%",
						"values": [
							11.3,
							10.7,
							10.5,
							10.3,
							10.2,
							10.5
						]
					},
					{
						"id": 47,
						"title": {
							"ru": "Коэффициент частоты травм с временной потерей трудоспособности (LTIFR)",
							"en": "LTIFR"
						},
						"units": "млн ч",
						"values": [
							0.48,
							0.62,
							0.36,
							0.44,
							0.23,
							0.32
						]
					},
					{
						"id": 48,
						"title": {
							"ru": "Коэффициент частоты несчастных случаев со смертельным исходом (FIFR)",
							"en": "FIFR"
						},
						"units": "млн ч",
						"values": [
							0.07,
							0.12,
							0.11,
							0.08,
							0.05,
							0.08
						]
					},
					{
						"id": 49,
						"title": {
							"ru": "Общее количество производственных несчастных случаев",
							"en": "Total number of accidents"
						},
						"units": "чел.",
						"values": [
							64,
							88,
							56,
							61,
							32,
							44
						]
					},
					{
						"id": 50,
						"title": {
							"ru": "Расходы на окружающую среду",
							"en": "Costs of environmental protection"
						},
						"units": "млн долл. США",
						"values": [
							505,
							387,
							373,
							444,
							507,
							596
						]
					},
					{
						"id": 51,
						"title": {
							"ru": "Возмещение ущерба и плата за допустимые и сверхнормативные выбросы",
							"en": "Capital expenditures for environmental protection"
						},
						"units": "млн долл. США",
						"values": [
							null,
							null,
							11,
							15,
							11,
							14
						]
					},
					{
						"id": 52,
						"title": {
							"ru": "Выбросы загрязняющих веществ в атмосферу",
							"en": "Emissions into the atmosphere"
						},
						"units": "тыс. тонн",
						"values": [
							2008,
							2063,
							1936,
							1846,
							1926,
							1953
						]
					},
					{
						"id": 53,
						"title": {
							"ru": "Образование отходов на предприятиях",
							"en": "Waste generation in enterprises"
						},
						"units": "млн т",
						"values": [
							35.2,
							33.6,
							33.3,
							31.9,
							30.7,
							36.4
						]
					},
					{
						"id": 54,
						"title": {
							"ru": "Использование отходов на предприятиях",
							"en": "Use of waste in enterprises"
						},
						"units": "млн т",
						"values": [
							18.2,
							18.8,
							20.2,
							20.8,
							21.6,
							22.8
						]
					},
					{
						"id": 55,
						"title": {
							"ru": "Общий объем забираемой воды",
							"en": "Total amount of water taken"
						},
						"units": "млн  м3",
						"values": [
							352,
							348.5,
							328.4,
							335.6,
							356.8,
							319.4
						]
					},
					{
						"id": 56,
						"title": {
							"ru": "Вклад в реализацию программы переселения",
							"en": "Implementation of the resettlement program"
						},
						"units": "млн долл. США",
						"values": [
							26.1,
							21.6,
							13.6,
							14.2,
							13.2,
							12.8
						]
					}
				]
			}
		]
	}
};