const cookie = require('cookie_js');
const platform = require('platform');
const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
const COldBrowser = require('../../index.js');
const isTest = global.testComponentName;
const isSelfTest = isTest && global.testComponentName == 'old-browser/base';

class COldBrowser_base extends COldBrowser {
	constructor() {
		super();
		// Получаем config.yaml
		this.config = config;
	}
	// Метод, где инициализируется событие клика на крестик, и генерация шаблона уведомления
	init() {
		return new Promise((resolve) => {
			if (typeof this.config.browsers[platform.name] == 'undefined' || isTest) {
				resolve();
			}

			// Получаем версии браузеров из config.yaml
			const versionRequire = isTest && !isSelfTest ? 0 : Number(this.config.browsers[platform.name].version);
			// Получаем текущую версию браузера пользователя
			const versionMatch = parseFloat(platform.version);
			const clientVersion = isSelfTest ? 0 : (versionMatch ? versionMatch : 0);
			const isMicrosoftBrowser = $('html').hasClass('browser-ie');
			const isOldBrowser = clientVersion < versionRequire;
			this.cookieName = isOldBrowser ? 'shown_old_browser' : (isMicrosoftBrowser ? 'shown_ie_compatibility' : '');

			// Если текущая версия браузера пользователя ниже версии заданной в config.yaml
			if ((isOldBrowser || isMicrosoftBrowser) && cookie(this.cookieName) != 'true') {
				// По готовности документа
				$('document').ready(() => {
					// Получить кнопку для закрытия формы (если параметр - closable активирован, получаем кнопку, если нет - получаем пустое значение)
					const buttonClose = (isOldBrowser && this.config.closableOld) || (isMicrosoftBrowser && this.config.closableIe && !isOldBrowser)
						? `<button class="${this.config.buttonClass} js-button-close" title="${text.closeBtnTitle}"><span class="btn__text">${text.closeBtnText}</span></button>` : '';
					const textMessage = isOldBrowser ? text.message : text.ieCompatibilityMode;

					// Шаблон формы уведомления (text.message - текст, задаваемый в text.yaml - в параметре - message)
					const notyTemplate = () => {
						return `<div class="b-noty__body noty_body">
									<span class="b-noty__text">${textMessage.replace('{{rootUrl}}', templateVars.siteLangRootUrlRel)}</span>
									<span class="b-noty__buttons">
										${buttonClose}
									</span>
								</div>`;
					};

					// Прописываем параметры для формы уведомления
					const oldBrowserNotify = {
						// id, который будет присвоем главному блоку
						id: 'old-browser',
						// Тема
						themeModifier: 'old-browser',
						// Тип уведомления
						type: 'information',
						// Текст, выводимый в сообщении
						message: textMessage,
						// Шаблон уведомления (передаётся переменная, обьявленная выше)
						onTemplate: notyTemplate,
						// Функция, вызываемая при начале показа уведомления
						onShow: () => {
							// Если параметр - isStatic равен - true
							if (this.config.isStatic) {
								// Получить id главного блока
								const $notyLayoutDefault = $('#noty_layout__default');
								// Если индекс главного блоке не равен - 0
								if ($notyLayoutDefault.index()) {
									//  Добавить блоку класс - noty_layout--static
									$notyLayoutDefault
										.addClass('noty_layout--static')
										// Задать ему индекс - 0
										.prependTo('body');
								}
							}
						},
					};
					// Получаем доступ к параметрам компонента - notification, в качестве аргумента передаются заданные параметры для ld-browser
					AR.components.cNotification_base.createNotification(oldBrowserNotify)
						.then(() => {
							// Получаем id блока компонента - old-browser
							this.$cookieLawPlate = $('#old-browser');
							// Если блок на странице найден
							if (this.$cookieLawPlate.length) {
								// Получить кнопку для закрытия уведомления
								this.$closeButton = this.$cookieLawPlate.find('.js-button-close');
								// Провесить обработчик на кнопку закрытия уведомления (обработчик создает куки при закрытии, для отмены повторного показа уведомления)
								this.$closeButton.on('click', $.proxy(this, 'onClickCloseButton'));
							}

							resolve();
						});
				});
			} else {
				resolve();
			}
		});
	}
	// Обработка клика по кнопке закрытия уведомления
	onClickCloseButton() {
		this.setShownCookie();
	}
	// Метод, инициализирующий создание куки
	setShownCookie() {
		cookie.set(this.cookieName, 'true', {path: '/', expires: 365});
	}
}

AR.waitComponents(['cNotification_base'], () => {
	const cOldBrowser_base = new COldBrowser_base();

	cOldBrowser_base
		.init()
		.then(() => {
			// Добавление в глобальный объект AR.components
			AR.pushComponent(cOldBrowser_base, 'cOldBrowser_base');
		});
});
