const config = require('./config.yaml');
const CMenu = require('../../index.js');

/**
 * Реализует логику варианта vertical-expandable компонента menu
 */
class CMenu_verticalExpandable extends CMenu {
	constructor() {
		super();
		this.config = config;
		// Тип меню для определения класса
		this.menuType = 'left';
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart = 'VerticalExpandable';
	}

	/**
	 * Инициализация
	 */
	init() {
		// Меню
		this.$menu = $('.js-left-nav--vertical-expandable');

		// Если меню на странице существует
		if (this.$menu.length) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-left-link');
			// Все ссылки с подменю
			this.$linksWithSub = this.$menu.find('.has-submenu > .js-left-link');
			// Класс списков (выпадашек)
			this.listClass = '.js-left-list';

			// Установить события, на которые раскрывать / закрывать списки меню
			this.setOpenHideEvents();
			// По установленному событию на ссылки с подменю инициализировать раскрытие
			this.$linksWithSub.on(this.openEvents, $.proxy(this, 'onOpen'));

			// Если меню необходимо делать фиксируемым
			if (this.config.fixedMenu) {
				// Инициализировать наблюдателя состояния фиксирующегося меню
				this.initFixMenuWatcher();
			}
		}
	}

	/**
	 * Получение эффекта и продолжительности анимации раскрытия
	 * @return {Object}          Эффект и время анимации
	 */
	getOpenAnimation() {
		return {
			openEffect: this.config.animation.open.transition,
			openDuration: this.config.animation.open.duration
		};
	}

	/**
	 * Получение эффекта и продолжительности анимации закрытия
	 * @return {Object}               Эффект и время анимации
	 */
	getCloseAnimation() {
		return {
			closeEffect: this.config.animation.close.transition,
			closeDuration: this.config.animation.close.duration
		};
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * Создаем свой обработчик вместо унаследованного,
	 * иначае при нахождении на странице нескольких вариантов, которые также унаследовали метод
	 * и для одного из них сделать $.off(), то для всех остальных также отключаюется, т.е наследуют
	 * @param  {Object} event Событие
	 */
	onDocumentListener(event) {
		// Обращаемся к родительскому методу
		super.onDocumentListener(event);
	}
}

AR.waitComponents([], () => {
	const cMenu_verticalExpandable = new CMenu_verticalExpandable();
	// Вызов метода, инициализирующего все существующие события
	cMenu_verticalExpandable.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMenu_verticalExpandable, 'cMenu_verticalExpandable');
});
