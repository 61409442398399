const CTabs = require('../../index.js');

/**
 * Реализует логику варианта bm компонента tabs
 */
class CTabs_bm extends CTabs {
	constructor() {
		super();
	}

	init(type) {
		this.$tabs = $(`.js-tabs--${type}`);
		if (this.$tabs.length) {

			this.$tabButtons = this.$tabs.find('.js-tabs-button');
			this.type = type;

			this.$tabButtons.on('click', $.proxy(this, 'onClickOpenTabs'));

			this.checkHash();
		}
	}

	checkHash() {
		const hash = window.location.hash;
		const $targetElem = $(`[href="${hash}"].js-tabs-button`);

		if ($targetElem.length) {
			const $parents = $targetElem.parents('.js-tabs-article');

			if ($parents.length) {
				this.openParents($parents);
			}

			if ($targetElem.hasClass('js-tabs-button')) {
				const $expandedButton = $targetElem
					.closest('.js-tabs')
					.children('.js-tabs-nav')
					.find('.js-tabs-button.is-expand');
				this.closeTabs($expandedButton);
				this.openTabs($targetElem);
				this.scrollToElement($targetElem);
			}
		} else {
			this.openFirstTab();
		}
	}
}

AR.waitComponents([], () => {
	const cTabs_bm = new CTabs_bm();
	// Вызов метода, инициализирующего все существующие события
	cTabs_bm.init('bm');
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTabs_bm, 'cTabs_bm');
});
