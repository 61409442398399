class Sizes {
	constructor() {}

	/**
	 * Добавляет атрибут viewBox
	 * @param  {Object} svg SVG
	 * @return {Object}     SVG
	 */
	constructViewBox(svg) {
		//Ширина
		const width = svg.getAttribute('width');
		//Высота
		const height = svg.getAttribute('height');
		//Выставить атрибу viewBox
		svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
	}

	/**
	 * Получает размеры viewBox'a SVG
	 * @param  {Object} $svg SVG
	 * @return {Object}      Размеры viewBox
	 */
	getSvgBoxSize($svg) {
		//Если у SVG отсутствует атрибут viewBox
		if (!$svg[0].getAttribute('viewBox')) {
			//Добавление атрибута viewBox
			this.constructViewBox($svg[0]);
		}
		//Получение значений атрибута viewBox
		const values = $svg[0].getAttribute('viewBox').split(' ');

		//Вернуть размеры viewBox(ширину, высоту)
		return {
			width: Number(values[2]),
			height: Number(values[3])
		};
	}

	/**
	 * Получает размер элемента, подогнанный под размер viewBox SVG
	 * @param  {Object} $svg          SVG
	 * @param  {Object} containerSize размеры контейнера
	 * @return {Object}               подогнанные размеры
	 */
	getFittedSvgSize($svg, containerSize) {
		//Размеры viewBox'a SVG
		const svgBoxSize = this.getSvgBoxSize($svg);
		return this.getFittedSize(svgBoxSize, containerSize);
	}

	/**
	 * Получает размер элемента, подогнанный под размер контейнера
	 * @param  {Object} elementSize   размеры SVG
	 * @param  {Object} containerSize размеры контейнера
	 * @return {Object}               подогнанные размеры
	 */
	getFittedSize(elementSize, containerSize) {
		//Если передана ширина и высота контейнера
		if (containerSize.width && containerSize.height) {
			//Соотношение ширины к высоте элемента
			const elementRatio = elementSize.width / elementSize.height;
			//Соотношение ширины к высоте контейнера
			const containerRatio = containerSize.width / containerSize.height;

			//Если соотношение элемента больше или равно соотношения контейнера
			if (elementRatio >= containerRatio) {
				//Получает размер элемента, подогнанный под ширину контейнера
				return this.getFittedByWidthSize(elementSize, containerSize.width);
			} else { //Получает размер элемента, подогнанный под высоту контейнера
				return this.getFittedByHeightSize(elementSize, containerSize.height);
			}
		} else if (containerSize.width) { //Если передана только ширина контейнера
			//Получает размер элемента, подогнанный под ширину контейнера
			return this.getFittedByWidthSize(elementSize, containerSize.width);
		} else if (containerSize.height) { //Если передана только высота контейнера
			//Получает размер элемента, подогнанный под высоту контейнера
			return this.getFittedByHeightSize(elementSize, containerSize.height);
		} else {
			console.error('Не определены width и height в параметре containerSize');
			return elementSize;
		}
	}

	/**
	 * Получает размер элемента, подогнанный под ширину контейнера
	 * @param  {Object} elementSize    размеры SVG
	 * @param  {Object} containerWidth размеры контейнера
	 * @return {Object}                подогнанные размеры
	 */
	getFittedByWidthSize(elementSize, containerWidth) {
		//Новая высота элемента
		let newHeight = containerWidth / elementSize.width * elementSize.height;
		//Округление до 1 знака после запятой
		newHeight = Math.round(newHeight * 10) / 10;
		return {
			width: containerWidth,
			height: newHeight
		};
	}

	/**
	 * Получает размер элемента, подогнанный под высоту контейнера
	 * @param  {Object} elementSize     размеры SVG
	 * @param  {Object} containerHeight размеры контейнера
	 * @return {Object}                 подогнанные размеры
	 */
	getFittedByHeightSize(elementSize, containerHeight) {
		//Новая ширина элемента
		let newWidth = containerHeight / elementSize.height * elementSize.width;
		//Округление до 1 знака после запятой
		newWidth = Math.round(newWidth * 10) / 10;
		return {
			width: newWidth,
			height: containerHeight
		};
	}

	/**
	 * Растягивает SVG по ширине внешнего контейнера (включая IE-костыли)
	 * @param  {Object} $container контейнер SVG
	 * @param  {Object} $svg       SVG
	 */
	fitSvgSizeToWidth($container, $svg) {
		//Размеры viewBox'a SVG
		const svgBoxSize = this.getSvgBoxSize($svg);
		//Соотношение ширины viewBox'а к высоте viewBox'а
		const svgRatio = svgBoxSize.width / svgBoxSize.height;
		//Соотношение ширины окна к высоте окна
		const ratioWindow = $(window).width() / $(window).height();
		//Соотношение высоты окна к ширине окна(для IE-костылей)
		var ratio = svgBoxSize.height / svgBoxSize.width * 100;

		//Если нужно растягивать по ширине или высоте, в зависимости от соотношения сторон
		if ($container.data('fit') === 'both') {
			const containerRatio = $container.parent().width() / $container.parent().height();
			//Если соотношение размеров SVG меньше или равно соотношению размеров контейнера
			if (svgRatio <= containerRatio) {
				//Обнулить "паддинги"
				ratio = 0;
				//Задать высоту
				$container.css('height', '100%');
			}
		} else if ($container.data('fit') === 'height') { //Если нужно растягивать по ширине
			//Обнулить "паддинги"
			ratio = 0;
			//Задать высоту
			$container.css('height', '100%');
		}

		//Выставление размеров контейнеру
		$container
			.css('position', 'relative')
			.attr('height', '0')
			.attr('width', '100%')
			.css('padding', '0')
			.css('padding-bottom', ratio + '%')
			.attr('data-ratio', svgRatio);

		//Выставление размеров SVG
		$svg
			.css('position', 'absolute')
			.attr('width', '100%')
			.attr('height', '100%')
			.css('left', '0')
			.css('top', '0');
	}
}

module.exports = Sizes;
