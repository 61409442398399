const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');
const CAnimations = require('../../index.js');
const config = require('./config.yaml');
const {throttle} = require('throttle-debounce');

class CAnimations_blocks extends CAnimations {
	constructor() {
		super();
		this.config = config;
	}

	init() {
		this.initAnimations();
	}

	initAnimations() {
		const targetsSelector = '[data-animation="true"]:not(.animate):not(.animated)';
		const $targets = $(targetsSelector);

		if ($targets.length) {
			this.makeItAnimate(targetsSelector);
			this.watchForAnimate();
			$(window).on('scroll', $.proxy(this, 'onThrottle'));
		}

		AR.events.on('svgRenderedAll', () => {
			const $contentSvgs = $('.content-area [data-svg]');

			if ($contentSvgs.length) {
				$contentSvgs.each((i, elem) => {
					const $targetsInSvg = $(elem).find(targetsSelector);

					this.makeItAnimate($targetsInSvg);

					if ($targetsInSvg.length) {
						this.processElement($targetsInSvg);
					}
				});

				if (!$targets.length) {
					$(window).on('scroll', $.proxy(this, 'onThrottle'));
				}
			}
		});
	}

	onThrottle() {
		throttle(100, this.onScroll());
	}

	onScroll() {
		this.watchForAnimate();
	}

	makeItAnimate(elem) {
		const $animateElements = typeof elem == 'object' ? $(elem) : $(`${elem}:not(.animate):not(.animated)`);

		$animateElements.addClass('animate');
	}

	watchForAnimate() {
		const $animateElements = $('.animate')
			.filter((i, elem) => {
				const $elem = $(elem);

				return !$elem.closest('.js-animation-sequence-group')
				|| $elem.hasClass('js-animation-sequence-group')
				|| $elem.closest('.js-animation-sequence-group:not(.animate)');
			});

		if ($animateElements.length) {
			this.processElement($animateElements);
		} else {
			$(window).off('scroll', $.proxy(this, 'onScroll'));
		}
	}

	processElement($elems) {
		$elems.each((i, elem) => {
			const $elem = $(elem);
			const data = $elem.data();
			const force = data['animationForce'] ? true : false;
			const viewPart = this.getPartValue($elem, data['animationPart']);

			if (force) {
				this.animate($elem, data);
			} else if (isElementInView(elem, true, viewPart) && !$elem.hasClass('in-process')) {
				this.animate($elem, data);
			}
		});
	}

	getPartValue($elem, data) {
		if (data) {
			if (data.match('%')) {
				return -($elem.height() / 100 * parseFloat(data));
			} else if (data.match('px')) {
				return -parseFloat(data);
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	checkForSequenceParent($elem) {
		const $seqParent = $elem.closest('.js-animation-sequence-group');

		if ($seqParent.length) {
			if ($seqParent.hasClass('js-animation-sequence-group--processed')) {
				return false;
			} else {
				const $seqTargets = $seqParent
					.find('[data-animation="true"].animate:not(.animated)')
					.filter((i, elem) => elem !== $elem[0]);

				if ($seqParent.is('[data-animation="true"].animate:not(.animated)')) {
					$seqTargets.push($seqParent[0]);
				}

				$seqParent.addClass('js-animation-sequence-group--processed');

				$seqTargets.each((i, elem) => {
					const $elem = $(elem);
					const data = $elem.data();

					$elem.addClass('in-process');

					this.animate($elem, data, true);
				});

				return true;
			}
		} else {
			return true;
		}
	}

	animate($elem, options, fromParent = false) {
		const delay = options['animationDelay'] || 0;
		const duration = options['animationDuration'] || this.config.animationsDuration || false;
		const loop = options['animationLoop'] ? 'infinite' : '';
		const animateIt = fromParent || this.checkForSequenceParent($elem);

		if (animateIt) {
			AR.events.emit('onAnimationStart', $elem);

			setTimeout(() => {
				$elem
					.removeClass('animate')
					.addClass(`animated ${loop}`)
					.css({
						'-webkit-animation-duration': `${duration}ms`,
						'animation-duration': `${duration}ms`
					});
			}, delay);
		}
	}
}

AR.waitComponents([], () => {
	const cAnimations_blocks = new CAnimations_blocks();

	cAnimations_blocks.init();

	AR.pushComponent(cAnimations_blocks, 'cAnimations_blocks');
});
