module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'logo/base' && templateVars.config ?
				templateVars.config :
				{
	"expandedWidth": false,
	"animationSpeed": 200,
	"useAs": "image",
	"logo": {
		"ru": "/images/components/logo/images/logo-ru.svg",
		"en": "/images/components/logo/images/logo-en.svg"
	},
	"logoMobile": {
		"ru": "/images/components/logo/images/logo-mobile.svg",
		"en": "/images/components/logo/images/logo-mobile.svg"
	},
	"logoMain": {
		"ru": "/images/components/logo/images/logo-main-ru.svg",
		"en": "/images/components/logo/images/logo-main-en.svg"
	}
};