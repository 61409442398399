const config = require('./config.yaml');

document.addEventListener('DOMContentLoaded', () => {


	const app = $('.body--business-model');

	if (app.length) {

		$('body').on('mouseenter', '.point', function () {
			let $layer = $('.b-map-picture [id="Layer_1"]');
			let code = '';
			$($(this).attr('class').split(' ')).each(function () {
				if (this.indexOf('point--') !== -1) {
					code = this.substr(7);
				}
			});

			$layer.find('.flag').css({opacity: 0});
			$layer.find('.flag.flag--' + code).css({opacity: 1});

		});


		$('body').on('mouseleave', '.point', function () {
			let $layer = $('.b-map-picture [id="Layer_1"]');
			$layer.find('.flag').css({opacity: 0});
		});


		$('body').on('mouseenter', '.picture', function () {
			let $layer = $('.b-scheme__picture [id="Layer_1"]');
			let code = '';
			$($(this).attr('class').split(' ')).each(function () {
				if (this.indexOf('picture--') !== -1) {
					code = this.substr(9);
				}
			});
			$layer.find('.point, .flag, .part').addClass('is-hidden');
			$layer.find('.part--' + code).removeClass('is-hidden');

			$('.b-scheme__icon-box').hide();
		});


		$('body').on('mouseleave', '.picture', function () {
			let $layer = $('.b-scheme__picture [id="Layer_1"]');
			$layer.find('.point, .flag, .part').removeClass('is-hidden');
		});

		//
		let appState = false;

		AR.events.on('svgRenderedAll', (svg) => {
			appState = true;
			checkTab();
			$(window).on('scroll mousewheel', null, $('.js-strat-bm'), animateSvg);
		});

		AR.events.on('onTabsBmOpen', checkTab);

		function checkTab(x) {
			let $currentTab = x ? x : $('.js-tabs-article.is-expand');

			if (appState) {

				switch ($currentTab.attr('data-tab')) {
					case config.firstTab:
						let $map = $currentTab.find('.js-strat-map');
						if ($map.length && !$map.hasClass('block-show')) {
							$map.addClass('block-show');
							$map.velocity('transition.slideDownBigIn', {delay: 500});
						}
						break;

					case config.secTab:
						let $first = $currentTab.find('.js-strat-wrapper');

						if ($first.length && !$first.hasClass('block-show')) {
							$first
								.addClass('block-show')
								.velocity('transition.slideDownBigIn', {delay: 500});
						}
						break;

					default:
						break;
				}
			}
		}

		function animateSvg($event) {
			if (!$event.data.length) {
				return false;
			}

			let $blocks = $event.data;
			let trueTab = $('.js-tabs-article.is-expand').attr('data-tab') === config.secTab ? true : false;

			let windowH = document.documentElement.clientHeight;
			let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
			let layoutH = $('#layout-wrapper').height();

			$.each($blocks, function (i, block) {
				let $block = $(block);
				let offsetTop = $block.offset().top;

				if (!$block.hasClass('block-show') && trueTab) {
					if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
						$block.addClass('block-show');
						$block.velocity('transition.slideDownBigIn');
					}
				}
			});

			let check = [...$blocks].every(el => {
				return el.classList.contains('block-show');
			});

			if (check) {
				$(window).off('scroll mousewheel', animateSvg);
			}
		}

	}

});
