import {throttle} from 'throttle-debounce';

/**
 * Класс реализует базовую логику для компонента slider
 */
export default class CSlider {
	constructor() {}

	/**
	 * Инициализировать слайдер
	 * @param {object} sliderWrapper обертка слайдера
	 * @param {object} customConfig кастомный конфиг слайдера
	 */
	async initSlider(sliderWrapper, customConfig = {}) {
		this.sliderWrapper = sliderWrapper;
		this.slider = this.sliderWrapper.querySelector('.js-slider');

		if (this.slider) {
			this.CSwiper = await this.getSwiper();
			this.poly = await this.getPoly();
			this.Tools = await this.getTools();

			this.Tools.disableTab(this.slider.querySelectorAll('*'));
			this.swiper = new this.CSwiper($(this.slider), this.getConfig(customConfig));
		}
	}

	async getTools() {
		const module = await import(/* webpackChunkName: "swiperTools" */ './Tools.js');
		return AR.getEsm(module);
	}

	async getSwiper() {
		const lib = await import(/* webpackChunkName: "swiper" */ 'swiper/js/swiper.js');
		return AR.getEsm(lib);
	}

	async getPoly() {
		const poly = await import(/* webpackChunkName: "poly" */ 'core-js/modules/es6.math.sign');
		return AR.getEsm(poly);
	}

	/**
	 * Получить конечный конфиг слайдера
	 * @param {object} customConfig кастомный конфиг слайдера
	 */
	getConfig(customConfig) {
		const defaultConfig = AR.rExtend(this.config.options, {
			pagination: this.getPagination(),
			on: {
				slideChangeTransitionStart: () => {
					this.onSlideChangeTransitionStart();
				},
				slideChangeTransitionEnd: () => {
					this.onSlideChangeTransitionEnd();
				},
				autoplay: () => {
					this.onAutoplay();
				},
				autoplayStart: () => {
					this.onAutoplayStart();
				},
				autoplayStop: () => {
					this.onAutoplayStop();
				},
				init: () => {
					this.onInit();
				},
				fromEdge: () => {
					this.onFromEdge();
				},
				reachBeginning: () => {
					this.onReachBeginning();
				},
				reachEnd: () => {
					this.onReachEnd();
				}
			}
		});

		const newConfig = AR.rExtend(defaultConfig, customConfig);

		return newConfig;
	}

	/**
	 * callback на инициализацию свайпеа
	 */
	onInit() {
		AR.events.emit('onInit');
		const html = document.querySelector('html');

		if (html && (html.classList.contains('feature-no-flexbox') || html.classList.contains('browser-safari-10') || html.classList.contains('browser-safari-9'))) {
			this.fixContainerHeight();
		}

		//	Отключить прелоадер
		AR.components.cPreloader_base.disablePreloaderInItem($(this.sliderWrapper));
	}

	/**
	 * callback на начало смены слайда
	 */
	onSlideChangeTransitionStart() {
		AR.events.emit('onSlideChangeTransitionStart');
	}

	/**
	 * callback на завершение смены слайда
	 */
	onSlideChangeTransitionEnd() {
		AR.events.emit('onSlideChangeTransitionEnd');
	}

	/**
	 * callback при смене слайда с первого / последнего слайда
	 */
	onFromEdge() {
		AR.events.emit('fromEdge');
	}

	/**
	 * callback смене на первый слайд
	 */
	onReachBeginning() {
		AR.events.emit('reachBeginning');
	}

	/**
	 * callback при смене на последний слайд
	 */
	onReachEnd() {
		AR.events.emit('reachEnd');
	}

	/**
	 * callback при смене слайда автоплеем (автоматическая смена слайдов)
	 */
	onAutoplay() {
		AR.events.emit('onAutoplay');
	}

	/**
	 * callback при начале автоплея
	 */
	onAutoplayStart() {
		AR.events.emit('onAutoplayStart');
	}

	/**
	 * callback при остановке автоплея
	 */
	onAutoplayStop() {
		AR.events.emit('onAutoplayStop');
	}

	/**
	 * Получчить пагинацию (кастомная разметка)
	 */
	getPagination() {
		const paginationObj = AR.rExtend({}, this.config.options['pagination']);

		if (paginationObj) {
			if (paginationObj.render) {
				paginationObj['renderBullet'] = (index, className) => {
					return this.getBulletMarkup(index, className);
				};
			}

			return paginationObj;
		} else {
			return false;
		}
	}

	/**
	 * Получить разметку буллита пагинации
	 * @param {number} index индекс
	 * @param {string} className класс
	 */
	getBulletMarkup(index, className) {
		return `<span class="${className} b-slider-pagination__item">${index + 1}</span>`;
	}

	/**
	 * Задать высоту слайдера (for old browsers)
	 */
	fixContainerHeight() {
		const header = document.querySelector('.l-header');
		const footer = document.querySelector('.l-footer');
		const container = document.querySelectorAll('.l-page, .content-area');
		const minHeight = this.config.containerMinHeight;
		const resizeHandler = function () {
			const contentAreaHeight = window.innerHeight - header.offsetHeight - footer.offsetHeight;

			if ((typeof minHeight == 'number' && contentAreaHeight > minHeight) || minHeight == 'auto') {
				AR.getElemsArr(container).forEach(elem => {
					elem.style.height = `${contentAreaHeight}px`;
				});
			}
		};

		resizeHandler();

		window.addEventListener('resize', throttle(150, () => {
			resizeHandler();
		}));
	}
}
