/**
 * Класс реализует базовую логику для компонента tables
 */
class CTables {
	constructor() {
	}

	init() {

	}
}

module.exports = CTables;

AR.waitComponents([], () => {
	const cTables = new CTables();
	// Вызов метода со всеми событиями
	cTables.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTables, 'cTables');
});
