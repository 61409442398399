//базовый класс компонента
const config = require('./config.yaml');
const CSocialServices = require('../../index.js');

class CSocialServices_staticInLine extends CSocialServices {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация логики класса
	 */
	init() {
		this.$socialStaticInLine = $('.js-social--static-in-line');
		// Кнопка варианта
		this.$triggerButton = this.$socialStaticInLine.find('.has-sublist');
		// Провешивание события клика по кнопке
		this.initToggleEvents();
	}

	/**
	 * Инициализация события смены состояния
	 */
	initToggleEvents() {
		// События открытия и закрытия списка по ховеру|тачстарту
		this.$triggerButton
			.on('mouseenter touchstart', $.proxy(this, 'onSublistOpen'))
			.on('mouseleave', $.proxy(this, 'onSublistClose'));
	}

	/**
	 * Метод закрытия списка
	 * @param  {object} $object - список, который закрывается
	 * @return {object}
	 */
	close($object) {
		// список внутри экземпляра компонента
		const $socialList = $object.children('.js-social-sublist');

		// удаление класса с кнопки
		$object.removeClass('is-expand');
		// Вызов события скрытия списка внутри компонента
		AR.events.emit('onSocialServicesStaticInLineCloseStart', $object);
		$socialList.velocity('stop', true);
		// скрывание списка
		return $.Velocity.animate($socialList, this.config.animationClose, this.config.animationCloseDuration);
	}

	/**
	 * Инициализация события раскрытия
	 * @param $object - список, который открывается
	 */
	open($object) {
		// поиск объекта js-social-list
		const $socialList = $object.children('.js-social-sublist');
		// добавляется к кнопке класс is-expand
		$object.addClass('is-expand');
		// Вызов события раскрытия списка внутри компонента
		AR.events.emit('onSocialServicesStaticInLineOpenStart', $object);
		$socialList.velocity('stop', true);
		// velocity promise для анимации раскрытия
		return $.Velocity.animate($socialList, this.config.animationOpen, this.config.animationOpenDuration);
	}

	/**
	 * Обработка событие наведения на кнопку с подсписком
	 * @param event
	 */
	onSublistOpen(event) {
		// Текущий элемент - кнопка
		const $object = $(event.currentTarget);
		// Проверка не открыт ли элемент
		if (!$object.hasClass('is-expand')) {
			// Вызов события скрытия списка внутри компонента
			AR.events.emit('onSocialServicesStaticInLineOpenStart', $object);
			// Вызов смены состояни кнопки
			this
				// вызов события open, открывающего элемент
				.open($object)
				// Вызов функции после раскрытия списка
				.then(() => {
					// вызов события завершения раскрытия списка после анимации
					AR.events.emit('onSocialServicesStaticInLineOpenEnd', $object);
					// Обработка события нажатия на тач устройстве
					if (event.type == 'touchstart') {
						$(document).on('touchstart', $.proxy(this, 'onDocumentListener'));
					}
				});
		}
	}

	/**
	 * Метод скрытия подсписка
	 * @param event
	 */
	onSublistClose(event) {
		// Текущий элемент - кнопка
		const $object = $(event.currentTarget);
		// Вызов события скрытия списка внутри компонента
		AR.events.emit('onSocialServicesStaticInLineCloseStart', $object);

		// Вызов смены состояни кнопки
		this
			// вызов метода, закрывающего список
			.close($object)
			// Вызов функции после скрытия списка
			.then(() => {
				// вызов события завершения закрытия списка после анимации
				AR.events.emit('onSocialServicesStaticInLineCloseEnd', $object);
			});
	}

	/**
	 * Обработчик события клика по document
	 * @param  {object} event - событие
	 * @param  {object} classObject
	 */
	onDocumentListener(event, classObject) {
		// если у нажатого элемента нет родителя с классом, вызов метода закрытия списка
		if (!$(event.target).closest('.js-social--static-in-line').length) {
			this.close(this.$triggerButton);
			$(document).off('touchstart', this.onDocumentListener);
		}
	}
}

AR.waitComponents([], () => {
	// создание экземпляра класса
	const cSocialServices_staticInLine = new CSocialServices_staticInLine();
	cSocialServices_staticInLine.init();

	// добавление в глобальный объект AR.components
	AR.pushComponent(cSocialServices_staticInLine, 'cSocialServices_staticInLine');
});
