/**
 * Класс реализует базовую логику для компонента download-center
 */
class CDownloadCenter {
	constructor(config, text) {
		this.config = null;
		this.text = null;
	}

	init() {
		// Получаем config.yaml
		this.config = null;
		// Получаем text.yaml
		this.text = null;
	}

	getActiveLinks() {
		let $checkedTriggers = this.$triggers.filter((i, item) => {
				let $item = $(item);
				//берем чекнутые чекбоксы, но исключаем те, которые в заголовке секций
				return (!$item.attr('data-group-trigger') && $item.prop('checked'));
			}),
			links = [];

		$.each($checkedTriggers, function () {
			let link = $(this)
				.closest('.js-row')
				.find('.js-link')
				.attr('data-link');

			if (link) {
				links.push(link);
			}
		});
		return links;
	}

	getAllLinks() {
		let $checkedTriggers = this.$triggers.filter((i, item) => {
				let $item = $(item);
				//берем чекнутые чекбоксы, но исключаем те, которые в заголовке секций
				return (!$item.attr('data-group-trigger'));
			}),
			links = [];

		$.each($checkedTriggers, function () {
			let link = $(this)
				.closest('.js-row')
				.find('.js-link')
				.attr('data-link');

			if (link) {
				links.push(link);
			}
		});
		return links;
	}

	checkDownloadButtonAccess() {
		const $links = this.getActiveLinks();

		if ($links.length && this.$downloadButton.length) {
			this.$downloadButton.prop('disabled', false);
			this.$downloadButton.removeClass('is-disabled');
		} else {
			this.$downloadButton.prop('disabled', true);
			this.$downloadButton.addClass('is-disabled');
		}
	}

	initDownloadButton() {
		if (this.$downloadButton.length) {
			this.$downloadButton.click(() => {
				let query = this.getActiveLinks()
					.map(file => `files[]=${encodeURIComponent(file)}`)
					.join('&');

				query += `&filename=files&site=${templateVars.site}&lang=${templateVars.lang}`;
				window.open(`/download?${query}`, '_blank');
				return false;
			});
		}
	}

	initDownloadAllButton() {
		if (this.$downloadAllButton.length) {
			this.$downloadAllButton.click(() => {
				let query = this.getAllLinks()
					.map(file => `files[]=${encodeURIComponent(file)}`)
					.join('&');

				query += `&filename=files&site=${templateVars.site}&lang=${templateVars.lang}`;
				window.open(`/download?${query}`, '_blank');
				return false;
			});
		}
	}
}

module.exports = CDownloadCenter;

AR.waitComponents(['cAccordions_base'], () => {
	const cDownloadCenter = new CDownloadCenter();
	// Вызов метода со всеми событиями
	cDownloadCenter.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cDownloadCenter, 'cDownloadCenter');
});
