const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
const tourConfig = require('./tourConfig.js')(text);

/**
 * Инициализация модуля интерактивного анализа
 * @param {object} callback
 */
function initAnalysis(callback) {
	require.ensure(['zebra-analysis'], function (require) {
		const ZebraAnalysis = require('zebra-analysis');
		const zebraAnalysis = new ZebraAnalysis(config, 'analysis', templateVars.lang);
		zebraAnalysis.init()
			.then(function () {
				global.zebraAnalysis = zebraAnalysis;
				callback();
			});
	});
}

AR.waitComponents(['cAccordions_base', 'cTour_base', 'cPreloader_base'], () => {
	const $analysis = $('#analysis');

	if ($analysis.length) {
		initAnalysis(() => {
			const $accordions = $analysis.find('.js-accordion');
			const $accButtons = $analysis.find('.js-accordion-button');
			const tour = AR.components.cTour_base.createTour(tourConfig);
			const $chartSection = $analysis.find('.analysis__chart-section');
			const $itemsGroupItems = $analysis.find('.items-group__item');
			const $presets = $analysis.find('.analysis__preset');
			const $analysisWrapper = $('.js-analysis-wrapper');

			$accButtons.append('<span class="b-accordion__up"><i class="webfont-icon webfont-icon--up_3"></i></span>');

			AR.components.cAccordions_base.initAccordions($accordions);
			AR.components.cPreloader_base.disablePreloaderInItem($analysisWrapper);

			if (Object.keys(AR.components.cNotification_base.notifications).length) {
				AR.events.on('onNotificationRemove', () => {
					if (!Object.keys(AR.components.cNotification_base.notifications).length) {
						AR.components.cTour_base.startFirstTime(tour);
					}
				});
			} else {
				AR.components.cTour_base.startFirstTime(tour);
			}

			$itemsGroupItems.on('click', function () {
				$('html, body').velocity('scroll', {
					offset: $chartSection.offset().top,
					duration: config.scrollToChartDuration
				});
			});

			$presets.on('click', function () {
				$('html, body').velocity('scroll', {
					offset: $chartSection.offset().top,
					duration: config.scrollToChartDuration
				});
			});

			$('.analysis__preset:first-child').click(); // имитируем нажатие кнопкой мишы на блок
		});
	}
});

