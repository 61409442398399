//конфиг шаблона компонента
const config = require('./config.yaml');
//базовый класс компонента
const CLogo = require('../../index.js');

/**
 * Реализует логику варианта base компонента logo
 */
class CLogo_base extends CLogo {
	constructor() {
		super();
		//логотипы, которые нужно расширять
		this.$expandableLogo = $('.js-logo--expandable');
		//конфиг шаблона компонента
		this.config = config;
	}

	/**
	 * Инициализация компонента
	 */
	init() {
		//если в параметрах задан параметр расширения лого при наведении
		if (this.$expandableLogo.length && this.isSetConfigExpandedWidth()) {
			//скорость анимаций
			this.animationSpeedParam = this.config.animationSpeed || 200;
			//параметр, насколько нужно расширять
			this.expandedWidthParam = this.config.expandedWidth;

			//обработчики событий для расширения
			this.initExpandableEvents();
		}
	}

	/**
	 * Инициализация событий наведения/снятия наведения на логотип
	 */
	initExpandableEvents() {
		//если логотип/логотипы есть на странице
		if (this.$expandableLogo.length) {
			//обрабатываем каждый логотип
			$.each(this.$expandableLogo, (key, logo) => {
				const $logo = $(logo);
				//ширина логотипа по-умолчанию
				const defaultLogoWidth = $logo.width();

				//события наведения и снятия наведения на логотип
				$logo.mouseenter((event) => {
					//триггер глобального события начала расширения
					AR.events.emit('onLogoExpandStart', $logo);

					//анимация расширения
					$logo.velocity({
						width: this.expandedWidthParam,
						complete: () => {
							//триггер глобального события окончания расширения
							AR.events.emit('onLogoExpandEnd', $logo);
						}
					}, this.animationSpeedParam);
				}).mouseleave((event) => {
					//триггер глобального события начала сжатия логотипа
					AR.events.emit('onLogoCompressStart', $logo);

					//анимация сжатия
					$logo
						.velocity('stop', true)
						.velocity({
							width: defaultLogoWidth,
							complete: () => {
								//триггер глобального события окончания сжатия логотипа
								AR.events.emit('onLogoCompressEnd', $logo);
							}
						}, this.animationSpeedParam);
				});
			});
		}
	}

	/**
	 * Указано ли в параметрах, что логотип должен быть расширяемым
	 * @returns {boolean}
	 */
	isSetConfigExpandedWidth() {
		return this.config.expandedWidth;
	}
}

AR.waitComponents([], () => {
	const cLogo_base = new CLogo_base();
	// Вызов метода, инициализирующего все существующие события
	cLogo_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cLogo_base, 'cLogo_base');
});
