module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'figure-buttons/base' && templateVars.config ?
				templateVars.config :
				{
	"createTooltips": true,
	"tooltipster": {
		"theme": [
			"default",
			"tooltipster-tooltips"
		],
		"side": "left"
	}
};