const {debounce} = require('throttle-debounce');
const config = require('./config.yaml');
const $breadcrumbs = $('.breadcrumbs--short');

if ($breadcrumbs.length) {
	$breadcrumbs.each(function () {
		const $this = $(this);

		$(document).ready(function () {
			if (isNeedDoingCheck($this)) {
				checkBreadcrumbs($this);

				$(window).on('resize', function () {
					debounce(100, checkBreadcrumbs($this));
				});
			}
		});
	});
}

/**
 * Проверяет нужно ли подключать функуционал коротких хлебных
 * крошек
 * @param $bc
 * @returns {boolean}
 */
function isNeedDoingCheck($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	return (Boolean($bc.find('.js-item').length));
}
/**
 * Основной метод проверки, который оперирует хлебными крошками
 * @param $bc - хлебные крошки
 */
function checkBreadcrumbs($bc) {
	try {
		if (isFit($bc)) {
			if (hasHiddenItems($bc)) {
				let $item = false;
				if (config.typeOfShortening == 'mostLongest') {
					$item = getMostShortHiddenItem($bc);
				} else {
					$item = getMostRightHiddenItem($bc);
				}

				if ($item && tryShowItem($bc, $item)) {
					if (config.tooltipTitle) {
						let $dots = $item.find('.js-dots');
						if ($dots) {
							removeTooltipFromItem($dots);
						}
					}

					checkBreadcrumbs($bc);
				}
			}
		} else {
			let $item = false;
			if (config.typeOfShortening == 'mostLongest') {
				$item = getMostLongShownItem($bc);
			} else {
				$item = getMostLeftShownItem($bc);
			}

			if ($item && tryHideItem($bc, $item)) {
				if (config.tooltipTitle) {
					let $dots = $item.find('.js-dots');
					if ($dots) {
						addTooltipToItem($dots);
					}
				}

				checkBreadcrumbs($bc);
			}
		}
	} catch (e) {
		console.error(`[breadcrumbs] ${e.name}: ${e.message}`);
	}
}

/**
 * Возвращает общую ширину элементов хлебных крошек
 * @param $bc
 * @returns {number}
 */
function getBreadcrumbsWidth($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}
	let width = 0;

	$.each($bc.find('.js-item'), function () {
		width += $(this).outerWidth(true);
	});

	return width;
}
/**
 * Метод проверяет, влезают ли хлебные крошки в родителя
 * @param $bc  - хлебные крошки
 * @returns {boolean}
 */
function isFit($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	let parentWidth = $bc.parent().width();
	let breadcrumbsWidth = getBreadcrumbsWidth($bc);
	let itemsCount = $bc.find('.js-item').length;
	let result = true;

	if (breadcrumbsWidth > parentWidth) {
		result = false;
	}

	return result;
}

/**
 * Получить самый левый из видимых элементов,
 * которые можно переключать
 * @param $bc  - хлебные крошки
 */
function getMostLeftShownItem($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	let $items = $bc.find('.js-item-can-switch:not(.is-short)');

	return ($items.length) ? $items.first() : false;
}

/**
 * Получить самый правый из скрытых элементов,
 * которые можно переключить
 * @param $bc  - хлебные крошки
 */
function getMostRightHiddenItem($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	let $items = $bc.find('.js-item-can-switch.is-short');

	return ($items.length) ? $items.last() : false;
}

/**
 * Получить самый длинный из видимых,
 * которые можно переключить
 * @param $bc  - хлебные крошки
 */
function getMostLongShownItem($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	let $items = $bc.find('.js-item-can-switch:not(.is-short)');

	if ($items.length) {
		let $resultItem = $($items.first());

		$items.each(function () {
			let currentInnerWidth = $(this).find('.js-inner').width();
			let resultInnerWidth = $resultItem.find('.js-inner').width();

			if (currentInnerWidth > resultInnerWidth) {
				$resultItem = $(this);
			}
		});

		return $resultItem;
	} else {
		return $items;
	}
}

/**
 * Получить самый коротки из скрытых,
 * которые можно переключить
 * @param $bc  - хлебные крошки
 */
function getMostShortHiddenItem($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	let $items = $bc.find('.js-item-can-switch.is-short');

	if ($items.length) {
		let $resultItem = $($items.first());

		$items.each(function () {
			let currentInnerWidth = $(this).find('.js-inner').width();
			let resultInnerWidth = $resultItem.find('.js-inner').width();

			if (currentInnerWidth < resultInnerWidth) {
				$resultItem = $(this);
			}
		});

		return $resultItem;
	} else {
		return $items;
	}
}

/**
 * Имеются ли скрытые элементы в хлебных крошках
 * @param $bc - хлебные крошки
 * @returns {boolean}
 */
function hasHiddenItems($bc) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	}

	return (Boolean($bc.find('.js-item.is-short').length));
}

/**
 * Пробует скрыть переданный элемент. При этом, если хлебные
 * крошки вмещаются в родителя, то элемент скрыт не будет.
 * @param $bc - хлебные крошки
 * @param $item
 * @returns {boolean}
 */
function tryHideItem($bc, $item) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	} else if (!$item.length) {
		throw new Error('Не передан элемент для скрытия');
	} else if ($item.hasClass('is-short')) {
		throw new Error('Элементы уже скрыт');
	}

	let $itemInner = $item.find('.js-inner');
	if (!$itemInner.length) {
		throw new Error('Не найдено внутреннее содержимое элемента');
	}

	let parentWidth = $bc.parent().width();
	let breadcrumbsWidth = getBreadcrumbsWidth($bc);
	if (breadcrumbsWidth > parentWidth) {
		$item.addClass('is-short');
		return true;
	} else {
		return false;
	}
}

/**
 * Пробует показать переданный элемент. При этом, если
 * "ширина текущих хлебных крошек + ширина элемента"
 * не вмещается в родителя, то элемент не будет показан.
 * @param $bc - хлебные крошки
 * @param $item
 * @returns {boolean}
 */
function tryShowItem($bc, $item) {
	if (!$bc.length) {
		throw new Error('Не передан объект хлебных крошек');
	} else if (!$item.length) {
		throw new Error('Не передан элемент для открытия');
	} else if (!$item.hasClass('is-short')) {
		throw new Error('Элементы уже открыт');
	}

	let $itemInner = $item.find('.js-inner');
	if (!$itemInner.length) {
		throw new Error('Не найдено внутреннее содержимое элемента');
	}

	let parentWidth = $bc.parent().width();
	let breadcrumbsWidth = getBreadcrumbsWidth($bc);
	let itemInnerWidth = $itemInner.width();
	if ((breadcrumbsWidth + itemInnerWidth) < parentWidth) {
		$item.removeClass('is-short');
		return true;
	} else {
		return false;
	}
}

/**
 * Удалает подсказку у элемента
 * @param $item
 */
function removeTooltipFromItem($item) {
	if ($item.hasClass('tooltip')) {
		let $itemContext = $item.find('.tooltip__text');

		$item.removeClass('tooltip');
		if ($itemContext.length) {
			$item.attr('title', $itemContext.text());
			$item.empty();
		}
	}
}

/**
 * Добавляет подсказку к элементу
 * @param $item
 */
function addTooltipToItem($item) {
	let text = $item.attr('title');

	if (!$item.hasClass('tooltip')) {
		$item
			.addClass('tooltip')
			.append(`<span class="tooltip__text tooltip__text--bottom">${text}</span>`);
	}
}

// if ($('.breadcrumbs--short').length > 0) {
// 	var parentWidth = $('.breadcrumbs--short').parent().width(),
// 		breadcrumbsWidth = $('.breadcrumbs--short').width(),
// 		$itemsArray = $('.breadcrumbs__item'),
// 		hideFromIndex = config.dontRollUpFirst ? 2 : 1;
//
// 	$(document).ready(function () {
// 		if (breadcrumbsWidth > parentWidth && $itemsArray.length > 2) {
// 			config.typeOfShortening == 'mostLongest' ? shortenMostLongest() : shortenLeftToRight();
// 		}
// 	});
//
// 	function shortenLeftToRight() {
// 		for (var i = hideFromIndex; i < $itemsArray.length - 1; i++) {
// 			if (breadcrumbsWidth > parentWidth) {
// 				var $targetItem = $itemsArray.eq(i).find('span');
//
// 				$targetItem.html('...');
// 				breadcrumbsWidth = $('.breadcrumbs--short').width();
//
// 				if (config.tooltipTitle) {
// 					addTooltip($targetItem, $targetItem.parent().attr('title'));
// 				}
// 			} else {
// 				return false;
// 			}
// 		}
// 	}
//
// 	function shortenMostLongest() {
// 		while (breadcrumbsWidth > parentWidth) {
// 			var indexOfLongest = hideFromIndex;
//
// 			for (var i = 2; i < $itemsArray.length - 1; i++) {
// 				if ($itemsArray.eq(i).width() > $itemsArray.eq(indexOfLongest).width()) {
// 					indexOfLongest = i;
// 				}
// 			}
//
// 			var $targetItem = $itemsArray.eq(indexOfLongest).find('.breadcrumbs__link').children('span');
//
// 			if ($targetItem.text() != '...' && !$targetItem.hasClass('tooltip')) {
// 				$targetItem.text('...');
// 				if (config.tooltipTitle) {
// 					addTooltip($targetItem, $targetItem.parent().attr('title'));
// 				}
// 			} else {
// 				return false;
// 			}
//
// 			breadcrumbsWidth = $('.breadcrumbs--short').width();
// 		}
// 	}
//
// 	function addTooltip(elem, text) {
// 		elem.addClass('tooltip').append(`<span class="tooltip__text tooltip__text--bottom">${text}</span>`);
// 		elem.parent().attr('title', '');
// 	}
// }
