module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'charts/highcharts' && templateVars.config ?
				templateVars.config :
				{
	"chartAttribute": "data-chart",
	"langSettings": {
		"ru": {
			"lang": {
				"decimalPoint": ",",
				"thousandsSep": " "
			}
		},
		"en": {
			"lang": {
				"decimalPoint": ".",
				"thousandsSep": ","
			}
		}
	},
	"chartSettings": {
		"chart": {
			"style": {
				"fontFamily": "OpenSans, sans-serif"
			}
		}
	},
	"theme": {
		"colors": [
			"#7CB5EC",
			"#434348",
			"#90ED7D",
			"#F7A35C",
			"#8085E9",
			"#F15C80",
			"#E4D354",
			"#2B908F",
			"#F45B5B",
			"#91E8E1"
		]
	}
};