/**
 * Класс реализует базовую логику для компонента buttons-panel
 */
class CButtonsPanel {
	constructor() {

	}

	toggleList($elem, panelObj, options, currentList, isSublist) {
		if ($elem.hasClass('is-expand')) {
			this.hideList($elem, panelObj, options, currentList, isSublist);
		} else {
			setTimeout(() => {
				this.showList($elem, panelObj, options, isSublist);
			}, 0);
		}
	}

	showList($elem, panelObj, options, isSublist) {
		this.enableDocumentListener(panelObj, isSublist);

		$elem
			.addClass('is-expand')
			.siblings('.js-buttons-panel-list, .js-buttons-panel-sublist')
			.addClass('is-expand')
			.velocity(options.show.effect, {
				duration: options.show.duration,
				complete: () => {
					if (panelObj.tour && panelObj.$panel.closest('.js-buttons-panel--tools')) {
						AR.components.cTour_base.startFirstTime(panelObj.tour);
					}
				}
			});
	}

	sortTourSteps(steps, sorting) {
		return steps.sort((stepA, stepB) => (sorting.indexOf(stepA.id) > sorting.indexOf(stepB.id)) ? 1 : -1);
	}

	hideList($elem, panelObj, options, $list, isSublist) {
		this.disableDocumentListener(panelObj, isSublist);

		$elem
			.removeClass('is-expand')
			.siblings('.js-buttons-panel-list, .js-buttons-panel-sublist')
			.removeClass('is-expand');

		$list
			.velocity(options.hide.effect, {
				duration: options.hide.duration,
				begin: () => {
					if (panelObj.tour && panelObj.$panel.closest('.js-buttons-panel--tools')) {
						AR.components.cTour_base.skipTours(panelObj.tour.config);
					}
				}
			});
	}

	hideSublists($elem, panelObj) {
		const $lists = $elem
			.closest('.js-buttons-panel-item')
			.siblings('.js-buttons-panel-item')
			.find('.js-buttons-panel-sublist.is-expand');

		if ($lists.length) {
			this.hideList($lists.siblings('.js-buttons-panel-sublist-trigger'), panelObj, panelObj.sublistOptions, $lists, true);
		}
	}

	onDocumentListenerSublist(panelObj, event) {
		if (!$(event.target).closest(`${panelObj.classesObj['sublistTrigger']}, ${panelObj.classesObj['sublist']}`).length && panelObj.$sublist.hasClass('is-expand')) {
			this.hideList(panelObj.$sublistTrigger, panelObj, panelObj.sublistOptions, panelObj.$sublist, true);
		}
	}

	onDocumentListener(panelObj, event) {
		const $target = $(event.target);

		if (!$target.closest(panelObj.$panel).length &&
			!$target.closest(`${panelObj.tourSelector}`).length &&
			panelObj.$buttonsList.hasClass('is-expand')) {

			const $tourList = $(`.tour-tools--${this.config['modifier']}`);
			if ($tourList.length) {
				$tourList.remove();
			}

			if (this.config.hoverablePanel && !this.config.hoverableSublist) {
				const $expandedSublists = panelObj.$sublist.filter((i, elem) => !$(elem).hasClass('is-expand'));

				this.hideSublists(panelObj.$sublist, panelObj);
			}

			this.hideList(panelObj.$buttonsTrigger, panelObj, panelObj.listOptions, panelObj.$buttonsList);
		}
	}

	enableDocumentListener(panelObj, isSublist) {
		if (!isSublist) {
			$(document).on(panelObj.events.list.hide, $.proxy(this, 'onDocumentListener', panelObj));
		} else {
			$(document).on(panelObj.events.sublist.hide, $.proxy(this, 'onDocumentListenerSublist', panelObj));
		}
	}

	disableDocumentListener(panelObj, isSublist) {
		if (!isSublist) {
			$(document).off(panelObj.events.list.hide, $.proxy(this, 'onDocumentListener'));
		} else {
			$(document).off(panelObj.events.sublist.hide, $.proxy(this, 'onDocumentListenerSublist'));
		}
	}

	tooltipTouch($this, event) {
		if (!$this.hasClass('is-touch') && !$this.children('.buttons-panel__link').hasClass('is-expand')) {
			event.preventDefault();

			$this
				.addClass('is-touch')
				.siblings()
				.removeClass('is-touch is-active');
		} else if ($this.hasClass('is-touch') && $this.children('.buttons-panel__link').hasClass('is-expand')) {
			$this.removeClass('is-touch');
		}
	}

	tooltipHover(panelObj) {
		panelObj.$panel.find('.js-buttons-panel-link').hover(function () {
			$(this).not('.is-expand').closest('.js-buttons-panel-item').addClass('is-active');

		}, function () {
			$(this).closest('.js-buttons-panel-item').removeClass('is-active');
		});

		panelObj.$sublist.on('click', function (event) {
			$(this).closest('.js-buttons-panel-item').removeClass('is-active');
		});
	}

	showAndHideByTab(panelObj) {
		const TAB_KEY = 9;

		$('body').on('keyup', event => {
			const isFocused = $(document.activeElement).closest(panelObj.$panel).length;

			if (event.which == TAB_KEY) {
				if (isFocused && !panelObj.$buttonsTrigger.hasClass('is-expand')) {
					this.showList(panelObj.$buttonsTrigger, panelObj.sublistOptions);
				} else if (!isFocused && panelObj.$buttonsTrigger.hasClass('is-expand')) {
					this.hideList(panelObj.$buttonsTrigger, panelObj.sublistOptions, panelObj.$buttonsList);
				}
			}
		});
	}

	openShareLink(event) {
		const link = $(event.currentTarget).data('shareLink');

		window.open(link, '', 'scrollbars=yes,resizable=no,width=560,height=350,top=' + Math.floor((screen.height - 350) / 2 - 14) + ',left=' + Math.floor((screen.width - 560) / 2 - 5));

		return false;
	}

	checkForItems(panelObj) {
		const itemsLength = panelObj.$buttonsList.find('.js-buttons-panel-item').length;

		if (!itemsLength) {
			if (this.config.ifEmptyPanel === 'remove') {
				panelObj.$buttonsTrigger.remove();
			} else {
				panelObj.$buttonsTrigger.attr('disabled', 'disabled');
			}
		}
	}

	fixCompare(panelObj) {
		panelObj.$compareList.insertAfter(panelObj.$compare);
	}

	initMyReportButtons(panelObj) {
		const $addBtn = panelObj.$panel.find('.js-buttons-panel-link--add');
		const $delBtn = panelObj.$panel.find('.js-buttons-panel-link--del');
		const $myReportBtn = panelObj.$panel.find('.js-buttons-panel-link--myreport');
		const $counter = $myReportBtn.find('.js-buttons-panel-counter');

		if ($myReportBtn.length) {
			this.setMyReportLength($counter);
		}

		if ($myReportBtn.length && $addBtn.length && $delBtn.length) {
			const pageKey = templateVars.siteUrl.path;

			AR.components.cMyReport_base.checkPageOnExist(pageKey)
				.then((value) => {
					if (value) {
						$addBtn.removeClass('is-visible');
						$delBtn.addClass('is-visible');
					}
				});

			$addBtn.on('click', () => {
				AR.components.cMyReport_base
					.addPage()
					.then(() => this.setMyReportLength($counter));

				$addBtn.removeClass('is-visible');
				$delBtn.addClass('is-visible');
			});

			$delBtn.on('click', () => {
				AR.components.cMyReport_base
					.removeItem(pageKey)
					.then(() => this.setMyReportLength($counter));

				$addBtn.addClass('is-visible');
				$delBtn.removeClass('is-visible');
			});
		}
	}

	setMyReportLength($counter) {
		AR.components.cMyReport_base.getLength()
			.then((value) => {
				$counter.html('(' + value + ')');
			});
	}
}

module.exports = CButtonsPanel;
