/**
 * Класс реализует базовую логику для компонента tabs
 */
class CTabs {
	constructor() {}

	/**
	 * Инициализация
	 * @param {string} type тип табов
	 */
	init(type) {
		this.$tabs = $(`.js-tabs--${type}`);
		if (this.$tabs.length) {
			this.$tabButtons = this.$tabs.find('.js-tabs-button');
			this.type = type;
			this.openFirstTab();
			this.$tabButtons.on('click', $.proxy(this, 'onClickOpenTabs'));

			const $svg = $('[data-svg]');
			if ($svg.length) {
				AR.events.on('svgRenderedAll', () => {
					this.checkHash();
				});
			} else {
				this.checkHash();
			}
		}
	}

	/**
	 * Проверка хэша и скролл к табу
	 */
	checkHash() {
		const hash = window.location.hash;
		const $targetElem = $(`[href="${hash}"]`);

		if ($targetElem.length) {
			const $parents = $targetElem.parents('.js-tabs-article');

			if ($parents.length) {
				this.openParents($parents);
			}

			if ($targetElem.hasClass('js-tabs-button')) {
				const $expandedButton = $targetElem
					.closest('.js-tabs')
					.children('.js-tabs-nav')
					.find('.js-tabs-button.is-expand');
				this.closeTabs($expandedButton);
				this.openTabs($targetElem);
				this.scrollToElement($targetElem);
			}
		}
	}

	/**
	 * Скролл к элементу (кнопке)
	 * @param {object} $targetButton кнопка таба
	 */
	scrollToElement($targetButton) {
		const topPos = $targetButton.offset().top;
		$('html')
			.velocity('scroll', {
				offset: topPos + 'px',
				duration: 500,
				mobileHA: false
			});
	}

	/**
	 * Callback на клик по кнопке таба
	 * @param {object} event событие клика
	 */
	onClickOpenTabs(event) {
		const $button = $(event.currentTarget);
		const $expandedButton = $button.closest('.js-tabs-nav').find('.js-tabs-button.is-expand');
		this.closeTabs($expandedButton);
		this.openTabs($button);
	}

	/**
	 * Открыть таб
	 * @param {object} $button кнопка таба
	 */
	openTabs($button) {
		const hash = $button.attr('href');
		const $targetArticle = this.$tabs.find(`.js-tabs-article[data-tab="${hash}"]`);

		$button.addClass('is-expand');
		$targetArticle.addClass('is-expand');
		AR.events.emit(`onTabs${this.type.replace(this.type[0], this.type[0].toUpperCase())}Open`, $targetArticle);
		AR.tools.checkViewport();
	}

	/**
	 * Закрыть таб
	 * @param {object} $button кнопка таба
	 */
	closeTabs($button) {
		const hash = $button.attr('href');
		const $targetArticle = this.$tabs.find(`.js-tabs-article[data-tab="${hash}"]`);

		$button.removeClass('is-expand');
		$targetArticle.removeClass('is-expand');
		AR.events.emit(`onTabs${this.type.replace(this.type[0], this.type[0].toUpperCase())}Close`, $targetArticle);
	}

	/**
	 * Открыть родительские табы
	 * @param {object} $parentArticles контентные блоки родительских табов
	 */
	openParents($parentArticles) {
		$parentArticles.each((i, elem) => {
			const $elem = $(elem);
			const hash = $elem.data('tab');
			const $button = this.$tabs.find(`.js-tabs-button[href="${hash}"]`);
			const $expandedButton = $button.closest('.js-tabs-nav').find('.js-tabs-button.is-expand');
			this.closeTabs($expandedButton);
			this.openTabs($button);
		});
	}

	/**
	 * Открыть первый таб
	 */
	openFirstTab() {
		this.$tabs.each((index, el) => {
			const $this = $(el);
			const $buttons = $this.children('.js-tabs-nav').find('.js-tabs-button');
			const showTabNum = $this.data('open');
			this.$targetButton = showTabNum ? $buttons.eq(showTabNum - 1) : $buttons.eq(0);
			this.openTabs(this.$targetButton);
		});
	}
}

module.exports = CTabs;
