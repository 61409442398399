const $buttons = $('button');

if ($buttons.length) {
	document.addEventListener('keydown', tabListener);
}

function tabListener(event) {
	const TAB_KEY = 9;

	if (event.keyCode == TAB_KEY) {
		document.addEventListener('click', clickListener);
		$buttons.addClass('focus-on');
		window.removeEventListener('keydown', tabListener);
	}
}

function clickListener(event) {
	$buttons.removeClass('focus-on');
	document.addEventListener('keydown', tabListener);
}
