import checkViewport from 'component/core/.variant/base/additionals/viewport.js';

const modules = {};

/**
 * Класс реализует логику варианта base для компонента graphic
 */
class CGraphic_base {
	constructor() {
		this.$graphics = null;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$graphics = $('.graphic');

		this.initGraphics(this.$graphics);
		this.initAnimations();
	}

	/**
	 * Инициализация графиков (добавить кнопки)
	 * @param {object} $graphics графики
	 */
	initGraphics($graphics) {
		$graphics.each((index, el) => {
			const $this = $(el);

			if (!$this.hasClass('icon')) {
				if (!$this.find('.figure-buttons.js-buttons').length) {
					$this.append('<div class="figure-buttons js-buttons"></div>');
				}

				if (!$this.hasClass('graphic--no-zoom')) {
					AR.components.cZoomIn_base.addZoomIn($this);
				}
			}
		});
	}

	initAnimations() {
		//Запускать анимацию графиков после их загрузки на странице
		AR.events.on('svgRendered', ($svg) => {
			let isNeedToDoAnimation = true;

			if ($svg.hasClass('js-acc-svg')) {
				let $svgInvisibleParents = $svg.parents(':not(:visible)');

				if ($svgInvisibleParents.length) {
					isNeedToDoAnimation = false;
				}
			}


			if ($svg.hasClass('js-animate-svg')) {
				if (isNeedToDoAnimation) {
					$svg.addClass('check-viewport');
				}

				// Разделить графики по типу анимации с помощью дата-атрибута
				$svg.on('show', async () => {
					let targetModule = null;

					if ($svg.data('animation-type') === 'horizontal-rects') {
						targetModule = modules.horizontalRectsGraphic || await this.getModule('horizontalRectsGraphic');
					} else if ($svg.data('animation-type') === 'vertical-rects') {
						targetModule = modules.verticalRectsGraphic || await this.getModule('verticalRectsGraphic');
					} else if ($svg.data('animation-type') === 'left-right-rects') {
						targetModule = modules.leftRightRectGraphic || await this.getModule('leftRightRectGraphic');
					} else if ($svg.data('animation-type') === 'pressure-arrows') {
						targetModule = modules.pressureLinesGraphic || await this.getModule('pressureLinesGraphic');
					}

					if (targetModule) {
						targetModule.animate($svg);
					}

					$svg.removeClass('check-viewport');
				});
			}

			checkViewport();
		});

		AR.events.on('svgRenderedAll', () => {
			if (document.querySelector('.js-acc-svg')) {
				AR.events.on('onAccordeonOpenEnd', (e, $openedSection) => {
					let $svgInActiveSection = $openedSection.find('.js-acc-svg');

					if ($svgInActiveSection.hasClass('js-acc-svg')) {
						$svgInActiveSection.addClass('check-viewport');
					}
				});
			}
		});
	}

	async getModule(name) {
		return await import(/* webpackChunkName: "[request]" */ `./modules/${name}.js`);
	}
}

AR.waitComponents(['cZoomIn_base'], () => {
	const cGraphic_base = new CGraphic_base();
	// Вызов метода со всеми событиями
	cGraphic_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cGraphic_base, 'cGraphic_base');
});
