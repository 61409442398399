const cookie = require('cookie_js');

/**
 * Класс реализует базовую логику для компонента cookie-law
 */
class CCookieLaw {
	// В параметрах передаются config.yaml и text.yaml
	constructor(config, text) {
		// Получаем config.yaml
		this.config = config;
		// Получаем text.yaml
		this.text = text;
	}

	/**Метод, необходимый для получения корректной ссылки (проставляет нужную языковую версию)
	 * @param  {[string]} urlQuery [Передается ссылка из config.yaml]
	 */
	getUrl(urlQuery) {
		return urlQuery
			// Удаляем хэш в начале ссылки
			.replace(/\/$/g, '')
			// Получаем нужную языковую версию
			.replace('${rootUrl}', templateVars.siteLangRootUrlRel);
	}

	/**
	 * [Метод формирования шаблона]
	 * @param  {[function]} notyTemplate [передается параметр, для генерация шаблона, т.к. шаблоны в вариантах имеют различия]
	 */
	createNoty(notyTemplate) {
		// Задаем параметры для блока с уведомлением
		const cookieLawNotify = {
			// Id, который будет присвоен главному блоку
			id: 'cookie-law',
			// Тип блока
			type: 'information',
			// Основной текст, выводимый в уведомлении
			message: this.text.message.replace('{{siteTitle}}', templateVars.structure.title),
			// Текст, выводимый в заголовке уведомления (задается в файле text.yaml)
			title: this.text.title,
			// Текст, выводимый на кнопке согласия с принятием куки (задается в файле text.yaml)
			buttonApplyText: this.text.buttonApplyText,
			// Текст, выводимый на кнопке перехода на страницу с подробным описанием (задается в файле text.yaml)
			buttonAlsoText: this.text.buttonAlsoText,
			// Классы, присваеваемые главному блоку
			themeModifier: 'cookie-law',
			// Шаблон уведомления
			onTemplate: notyTemplate || null,
			// Функция, вызываемая в начале показа уведомления
			onShow: () => {
				// Если в config.yaml, параметр - "isStatic" имеет свойство - true
				if (this.config.isStatic) {
					// Получаем класс главного блока уведомления
					const $notyLayoutDefault = $('#noty_layout__default');
					// Если в config.yaml, параметр - "isStatic" имеет свойство - true и блок уведомления не имеет индекса - "0" в DOM
					if (this.config.isStatic && $notyLayoutDefault.index()) {
						$notyLayoutDefault
							// Добавить блоку с уведомление класс - "noty_layout--static"
							.addClass('noty_layout--static')
							// Задать ему индекс - "0"
							.prependTo('body');
					}
				}
				// Если в config.yaml, параметр - "showOneTime" имеет свойство - true
				if (this.config.showOneTime) {
					// Вызов метода создания куки
					this.setShownCookie(true);
				}
			},
		};
		// Получаем доступ к параметрам компонента - notification, в качестве аргумента передаются заданные параметры для cookielaw
		return AR.components.cNotification_base.createNotification(cookieLawNotify);
	}

	// Обработка клика по конпе Я принимаю
	onClickApplyButton() {
		AR.events.emit('onCookieLawAccept');

		this.setShownCookie(true);
		return true;
	}

	// Метод, выполняющий создание куки
	setShownCookie(action) {
		(action)
			? cookie.set('shown_cookies_law', 'true', {path: '/', expires: 365})
			: cookie.remove('shown_cookies_law');
	}
}

module.exports = CCookieLaw;
