/**
 * Класс реализует базовую логику для компонента form
 */
class CForm {
	constructor() {}

	/**
	 * Провесить обработчики на события компонента popup, подтверждение / фейла отправки формы
	 * @param {object} $targetForm форма
	 * @param {object} $targetPopup попап формы
	 * @param {object} config конфиг формы
	 */
	initInteractiveWithPopup($targetForm, $targetPopup, config) {
		const targetPopupID = $targetPopup.attr('data-popup-id');

		AR.events.on('onPopupStartCloseAnimation', ($popup) => {
			if ($popup.attr('data-popup-id') == targetPopupID) {
				if (config.cleanOnClose) {
					$targetForm.trigger('reset');
				}
			}
		});

		AR.events.on('onPopupStartOpenAnimation', ($popup) => {
			if ($popup.attr('data-popup-id') == targetPopupID) {
				if (config.cleanOnClose) {
					$targetForm.trigger('reset');
				}

				if (!this.masksInited) {
					this.masksInited = true;
					this.initMaskPlugin($targetForm, config);
				}
			}
		});

		AR.events.on('onFormSuccessSubmit', ($form) => {
			if ($targetForm[0] === $form[0]) {
				AR.components.cPopup_base.closePopup(targetPopupID, 'transition.bounceDownOut');
			}
		});

		AR.events.on('onFormFailureSubmit', ($form) => {
			if ($targetForm[0] === $form[0]) {
				AR.components.cPopup_base.closePopup(targetPopupID, 'transition.bounceUpOut');
			}
		});
	}

	/**
	 * Инициализация плагина-маски для полей со специальными атрибутами
	 * @param $form - форма, в которой содеражтся поля для маски
	 * @param config - конфиг варианта компонента
	 */
	initMaskPlugin($form, config) {
		//взять все поля для маски
		const $maskInputs = $form.find('[data-mask-type]');

		if ($maskInputs.length) {
			this.getInputmask()
				.then(Inputmask => {
					this.defineMasks(Inputmask);
					//обход найденных полей
					$.each($maskInputs, (i, input) => {
						const $input = $(input), //jQuery object
							type = $input.attr('data-mask-type'); //Тип маски для инициализации

						switch (type) {
							//поле под почтовый адрес
							case 'email':
								this.inputMasks.email.mask($input);
								break;
						}
					});
				});
		}
	}

	/**
	 * Получить библиотеку масок
	 */
	async getInputmask() {
		const lib = await import(/* webpackChunkName: "inputmask" */ 'inputmask');
		return AR.getEsm(lib);
	}

	/**
	 * Объявление масок для полей
	 * @param Inputmask - библиотека inputmask
	 */
	defineMasks(Inputmask) {
		this.inputMasks = {
			email: new Inputmask({
				mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]',
				greedy: false,
				showMaskOnHover: false,
				onBeforePaste: function (pastedValue, opts) {
					pastedValue = pastedValue.toLowerCase();
					return pastedValue.replace('mailto:', '');
				},
				definitions: {
					'*': {
						validator: '[0-9A-Za-zА-Яа-я!#$%&\'*+/=?^_\`{|}~\-]',
						cardinality: 1,
						casing: 'lower'
					}
				}
			})
		};
	}

	/**
	 * Инициализация smart-placeholders
	 * @param $form - форма, в которой содеражтся поля для инициализации
	 * @param config - конфиг варианта варианта компонента
	 */
	initSmartPlaceholder($form, config) {
		//взять все поля smart-placeholder
		const $smartPlaceholderItems = $form.find('[data-smart-placeholder]');

		//обход найденных полей
		$.each($smartPlaceholderItems, (i, input) => {
			const $input = $(input);

			//провешиваем событие на потерю фокуса
			$input.on('focusout', $.proxy(this, 'onBlurSmartInput'));
			//провешиваем событие на фокус на элемент
			$input.on('focus', $.proxy(this, 'onFocusSmartInput'));
		});
	}

	/**
	 * Валидация и настройки для формы
	 * @param $form - форма для валидации
	 * @param config - конфиг варианта варианта компонента
	 */
	initValidation($form, config) {
		$form.parsley({
			errorClass: 'is-error',
			validClass: 'is-valid'
		});
	}

	/**
	 * Инициализация для события сабмита формы
	 * @param $form - форма, в которой содеражтся поля для инициализации
	 * @param config - конфиг варианта компонента
	 * @param text - объект с текстами для варианта компонента
	 * @param data - дополнительные данные для передачи в самбит
	 * @param customSubmitHandler - кастомный обработчик сабмита, вызывается вместо ajax-отправки
	 */
	initFormSubmit($form, config, text, data = {}, customSubmitHandler = false) {
		//кнопка для сабмита
		const $submitBtn = $form.find('.js-form-submit-button');

		//провешиваем событие клика по кнопке, т.к. обновление капчи на заваленый сабмит не срабатывает
		$submitBtn.on('click', () => {
			//берем капчу
			const $captcha = $form.find('.js-captcha');
			//включаем валидацию капчи
			AR.components.cCaptcha_base.setValidState($captcha, true);
		});

		//провешиваем обработчик события на сабмит
		$form.on('submit', $.proxy(this, 'onSubmitForm', config, text, data, customSubmitHandler));
	}

	/**
	 * Обработчик события отправки формы
	 * @param config - конфиг варианта компонента
	 * @param text - объект с текстами для варианта компонента
	 * @param data - дополнительные данные для передачи в самбит
	 * @param customSubmitHandler - кастомный обработчик сабмита, вызывается вместо ajax-отправки
	 * @param e
	 */
	onSubmitForm(config, text, data = {}, customSubmitHandler = false, e) {
		//останавливаем событие отправки формы
		e.preventDefault();
		//форма
		const $form = $(e.currentTarget);
		//каптча
		const $captcha = $form.find('.js-captcha');

		//включаем режим проверки валидации каптчи
		if ($captcha.length) {
			AR.components.cCaptcha_base.setValidState($captcha, true);
		}

		//если форма прошла валидацию
		if (this.checkFormValidate($form)) {
			//триггер глобального события успешной валидации формы
			AR.events.emit('onFormValidationSuccess', $form);

			if (customSubmitHandler) {
				customSubmitHandler($form, config, text, data);
			} else {
				//отправляем форму ajax
				this.sendFormByAjax($form, config, text, data);
			}
		} else {
			//триггер глобального события провала валидации формы
			AR.events.emit('onFormValidationFailure', $form);
		}
	}

	/**
	 * Проверка формы на валидность
	 * @param $form - форма для проверки
	 */
	checkFormValidate($form) {
		return $form.parsley().validate();
	}

	/**
	 * Отправкап формы через ajax
	 * @param $form - форма для отправки
	 * @param config - конфиг варианта компонента
	 * @param text - объект с текстами для варианта компонента
	 * @param data - дополнительные данные для передачи в самбит
	 *
	 */
	sendFormByAjax($form, config, text, data = {}) {
		//каптча
		const $captcha = $form.find('.js-captcha');
		//кнопка отправки
		const $submitBtn = $form.find('.js-form-submit-button');
		//тип формы
		const formType = $form.attr('data-form-type');

		//если есть каптча, то включаем ее валидацию
		if ($captcha.length) {
			AR.components.cCaptcha_base.setValidState($captcha, true);
		}

		const resultData = $.extend({}, data);

		//блокируем прелодаером кнопку отправки формы
		AR.components.cPreloader_base.enablePreloaderInItem($submitBtn);

		//отправки формы
		//http://malsup.com/jquery/form/
		$form.ajaxSubmit({
			type: 'POST',
			dataType: 'json',
			data: resultData,
			beforeSubmit: (data, $form, options) => {
				//триггер глобального события перед отправкой формы
				AR.events.emit('onFormBeforeSubmit', $form, data);
			},
			success: () => {
				//триггер глобального события в случае успешной отправки формы
				AR.events.emit('onFormSuccessSubmit', $form);

				//снимаем блокировку кнопки отправки формы
				AR.components.cPreloader_base.disablePreloaderInItem($submitBtn);
				// closeForm();

				//ребутаем каптчу, если она есть
				if ($captcha.length) {
					AR.components.cCaptcha_base.reloadCaptcha($captcha);
				}

				//вызываем Noty с сообщением об успешной отправке
				AR.components.cNotification_base.createNotification({
					id: `${formType}SendFormSuccess`,
					message: text['submittedSuccessfully'],
					timeout: 5000
				});

				//ресетим форму
				$form.trigger('reset');
			},
			error: (response) => {
				//ребутаем каптчу, если она есть
				if ($captcha.length) {
					AR.components.cCaptcha_base.reloadCaptcha($captcha);
				}

				//снимаем блокировку кнопки отправки формы
				AR.components.cPreloader_base.disablePreloaderInItem($submitBtn);

				try {
					//разбираем ошибку
					const error = JSON.parse(response.responseText);
					//получаес id ошибки
					const errorId = error.errorId;

					//если ошибкой стала неверная каптча
					if (errorId && errorId === 'WrongCaptcha') {
						AR.components.cCaptcha_base.setValidState($captcha, false);

						$captcha.find('.js-captcha-text').parsley().validate();
					} else {
						//триггер глобального события в случае провальной отправки формы
						AR.events.emit('onFormFailureSubmit', $form);

						//показываем Noty с сообщением об ошибке
						AR.components.cNotification_base.createNotification({
							id: `${formType}SendFormError`,
							message: text['sendingError'],
							title: text['sendingErrorTitle'],
							timeout: 2000
						});
					}
				} catch (e) {
					//триггер глобального события в случае провальной отправки формы
					AR.events.emit('onFormFailureSubmit', $form);

					//показываем Noty с сообщением об ошибке
					AR.components.cNotification_base.createNotification({
						id: `${formType}SendFormError`,
						message: text['sendingError'],
						title: text['sendingErrorTitle'],
						timeout: 2000
					});
				}
			}
		});
	}

	/**
	 * Обработка события фокуса на smart-placeholder input
	 * @param e
	 */
	onFocusSmartInput(e) {
		const $item = $(e.currentTarget);

		this.focusSmartInput($item);
	}

	/**
	 * Обработка события снятия фокуса с smart-placeholder input
	 * @param e
	 */
	onBlurSmartInput(e) {
		const $item = $(e.currentTarget);

		this.blurSmartInput($item);
	}

	/**
	 * Обработка события ресета формы
	 * @param config - конфиг варианта компонента
	 * @param e
	 */
	onResetForm(config, e) {
		const $form = $(e.currentTarget);

		//триггер глобального события сброса формы
		AR.events.emit('onFormReset', $form);
		this.resetForm($form);
	}

	/**
	 * Фокус smart-placeholder input
	 * @param $item - элемента
	 */
	focusSmartInput($item) {
		$item
			.parents('.js-smart-placeholder-wrapper')
			.addClass('is-focused');
	}

	/**
	 * Снятие фокуса со smart-placeholder input
	 * @param $item - элемент
	 */
	blurSmartInput($item) {
		$item.val(String($item.val()).trim());

		$item
			.parents('.js-smart-placeholder-wrapper')
			.removeClass('is-focused');

		this.toggleInputEmptiness($item);
	}

	/**
	 * Обработка состояний снятия-заполнености поля
	 * @param $input - элемент
	 */
	toggleInputEmptiness($input) {
		if ($input.val() !== '') {
			$input
				.parents('.js-smart-placeholder-wrapper')
				.addClass('is-fill');
		} else {
			$input
				.parents('.js-smart-placeholder-wrapper')
				.removeClass('is-fill');
		}
	}

	/**
	 * Обработка дополнительных действий с формой на reset
	 * @param $form - форма
	 */
	resetForm($form) {
		$form.parsley().reset();
		const $smartPlaceholder = $form.find('.js-smart-placeholder-wrapper');

		if ($smartPlaceholder.length) {
			$smartPlaceholder
				.removeClass('is-fill')
				.removeClass('is-focused');
		}
	}
}

module.exports = CForm;
