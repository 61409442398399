module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/multi' && templateVars.config ?
				templateVars.config :
				{
	"name": "multi",
	"containerMinHeight": "auto",
	"options": {
		"direction": "vertical",
		"speed": 1000,
		"loop": false,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": false
		},
		"pagination": {
			"el": ".js-slider-pagination",
			"type": "bullets",
			"bulletElement": "span",
			"clickable": true,
			"render": true
		},
		"navigation": {
			"prevEl": ".js-slider-button--prev",
			"nextEl": ".js-slider-button--next"
		},
		"autoplay": false,
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": true
		},
		"mousewheel": true,
		"nested": false,
		"runCallbacksOnInit": false,
		"hashNavigation": false,
		"preventInteractionOnTransition": true,
		"parallax": false
	},
	"subSliders": [
		{
			"name": "first",
			"lockBetween": false,
			"options": {
				"direction": "horizontal",
				"speed": 1500,
				"loop": false,
				"effect": "slide",
				"fadeEffect": {
					"crossFade": true
				},
				"pagination": {
					"el": ".js-sub-slider-pagination",
					"type": "bullets",
					"bulletElement": "span",
					"clickable": true,
					"render": true
				},
				"navigation": {
					"prevEl": ".js-sub-slider-button--prev",
					"nextEl": ".js-sub-slider-button--next"
				},
				"autoplay": false,
				"initialSlide": 0,
				"simulateTouch": false,
				"keyboard": {
					"enabled": true,
					"onlyInViewport": true
				},
				"mousewheel": false,
				"nested": true,
				"runCallbacksOnInit": false,
				"hashNavigation": false,
				"preventInteractionOnTransition": true,
				"parallax": false
			}
		}
	]
};