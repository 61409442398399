/**
 * Класс реализует базовую логику для компонента animations
 */
class CAnimations {
	constructor() {

	}
}

module.exports = CAnimations;
