const $window = $(window);

// Является ли элемент видимым (если partial==false, то элемент должен быть полностью виден)
function isElementInView(element, partial = true, extraTop = 0) {
	const $element = $(element);
	const elementOffset = $element.offset();

	const pageTop = $window.scrollTop() + extraTop;
	const pageBottom = pageTop + $window.height();
	const pageLeft = $window.scrollLeft();
	const pageRight = pageLeft + $window.width();

	const elementTop = elementOffset.top;
	const elementBottom = $element.outerHeight() ? elementTop + $element.outerHeight() : elementTop + element.getBoundingClientRect().height;
	const elementLeft = elementOffset.left;
	const elementRight = $element.outerWidth() ? elementLeft + $element.outerWidth() : elementTop + element.getBoundingClientRect().width;

	if (partial) {
		return ((pageTop < elementBottom) && (pageBottom > elementTop) && (pageLeft < elementRight) && (pageRight > elementLeft));
	} else {
		return ((pageTop < elementTop) && (pageBottom > elementBottom) && (pageLeft < elementLeft) && (pageRight > elementRight));
	}
}

module.exports = isElementInView;
