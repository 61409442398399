const config = require('./config.yaml');
const CMenu = require('../../index.js');

/**
 * Реализует логику варианта horizontal компонента menu
 */
class CMenu_horizontal extends CMenu {
	constructor() {
		super();
		this.config = config;
		// Тип меню для определения класса
		this.menuType = 'top';
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart = 'Horizontal';
	}

	initScrollbars() {
		this.$mainMenuPanel = $('.js-menu-scroll');
		this.mainMenuInstance = this.$mainMenuPanel.overlayScrollbars({
			className: 'os-theme-rvc-main-menu',
			overflowBehavior: {
				x: 'hidden',
				y: 'scroll'
			},
		}).overlayScrollbars();

		//для кастомного скролла
		window.bodyInstance = $('body').overlayScrollbars({
			className: 'os-theme-rvc-main-menu',
			overflowBehavior: {
				x: 'hidden',
				y: 'scroll'
			},
			callbacks: {
				onScroll: function () {
					$(window).trigger('scroll');
				}
			}
		}).overlayScrollbars();

	}

	/**
	 * Инициализация
	 */
	init() {
		// Меню
		this.$menu = $('.js-top-nav--horizontal');

		// Если меню на странице существует
		if (this.$menu.length) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-top-link');
			// Все ссылки с подменю
			this.$linksWithSub = this.$menu.find('.b-nav__burger, .has-submenu > .js-top-link');

			// Класс списков (выпадашек)
			this.listClass = '.js-top-list';

			// Установить события, на которые раскрывать / закрывать списки меню
			this.setOpenHideEvents();
			// Установить ширину выпадашкам
			this.setSubListWidth();
			// По установленному событию на ссылки с подменю инициализировать раскрытие
			this.$linksWithSub.on(this.openEvents, $.proxy(this, 'onOpen'));
			this.initScrollbars();

			// Если меню необходимо делать фиксируемым
			if (this.config.fixedMenu) {
				// Инициализировать наблюдателя состояния фиксирующегося меню
				this.initFixMenuWatcher();
			}

			$('body').on('buttons-panel-in-column-open', () => {
				$('.js-mobile-menu-burger.is-expand').click();
			});

			$('body').on('initBodyInstance', () => {
				this.initScrollbars();
			});

			let that = this;
			AR.events.on(`onMenu${this.eventNameVariantPart}OpenEnd`, function ($e) {
				let $target = $e.siblings('a');
				if ($target.hasClass('js-top-link-lvl2')) {
					let top = $target.offset().top - 10 - $('html,body').scrollTop();
					that.mainMenuInstance.scroll({y: '+= ' + top + 'px'}, 500);
				}
			});

			$('.b-nav__burger, body').on('click', function () {
				setTimeout(() => {
					let $this = $('.b-nav__burger');
					if ($this.hasClass('is-expand')) {
						// $('body').css({overflow: 'hidden'});

						let scrollTop = $('body.os-host .os-viewport').scrollTop();
						$('body').overlayScrollbars({
							className: 'os-theme-rvc-main-menu open-m',
							overflowBehavior: {
								x: 'hidden',
								y: 'hidden'
							},
							callbacks: {
								onScroll: function () {
									$(window).trigger('scroll');
								}
							}
						}).overlayScrollbars();
						$('html,body').scrollTop(scrollTop);
					} else {
						// $('body').css({overflow: ''});
						that.initScrollbars();
					}
				}, 200);
			});
		}
	}


	/**
	 * Инициализация раскрытия списка
	 * @param  {Object} event Событие клика / тапа / наведения курсора на пункт меню
	 */
	onOpen(event) {
		// Пункт меню, из которого будет раскрыт список
		const $this = $(event.currentTarget);

		// Если список не раскрыт
		if (!$this.hasClass('is-expand')) {
			// Если списки необходимо раскрывать по наведению и пункт меню первого уровня
			if (this.config.hoverable && $this.hasClass(`js-${this.menuType}-link-lvl1`) && event.type !== 'touchstart') {
				// Раскрытые элементы первого уровня
				const expandedElementsLength = this.$menu.find(`.js-${this.menuType}-link-lvl1.is-expand`).length;

				// Очистить timeout
				clearInterval(this.hoverShowMenuTimeout);
				this.hoverShowMenuTimeout = setTimeout(() => {
					// Раскрыть список
					this.open($this);
				}, this.config.hoverableDelay && expandedElementsLength ? this.config.hoverableDelay : 0);
			} else {
				if (!$this.hasClass(`js-${this.menuType}-link-lvl1`)) {
					event.preventDefault();

					// Раскрыть список
					this.open($this);
				}
			}
		} else {
			//event.preventDefault();
			this.close($this);
		}
	}

	/**
	 * Закрытие списка
	 * @param  {Object}  $element      Элемент список
	 * @param  {Boolean} closeForNext  Делается ли закрытие списка перед открытием следующего
	 */
	close($element, closeForNext) {
		// Эффект и продолжительность анимации
		const {closeEffect, closeDuration} = this.getCloseAnimation($element, closeForNext);

		// Закрыть список
		$element
			.removeClass('is-expand')
			.siblings(this.listClass)
			.velocity(closeEffect, {
				duration: closeDuration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseStart`, $(element)),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseEnd`, $(element))
			});

		// Если слушатель клика по документу провешен и у всех пунктов списки не раскрыты
		// и закрытие делается не перед открытием следующего
		if (this.documentClickListen && !this.$links.hasClass('is-expand') && !closeForNext) {
			// Снять слушателя клика по документу
			$(document).off(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = false;
		}
	}

	/**
	 * Раскрытие списка
	 * @param  {Object} $element Элемент список
	 */
	open($element) {
		// Раскрытые списки на текущем уровне
		const $expandedSiblings = $(`.js-${this.menuType}-link.is-expand`);
		// Эффект и продолжительность анимации
		const {openEffect, openDuration} = this.getOpenAnimation($element);

		if ($expandedSiblings.length) {
			// Закрыть списки на текущем уровне
			this.close($expandedSiblings, true);
		}

		// Если слушатель клика по документу не провешен
		if (!this.documentClickListen) {
			// Провесить слушателя клика по документу
			$(document).on(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = true;
		}

		// Раскрыть список
		$element
			.addClass('is-expand')
			.siblings(this.listClass)
			.velocity(openEffect, {
				duration: openDuration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenStart`, $(element)),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenEnd`, $(element))
			});
		if ($element.hasClass('js-top-link-lvl1')) {
			$element
				.removeClass('is-expand')
				.addClass('expanded')
			;
		}
	}
	/**
	 * Установка ширины выпадашек
	 */
	setSubListWidth() {
		this.$menu
			.find('.js-top-list-lvl2')
			.css('width', `${100 * this.config.colspanSecondLevel}%`);
	}

	/**
	 * Получение эффекта и продолжительности анимации раскрытия
	 * @param  {Object} $element Элемент пункт
	 * @return {Object}          Эффект и время анимации
	 */
	getOpenAnimation($element) {
		// Если пункт первого уровня
		if ($element.hasClass('js-top-link-lvl1')) {
			return {
				openEffect: this.config.animation.main.open.transition,
				openDuration: this.config.animation.main.open.duration
			};
		} else if ($element.siblings('.is-slide-left').length) { // Иначе если нужно выводить влево
			return {
				openEffect: this.config.animation.inner.open.toRight.transition,
				openDuration: this.config.animation.inner.open.toRight.duration
			};
		} else {
			return {
				openEffect: this.config.animation.inner.open.toLeft.transition,
				openDuration: this.config.animation.inner.open.toLeft.duration
			};
		}
	}

	/**
	 * Получение эффекта и продолжительности анимации закрытия
	 * @param  {Object}  $element     Элемент пункт
	 * @param  {Boolean} closeForNext Делается ли закрытие списка перед открытием следующего
	 * @return {Object}               Эффект и время анимации
	 */
	getCloseAnimation($element, closeForNext) {
		// Если нужно делать закрытие перед открытием следующего и элемент второго уровня
		if (closeForNext && $element.hasClass('js-top-link-lvl2')) {
			return {
				closeEffect: this.config.animation.inner.close.transition,
				closeDuration: this.config.animation.inner.close.duration
			};
		} else {
			return {
				closeEffect: this.config.animation.main.close.transition,
				closeDuration: this.config.animation.main.close.duration
			};
		}
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * Создаем свой обработчик вместо унаследованного,
	 * иначае при нахождении на странице нескольких вариантов, которые также унаследовали метод
	 * и для одного из них сделать $.off(), то для всех остальных также отключаюется, т.е наследуют
	 * @param  {Object} event Событие
	 */
	onDocumentListener(event) {
		// Обращаемся к родительскому методу
		super.onDocumentListener(event);
	}
}

AR.waitComponents([], () => {
	const cMenu_horizontal = new CMenu_horizontal();
	// Вызов метода, инициализирующего все существующие события
	cMenu_horizontal.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMenu_horizontal, 'cMenu_horizontal');
});
