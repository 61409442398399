/**
 * Класс реализует базовую логику для компонента popup
 */
class CPopup {
	constructor() {
		// this.KEY_ESCAPE = 27;
	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {

	}
}

module.exports = CPopup;

AR.waitComponents([], () => {
	const cPopup = new CPopup();
	// Вызов метода со всеми событиями
	cPopup.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPopup, 'cPopup');
});
