import CScrollbar from '../../index.js';
import config from './config.yaml';

/**
 * Реализует логику компонента scrollbar/base
 */
class CScrollbar_base extends CScrollbar {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 * проверить коробку на вендоры и классы для скроллбара
	 * если нет, то добавить, иначе ничего не делать
	 */
	init() {
		const $collection = $('.js-customScroll');

		if ($collection.length) {
			// вернет Promise с коллекцией скроллбаров
			this.initScrollbar($collection);
		}
	}
}

AR.waitComponents([], () => {
	const cScrollbar_base = new CScrollbar_base();
	// Вызов метода, инициализирующего все существующие события
	cScrollbar_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cScrollbar_base, 'cScrollbar_base');
});
