const CTableChart = require('../../index.js');
const tablesSelector = '.data-table';
const config = require('./config.yaml');
const baseChart = require('./baseChart.yaml');
const $tables = $(tablesSelector);

/**
 * Реализует логику варианта CTableChart_base компонента table-chart
 */
class CTableChart_base extends CTableChart {
	constructor() {
		super();
	}

	/**
	 * Инициализация
	 */
	init() {
		if ($tables.length) {
			const cCharts = AR.components['cCharts_highcharts'];

			if (cCharts && !cCharts.Highcharts) {
				cCharts.initLib().then(() => this.initTableCharts($tables));
			} else {
				this.initTableCharts($tables);
			}
		}
	}

	/**
	 * Инициализировать графики для таблиц
	 * @param {object} $tables таблицы
	 */
	initTableCharts($tables) {
		$tables.each((iTable, table) => {
			const $table = $(table);
			const dataTitles = $table.data('legend').split(';');
			const titleCol = Number($table.data('title-col'));
			const unitCol = Number($table.data('unit-col'));
			const unit = $table.data('unit');
			const valueCols = $table.data('value-cols').split(';').map(valueCol => Number(valueCol));

			$table.find('tbody tr').each((iRow, row) => {
				const $row = $(row);
				const rowData = this.getChartDataForRow($row, titleCol, valueCols);
				$row.data('chart', JSON.stringify(rowData));

				const id = `chart${iTable}_${iRow}`;
				const tooltipConfig = {
					...config.tooltipOptions,
					content: `<div class="table-chart" id=${id}></div>`,
					functionReady: (instance, helper) => {
						const $origin = $(helper.origin);
						const data = {
							...baseChart,
							series: [JSON.parse($origin.data('chart'))],
							yAxis: {
								title: {
									text: this.getUnits($row, unitCol, unit)
								}
							},
							xAxis: {
								categories: dataTitles
							}
						};
						AR
							.components
							.cCharts_highcharts
							.renderChart(id, data);
					}
				};

				AR
					.components
					.cTooltips_base
					.createTooltips($row, tooltipConfig, false);
			});
		});
	}

	/**
	 * Получить числовое значение из стринги
	 * @param {string} value строка
	 */
	textToFloat(value) {
		const textValue = String(value)
			.replace(/( | |&nbsp;)/gi, '')
			.replace(/,/gi, '.');
		return parseFloat(textValue) || undefined;
	}

	/**
	 * Получить данные для строки таблицы
	 * @param {object} $row строка таблицы
	 * @param {number} titleCol номер столбца, в котором содержится название показателя
	 * @param {object} valueCols номера столбцов, в которых содержатся числовые данные
	 */
	getChartDataForRow($row, titleCol, valueCols) {
		const rowData = {data: []};
		$row.find('td').each((iCell, cell) => {
			const $cell = $(cell);
			if (iCell === titleCol) {
				rowData.name = $cell.text().trim();
			}
			if (valueCols.indexOf(iCell) > -1) {
				const value = this.textToFloat($cell.data('value') || $cell.text());
				rowData.data.push(value);
			}
		});
		return rowData;
	}

	/**
	 * Получить единицы измерения
	 * @param {object} $row строка таблицы
	 * @param {number} unitCol номер столбца, в котором содержится единица измерения показателя
	 * @param {string} unit ед. измерения
	 */
	getUnits($row, unitCol, unit) {
		if (unit) {
			return unit;
		}
		if (typeof unitCol !== undefined) {
			return $row.find(`td:eq(${unitCol})`).text();
		}
		return null;
	}
}

AR.waitComponents(['cTooltips_base', 'cCharts_highcharts'], () => {
	const cTableChart_base = new CTableChart_base();
	// Вызов метода, инициализирующего все существующие события
	cTableChart_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTableChart_base, 'cTableChart_base');
});
