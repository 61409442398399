const config = require('./config.yaml')[templateVars.lang];
const numeral = require('numeral');
const CKeyIndicator = require('../../index.js');

/**
* Реализует логику варианта animated компонента key-indicators
*/
class CKeyIndicator_animated extends CKeyIndicator {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$counters = $('.js-indicator--animated');

		if (this.$counters.length) {
			const localName = `${templateVars.lang}_local-animated`;

			numeral.register('locale', localName, config.local);
			numeral.locale(localName);

			this.repeat();
			this.animateDigitVelocity();
		}
	}

	/**
	 * Метод для инициализации анимации с использованием velocity
	 */
	animateDigitVelocity() {
		const $indicatorsAnimated = $('.js-indicator--animated');

		if ($indicatorsAnimated.length) {
			$indicatorsAnimated
				.each((i, elem) => {
					const $indicator = $(elem);

					const $value = ($indicator.find('.js-indicator-value').length) ? $indicator.find('.js-indicator-value') : $indicator.find('.b-indicator__value'); //значение внутри индикатора
					const customRequiredDecimalCount = $indicator.attr('data-required-decimal-count') || 0; //количество обязательных знаков после запятой, даже если это 0
					const customMaximumDecimalCount = $indicator.attr('data-maximum-decimal-count') || 3; //максимальное возможно количество возможных знаков после запятой

					$value.each((i, elem) => {
						let $valueItem = $(elem);
						const current_value = this.getNumeral($valueItem.text()); //приводим строку значения к числу
						const start_value = $indicator.attr('data-start-value') || 0; //начальное значение может быть задано в дата-атрибуте
						const end_value = $indicator.attr('data-end-value') || current_value; //конченое значение может быть в дата-атрибуте

						this.createCopyForPrint($valueItem);

						$valueItem.addClass('check-viewport velocity'); //классы, по которым будет тригериться анимация
						$valueItem.data('number-start-value', start_value);
						$valueItem.data('number-end-value', end_value);

						$valueItem.data('required-decimal-count', customRequiredDecimalCount);
						$valueItem.data('maximum-decimal-count', customMaximumDecimalCount);
					});
				});

			let $viewports = $('.check-viewport.velocity');

			//событие показа элемента (описано в компоненте core)
			$viewports.on('show', event => {
				const $this = $(event.currentTarget);
				this.startAnimationUp($this); //запуск анимации
				$this.removeClass('check-viewport'); //снимаем класс для срабатывания тригера
			});

			$(window).scroll(); //провоцируем событие проверки
		}
	}

	/**
	 * Возвращает число, преобразованное через numeral
	 * @param num
	 * @returns {number}
	 */
	getNumeral(num) {
		return Number(numeral(num).value());
	}

	/**
	 * Запуск анимации
	 * @param $elem
	 * @returns {boolean}
	 */
	startAnimationUp($elem) {
		if (!$elem || !$elem.length) {
			return false;
		}

		const startValue = $elem.data('number-start-value') || 0;
		const endValue = $elem.data('number-end-value');
		const customRequiredDecimalCount = $elem.data('required-decimal-count');
		const customMaximumDecimalCount = $elem.data('maximum-decimal-count');
		const current_value = $elem.text();
		let mask = this.getCustomMask(customRequiredDecimalCount, customMaximumDecimalCount);

		let decimalPart = String(endValue).split('.')[1]; //знаки после запятой
		let decimalPartLength = 0;

		//количество знаков после запятой
		if (decimalPart) {
			decimalPartLength = decimalPart.length;
		}

		try {
			if (typeof endValue != 'undefined') {
				//стратуем анимацию на velocity
				$elem
					.velocity({
						tween: 100
					}, {
						easing: 'spring',
						duration: config.animationSpeed,
						progress: (elements, complete) => {
							//во время выполнения анимации динамически обновляем значение показателя в DOM
							$elem.text(this.getFormattedNumber(endValue * complete, decimalPartLength, mask));
						},
						complete: function () {
							//в конце анимации короткая вспышка (SWAG)
							let $blinkElem = $elem.hasClass('js-indicator-value') ? $elem.closest('.b-indicator__value') : $elem.closest('.b-indicator__value');
							$blinkElem
								.velocity({opacity: 0}, {
									duration: config.fadeSpeed,
									complete: function () {
										$blinkElem.velocity({opacity: 1}, {
											duration: config.fadeSpeed
										});
									}
								});
						},
					});
			}
		} catch (e) {
			console.error(e);
			$elem.html(current_value);
		}
	}

	/**
	 * Генерация кастомной маски для форматирования числа
	 * @param countOfRequired - количестов обязательных знаков после запятой (включая фиктивные нули)
	 * @param maximumCountOfPossible - общее возможное количество знаков после запятой
	 */
	getCustomMask(countOfRequired = 0, maximumCountOfPossible = 3) {
		let defaultMaskPart = config.mask.split('.')[0]; //получаем часть маски до дробного разделителя
		let numberCountOfRequired = Number(countOfRequired); //приводим к числу количество обязательных знаков после запятой
		let numberMaximumCountOfPossible = Number(maximumCountOfPossible); //приводим к числу максимально возможно количество знаков после запятой

		//Проверяем, удалось ли получить часть дефолтной макси
		if (defaultMaskPart.length) {
			let requiredPart = '';
			let possibleCount = '[000]';

			if (numberCountOfRequired > 0) {
				requiredPart = '0'.repeat(numberCountOfRequired);
			}

			if ((numberMaximumCountOfPossible - numberCountOfRequired) > 0) {
				possibleCount = '[' + String(0).repeat(numberMaximumCountOfPossible - numberCountOfRequired) + ']';
			}

			return `${defaultMaskPart}.${requiredPart}${possibleCount}`;
		} else {
			return config.mask;
		}
	}

	/**
	 * Преобразование числа по маске, взятой из конфига
	 * @param number - числоа
	 * @param decimalPartLength - на сколько округлять
	 * @param mask - маска преобразования {numeral mask format}
	 */
	getFormattedNumber(number, decimalPartLength = 0, mask = config.mask) {
		let fixedNumber = number.toFixed(decimalPartLength); //округление

		//преобразовываем число по маске из конфига
		return numeral(fixedNumber)
			.set(fixedNumber)
			.format(mask);
	}

	/**
	 * Полифил на repeat
	 */
	repeat() {
		if (!String.prototype.repeat) {
			String.prototype.repeat = function (count) {
				'use strict';
				if (this == null) {
					throw new TypeError('can\'t convert ' + this + ' to object');
				}
				let str = String(this);
				count = Number(count);
				if (count != count) {
					count = 0;
				}
				if (count < 0) {
					throw new RangeError('repeat count must be non-negative');
				}
				if (count == Infinity) {
					throw new RangeError('repeat count must be less than infinity');
				}
				count = Math.floor(count);
				if (str.length == 0 || count == 0) {
					return '';
				}
				// Обеспечение того, что count является 31-битным целым числом, позволяет нам значительно
				// соптимизировать главную часть функции. Впрочем, большинство современных (на август
				// 2014 года) браузеров не обрабатывают строки, длиннее 1 << 28 символов, так что:
				if (str.length * count >= 1 << 28) {
					throw new RangeError('repeat count must not overflow maximum string size');
				}
				let rpt = '';
				for (;;) {
					if ((count & 1) == 1) {
						rpt += str;
					}
					count >>>= 1;
					if (count == 0) {
						break;
					}
					str += str;
				}
				return rpt;
			};
		}
	}
}

AR.waitComponents([], () => {
	const cKeyIndicator_animated = new CKeyIndicator_animated;
	// Вызов метода со всеми событиями
	cKeyIndicator_animated.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cKeyIndicator_animated, 'cKeyIndicator_animated');
});
