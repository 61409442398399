import CCharts from '../../index.js';
import config from './config.yaml';

const events = {
	chart: {
		events: {
			load: event => {
				AR.events.emit('onChartLoad', event);
			}
		}
	}
};

const getChartsNames = (data) => {
	const chartNames = Object.keys(data);
	chartNames.forEach(key => {
		const base = data[key].base;
		if (base) {
			data[key] = AR.rExtend($.extend(true, {}, data[base]), $.extend(true, {}, data[key]), events);
		}
	});

	return chartNames;
};

/**
 * Реализует логику компонента charts/highcharts
 */
class CCharts_highcharts extends CCharts {
	constructor() {
		super();
	}

	/**
	 * Инициализация
	 */
	async init() {
		this.$charts = $(`[${config.chartAttribute}]`);

		if (this.$charts.length) {
			await this.initLib();
			await this.initComponent();
		}
	}

	/**
	 * Инициализация библиотек, данных
	 */
	async initLib() {
		this.Highcharts = await this.getHighcharts();
		this.data = await this.getData();

		const exportModule = await this.getExportModule();
		const dataModule = await this.getDataModule();

		dataModule(this.Highcharts);
		exportModule(this.Highcharts);
	}

	/**
	 * Инициализация компонента
	 */
	initComponent() {
		const chartNames = getChartsNames(this.data);
		this.setTheme();
		this.setLangSetting();
		this.setChartSetting();

		chartNames.forEach(key => {
			const $charts = this.$charts.filter((idx, item) => item.dataset.chart == key);
			$charts.each((idx, chart) => {
				this.initChart(chart, this.data[key]);
			});
		});

		AR.events.on('onTabsHorizontalOpen', $article => {
			$article.find('[data-highcharts-chart]').each((idx, item) => {
				$(item).highcharts().setSize();
			});
		});
	}

	/**
	 * Получить данные графиков
	 */
	async getData() {
		const data = await import('./data/charts.js');
		return AR.getEsm(data);
	}

	/**
	 * Получить модуль данных для хайчартс
	 */
	async getDataModule() {
		const module = await import(/* webpackChunkName: "highchartsData" */ 'highcharts/modules/data');
		return AR.getEsm(module);
	}

	/**
	 * Получить модуль экспорта для хайчартс
	 */
	async getExportModule() {
		const module = await import(/* webpackChunkName: "highchartsExport" */ 'highcharts/modules/exporting');
		return AR.getEsm(module);
	}

	/**
	 * Получить библиотеку хайчартс
	 */
	async getHighcharts() {
		const lib = await import('highcharts');
		return lib;
	}

	/**
	 * Задать общие настройки локализации
	 */
	setLangSetting() {
		this.Highcharts.setOptions({
			...config.langSettings[templateVars.lang]
		});
	}

	/**
	 * Задать общие настройки графиков
	 */
	setChartSetting() {
		this.Highcharts.setOptions({
			...config.chartSettings
		});
	}

	/**
	 * Задать общую тему графиков
	 */
	setTheme() {
		this.Highcharts.theme = config.theme;
		this.Highcharts.setOptions(this.Highcharts.theme);
	}

	/**
	 * Рендер графика
	 * @param {string} id идентиФикатор
	 * @param {object} chartData данные графика
	 */
	renderChart(id, chartData) {
		const chart = {
			...this.data[chartData.base],
			...chartData
		};
		this.initChart($(`#${id}`)[0], chart);
	}

	/**
	 * Инициализация графика
	 * @param {object} element идентиФикатор
	 * @param {object} data данные графика
	 */
	initChart(element, data) {
		let result = this.Highcharts.chart(element, data);
		let that = this;
		element.addEventListener('fullscreenchange', function (e) {
			result.destroy();
			result = that.Highcharts.chart(element, data);
		});
		return result;

	}

	/**
	 * Получить данные по ключу (id)
	 * @param {string} key ключ (id)
	 */
	getDataBykey(key) {
		return this.data[key];
	}
}

AR.waitComponents([], () => {
	const cCharts_highcharts = new CCharts_highcharts();
	// Вызов метода, инициализирующего все существующие события
	cCharts_highcharts.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cCharts_highcharts, 'cCharts_highcharts');
});
