//базовый класс компонента
import CSearchResult from '../../index.js';

/**
 * Класс CSearchResult_base, наследник CSearchResult
 * Реализует логику варианта base компонента CSearchResult
 */
class CSearchResult_base extends CSearchResult {
	constructor() {
		super();
	}

	/**
	 * Инициализация компонента
	 */
	init() {
		this.$resultList = $('#search-result-list');
		this.$loadingMarker = $('#search-result-loading-trigger');

		if (this.$resultList.length && this.$loadingMarker.length) {
			this.enableSrollListener();
			this.loadSearchResults();
		}
	}

	/**
	 * Подгрузить результаты поиска
	 */
	loadSearchResults() {
		const pageScroll = $(window).scrollTop();
		const markerBoundaries = this.$loadingMarker.offset();

		if ((pageScroll + $(window).height()) > markerBoundaries.top) {
			if (!this.$loadingMarker.hasClass('is-finished') && !this.$loadingMarker.hasClass('is-loading')) {
				AR.components.cPreloader_base.enablePreloaderInItem(this.$loadingMarker);
				let currentPage = this.$loadingMarker.attr('data-page');
				let nextPage = Number(currentPage) + 1;

				this.$loadingMarker.attr('data-page', nextPage);
				this.$loadingMarker.addClass('is-loading');

				this.getSearchResultsForPage(nextPage)
					.then((data) => {
						if (data) {
							this.$resultList.append(data);
						} else {
							this.$loadingMarker.addClass('is-finished');
						}

						AR.components.cPreloader_base.disablePreloaderInItem(this.$loadingMarker);
						this.$loadingMarker.removeClass('is-loading');
					});
			}
		}
	}

	/**
	 * Провесить слушателя события скрола
	 */
	enableSrollListener() {
		$(window).on('scroll', $.proxy(this, 'loadSearchResults'));
	}

	/**
	 * Получить список результатов поиска для n-ой страницы
	 * @param {number} page номер страницы результата поиска
	 * @param {string} url url текущей страницы
	 */
	getSearchResultsForPage(page = 1, url = document.URL) {
		return new Promise((resolve, reject) => {
			$.ajax({
				method: 'GET',
				url: url,
				dataType: 'html',
				data: {
					page: page
				},
				error: (jqXHR, textStatus, errorThrown) => {
					reject();
				},
				success: (data) => {
					const $resultList = $('<div/>').html(data).find('#search-result-list');
					resolve($resultList.length ? $resultList.html() : false);
				}
			});
		});
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSearchResult_base = new CSearchResult_base();
	// Вызов метода, инициализирующего все существующие события
	cSearchResult_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSearchResult_base, 'cSearchResult_base');
});
