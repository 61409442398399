const config = require('./config.yaml');
const {throttle} = require('throttle-debounce');

/**
 * Класс реализует базовую логику для компонента Menu
 */
class CMenu {
	constructor() {
		this.config = config;
		// Слушается ли событие клика по документу
		this.documentClickListen = false;
		// Timeout раскрытия пунктов по элемента
		this.hoverShowMenuTimeout = null;
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart;
		// Тип меню для определения класса
		this.menuType;
		// Ширина определяющая отключение меню и подключение мобильной версии (при < заданного значения)
		this.menuDisableBreakpoint = this.config.menuDisableBreakpoint;
	}

	/**
	 * Установка событий, на которые раскрывать / закрывать списки меню
	 */
	setOpenHideEvents() {
		if (this.config.hoverable) {
			this.hideEvents = 'mouseover touchstart';
			this.openEvents = 'click touchstart mouseenter';
		} else {
			this.hideEvents = 'click touchstart';
			this.openEvents = 'click touchstart';
		}
	}

	/**
	 * Инициализация раскрытия списка
	 * @param  {Object} event Событие клика / тапа / наведения курсора на пункт меню
	 */
	onOpen(event) {
		// Пункт меню, из которого будет раскрыт список
		const $this = $(event.currentTarget);

		// Если список не раскрыт
		if (!$this.hasClass('is-expand')) {
			// Если списки необходимо раскрывать по наведению и пункт меню первого уровня
			if (this.config.hoverable && $this.hasClass(`js-${this.menuType}-link-lvl1`) && event.type !== 'touchstart') {
				// Раскрытые элементы первого уровня
				const expandedElementsLength = this.$menu.find(`.js-${this.menuType}-link-lvl1.is-expand`).length;

				// Очистить timeout
				clearInterval(this.hoverShowMenuTimeout);
				this.hoverShowMenuTimeout = setTimeout(() => {
					// Раскрыть список
					this.open($this);
				}, this.config.hoverableDelay && expandedElementsLength ? this.config.hoverableDelay : 0);
			} else {
				event.preventDefault();
				// Раскрыть список
				this.open($this);
			}
		}
	}

	/**
	 * Раскрытие списка
	 * @param  {Object} $element Элемент список
	 */
	open($element) {
		// Раскрытые списки на текущем уровне
		const $expandedSiblings = $element
			.closest(`.js-${this.menuType}-item`)
			.siblings()
			.find(`.js-${this.menuType}-link.is-expand`);
		// Эффект и продолжительность анимации
		const {openEffect, openDuration} = this.getOpenAnimation($element);

		if ($expandedSiblings.length) {
			// Закрыть списки на текущем уровне
			this.close($expandedSiblings, true);
		}

		// Если слушатель клика по документу не провешен
		if (!this.documentClickListen) {
			// Провесить слушателя клика по документу
			$(document).on(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = true;
		}

		// Раскрыть список
		$element
			.addClass('is-expand')
			.siblings(this.listClass)
			.velocity(openEffect, {
				duration: openDuration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenStart`, $(element)),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenEnd`, $(element))
			});
	}

	/**
	 * Закрытие списка
	 * @param  {Object}  $element      Элемент список
	 * @param  {Boolean} closeForNext  Делается ли закрытие списка перед открытием следующего
	 */
	close($element, closeForNext) {
		// Эффект и продолжительность анимации
		const {closeEffect, closeDuration} = this.getCloseAnimation($element, closeForNext);

		// Закрыть список
		$element
			.removeClass('is-expand')
			.siblings(this.listClass)
			.velocity(closeEffect, {
				duration: closeDuration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseStart`, $(element)),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseEnd`, $(element))
			});

		// Если слушатель клика по документу провешен и у всех пунктов списки не раскрыты
		// и закрытие делается не перед открытием следующего
		if (this.documentClickListen && !this.$links.hasClass('is-expand') && !closeForNext) {
			// Снять слушателя клика по документу
			$(document).off(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = false;
		}
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * @param  {Object} event Событие
	 */
	onDocumentListener(event) {
		// Если текущий элемент вне меню
		if (!$(event.target).closest(this.$menu).length) {
			const $expandedElements = this.$menu.find('.is-expand');
			// Закрыть все раскрытые списки
			this.close($expandedElements);
		}
	}

	/**
	 * Инициализировать наблюдателя состояния фиксирующегося меню
	 * Состояния:
	 * - notInited - не был инициализирован. Требуется инициализации;
	 * - waitInit - ожидает инициализации. Ждет необходимую ширину экрана;
	 * - inited - инициализирован;
	 * - disabled - отключен, но ранее был инициализирован.
	 */
	initFixMenuWatcher() {
		// Если ширина окна больше > ширины подключения мобильной версии,
		// то выставить состояние notInited, иначе waitInit
		let watchState = window.innerWidth > this.menuDisableBreakpoint ? 'notInited' : 'waitInit';

		if (watchState == 'notInited') {
			watchState = 'inited';
			// Объявить переменные необходимые для работы фиксированного меню
			this.defineFixedMenuValues();
			// Инициализировать фиксирующееся меню
			this.initFixMenu();
		}

		$(window).resize(() => {
			if (window.innerWidth > this.menuDisableBreakpoint && watchState == 'waitInit') {
				watchState = 'inited';
				this.defineFixedMenuValues();
				this.initFixMenu();
			} else if (watchState == 'disabled' && window.innerWidth > this.menuDisableBreakpoint) {
				watchState = 'inited';
				this.initFixMenu();
			} else if (watchState == 'inited' && window.innerWidth <= this.menuDisableBreakpoint) {
				watchState = 'disabled';
				// Если меню зафиксировано
				if (this.menuFixed) {
					// Расфиксировать меню
					this.unfixMenu();
				}
				// Отключить обработчик событий onFixMenuToggle по скролу
				$(window).off('scroll', $.proxy(this, 'onFixMenuToggle'));
				// Отключить обработчик событий onResizeRepositionMenu по ресайзу
				$(window).off('resize', $.proxy(this, 'onResizeRepositionMenu'));
			}
		});
	}

	/**
	 * Объявление переменных необходимых для работы фиксированного меню
	 */
	defineFixedMenuValues() {
		// Контейнер меню
		this.$menuContainer = this.$menu.parent();
		// Зафиксировано меню или нет
		this.menuFixed = this.$menu.css('position') == 'fixed' ? true : false;
		// Коордианата сверху относительно документа
		this.menuTop = this.$menuContainer.offset().top;
		// Коордианата слева относительно документа
		this.menuLeft = this.$menuContainer.offset().left;
		// Ширина меню
		this.menuWidth = this.$menuContainer.width();
		// Высота меню
		this.menuHeight = this.$menu.height();
		// Значение на сколько был совершен скрол по горизонтали
		this.scrollLeft = window.scrollX;

		// Задать высоту контейнеру равную высоте меню,
		// чтобы при фиксировании / расфиксировании не было скачков
		this.$menuContainer.height(this.menuHeight);
	}

	/**
	 * Инициализация фиксирующегося меню
	 */
	initFixMenu() {
		// Провесить обработчик событий onFixMenuToggle по скролу
		$(window).on('scroll', $.proxy(this, 'onFixMenuToggle'));
		// Провесить обработчик событий onResizeRepositionMenu по ресайзу
		$(window).on('resize', $.proxy(this, 'onResizeRepositionMenu'));

		// Обновить размеры и положение меню
		this.resizeRepositionMenu();
		// Обновить размеры и положение меню
		this.fixMenuToggle();

		// Если меню зафиксировано
		if (this.menuFixed) {
			// Показать зафиксированное меню
			this.slideDownFixedMenu();
		}
	}

	/**
	 * Инициализировать переключение состояния
	 */
	onFixMenuToggle() {
		throttle(100, this.fixMenuToggle());
	}

	/**
	 * Инициализировать ресайз и изменение позиции
	 */
	onResizeRepositionMenu() {
		throttle(100, this.resizeRepositionMenu());
	}

	/**
	 * Показать зафиксированное меню
	 * (если фиксация произошла по загрузке страницы или при ресайзе)
	 */
	slideDownFixedMenu() {
		// Значение top меню в зафиксированном состоянии
		const fixedMenuTopValue = parseInt(this.$menu.css('top'), 10) || 0;
		// Скрытая часть меню (if > 0)
		const menuHiddenPart = this.menuHeight - ($(window).scrollTop() - this.menuTop);

		this.$menu
			// Сместить вверх на скрытую часть / всю высоту
			.css({
				top: fixedMenuTopValue - this.menuHeight + (menuHiddenPart > 0 ? menuHiddenPart : 0)
			})
			// Плавно сместить к верху окна
			.velocity({top: fixedMenuTopValue}, 250, 'ease');
	}

	/**
	 * Зафиксировать меню
	 */
	fixMenu() {
		this.menuFixed = true;
		this.$menu
			.addClass('is-fixed')
			.css({
				// Выставить ширину, т.к элемент становится не относительным контейнера
				width: this.menuWidth,
				// Если произошел скрол по горизонтали, то выставить смещение слева с его учетом
				left: this.scrollLeft > 0 ? this.menuLeft - this.scrollLeft : false
			});
	}

	/**
	 * Расфиксировать меню
	 */
	unfixMenu() {
		this.menuFixed = false;
		this.$menu
			.removeClass('is-fixed')
			.css({
				width: 'auto',
				left: 'auto'
			});
	}

	/**
	 * Выставить смещение слева с учетом изменения значения горизонтального скрола
	 */
	setLeftByScrollLeft() {
		// Если зачение скрола изменилось
		if (this.scrollLeft !== window.scrollX) {
			// Обновить значение в переменной
			this.scrollLeft = window.scrollX;

			// Если меню зафиксировано
			if (this.menuFixed) {
				// Выставить смещение слева с учетом значения горизонтального скрола
				this.$menu.css({left: this.menuLeft - this.scrollLeft});
			}
		}
	}

	/**
	 * Переключение состояния фиксации меню
	 */
	fixMenuToggle() {
		// Выставить смещение слева по скролу
		this.setLeftByScrollLeft();

		// Если дистанция от верха документа до верха окна > дистанции от верха документа до меню
		// и меню не зафиксировано
		if ($(window).scrollTop() > this.menuTop && !this.menuFixed) {
			// Зафиксировать меню
			this.fixMenu();
		} else if ($(window).scrollTop() <= this.menuTop && this.menuFixed) { // Иначе если
			// дистанция от верха документа до верха окна <= дистанции от верха документа до меню

			// Расфиксировать меню
			this.unfixMenu();
		}
	}

	/**
	 * Обновление размеров и положения меню
	 */
	resizeRepositionMenu() {
		// Текущая ширина контейнера меню
		const updatedMenuWidth = this.$menuContainer.width();
		// Текущая коордианата контейнера меню слева относительно документа
		const updatedMenuLeft = this.$menuContainer.offset().left;
		// Обновить коордианата контейнера меню сверху относительно документа
		this.menuTop = this.$menuContainer.offset().top;

		// Если меню зафиксировано
		if (this.menuFixed) {
			// Если смещение контейнера меню слева изменилось
			if (updatedMenuLeft !== this.menuLeft) {
				// Обновить смещение меню слева с учетом значения горизонтального скрола
				this.$menu.css({left: updatedMenuLeft - this.scrollLeft});
			}

			// Если ширина меню изменилась
			if (this.menuWidth !== updatedMenuWidth) {
				// Обновить переменную с шириной меню
				this.$menu.width(updatedMenuWidth);
			}

			// Текущая высота меню
			const updatedMenuHeight = this.$menu.height();

			// Если высота меню изменилась
			if (this.menuHeight !== updatedMenuHeight) {
				// Обновить переменную с высотой меню
				this.menuHeight = updatedMenuHeight;
				// Обновить высоту контейнера меню
				this.$menuContainer.height(this.menuHeight);
			}
		}

		// Обновить переменную с коордианатой меню слева относительно документа
		this.menuLeft = updatedMenuLeft;
		// Обновить переменную с шириной меню
		this.menuWidth = updatedMenuWidth;
	}
}

module.exports = CMenu;
