/**
 * Класс реализует базовую логику для компонента graphic
 */
class CGraphic {
	constructor() {

	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {

	}
}

module.exports = CGraphic;

AR.waitComponents(['cCaptcha_base', 'cNotification_base', 'cPopup_base'], () => {
	const cGraphic = new CGraphic();
	// Вызов метода со всеми событиями
	cGraphic.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cGraphic, 'cGraphic');
});
