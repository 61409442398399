module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'buttons-panel/in-column' && templateVars.config ?
				templateVars.config :
				{
	"modifier": "in-column",
	"ifEmptyPanel": "remove",
	"animation": {
		"list": {
			"show": {
				"effect": "transition.slideLeftIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideLeftOut",
				"duration": 200
			}
		},
		"sublist": {
			"show": {
				"effect": "slideDown",
				"duration": 200
			},
			"hide": {
				"effect": "slideUp",
				"duration": 200
			}
		}
	},
	"events": {
		"list": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		},
		"sublist": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		}
	},
	"hoverablePanel": false,
	"hoverableSublist": false,
	"tools": {
		"panelType": "compact",
		"title": {
			"ru": "Инструменты",
			"en": "Tools"
		},
		"sublistSlideType": "bottom",
		"InMyReport": false,
		"buttonWithText": false,
		"offlineHideButtons": [
			"feedback",
			"share",
			"social"
		],
		"buttonsPanelHideButtons": [
			"glossary",
			"disclaimer",
			"archive"
		],
		"hideButtonsOnSystem": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf",
			"share"
		],
		"hideButtonsOnMainPage": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf"
		],
		"hideCurrentButtonOnPage": true,
		"showTour": true,
		"sorting": {
			"ru": [
				"analysis",
				"pdf",
				"myReport",
				"addToReport",
				"delFromReport",
				"download",
				"share",
				"history",
				"printVersion",
				"siteMap",
				"feedback",
				"compare"
			],
			"en": [
				"analysis",
				"pdf",
				"myReport",
				"addToReport",
				"delFromReport",
				"download",
				"share",
				"history",
				"printVersion",
				"siteMap",
				"feedback",
				"compare"
			]
		},
		"links": [
			{
				"id": "myReport",
				"modifier": "myreport",
				"targetBlank": false,
				"url": "${rootUrl}/my-report",
				"text": {
					"ru": "Мой отчет",
					"en": "My report"
				}
			},
			{
				"id": "addToReport",
				"modifier": "add js-add-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Добавить в «Мой отчет»",
					"en": "Add to My Report"
				}
			},
			{
				"id": "delFromReport",
				"modifier": "del js-del-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Удалить из «Моего отчета»",
					"en": "Delete from My Report"
				}
			},
			{
				"id": "printVersion",
				"modifier": "print js-print",
				"targetBlank": true,
				"url": "javascript:;",
				"text": {
					"ru": "Печать страницы",
					"en": "Print version"
				},
				"event": "window.print();"
			},
			{
				"id": "pdf",
				"modifier": "pdf js-get-pdf",
				"targetBlank": true,
				"url": "${pdf}",
				"text": {
					"ru": "Скачать страницу в PDF",
					"en": "PDF version"
				}
			},
			{
				"id": "share",
				"modifier": "share js-share",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Поделиться",
					"en": "Share"
				},
				"subList": [
					{
						"id": "shareVkontakte",
						"modifier": "vkontakte",
						"targetBlank": true,
						"url": "http://vkontakte.ru/share.php?url={{pageUrl}}&title={{pageTitle}}&image={{thisSiteUrl}}&description=",
						"text": {
							"ru": "Вконтакте",
							"en": "Vkontakte"
						}
					},
					{
						"id": "shareFacebook",
						"modifier": "facebook",
						"targetBlank": true,
						"url": "https://www.facebook.com/sharer/sharer.php?u={{pageUrl}}&t={{pageTitle}}",
						"text": {
							"ru": "Facebook",
							"en": "Facebook"
						}
					},
					{
						"id": "shareTwitter",
						"modifier": "twitter",
						"targetBlank": true,
						"url": "http://twitter.com/share?text={{pageTitle}}&url={{pageUrl}}",
						"text": {
							"ru": "Twitter",
							"en": "Twitter"
						}
					},
					{
						"id": "shareEmail",
						"modifier": "email js-share-form-button",
						"targetBlank": false,
						"url": "",
						"text": {
							"ru": "E-mail",
							"en": "E-mail"
						}
					}
				]
			},
			{
				"id": "history",
				"modifier": "history",
				"targetBlank": false,
				"url": "${rootUrl}/history",
				"text": {
					"ru": "История",
					"en": "Browsing history"
				}
			},
			{
				"id": "feedback",
				"modifier": "feedback js-feedback-form-button",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Обратная связь",
					"en": "Feedback"
				}
			},
			{
				"id": "download",
				"modifier": "download",
				"targetBlank": false,
				"url": "${rootUrl}/downloads",
				"text": {
					"ru": "Центр загрузки",
					"en": "Download center"
				}
			},
			{
				"id": "analysis",
				"modifier": "analysis",
				"targetBlank": false,
				"url": "${rootUrl}/interactive-analysis",
				"text": {
					"ru": "Интерактивный анализ",
					"en": "Interactive analysis"
				}
			},
			{
				"id": "compare",
				"modifier": "compare",
				"targetBlank": true,
				"url": "${rootUrl}",
				"text": {
					"ru": {
						"compare": "",
						"see": "",
						"yearText": "",
						"compareList": "Предыдущие отчеты",
						"seeList": "Смотреть предыдущие отчеты",
						"previousReports": "Предыдущие отчеты"
					},
					"en": {
						"compare": "",
						"see": "",
						"yearText": "",
						"compareList": "Previous reports",
						"seeList": "See previous reports",
						"previousReports": "Previous reports"
					}
				}
			},
			{
				"id": "siteMap",
				"modifier": "site-map",
				"url": "${rootUrl}/sitemap",
				"text": {
					"ru": "Карта сайта",
					"en": "Sitemap"
				}
			},
			{
				"id": "glossary",
				"modifier": "glossary",
				"targetBlank": false,
				"url": "${rootUrl}/glossary/",
				"text": {
					"ru": "Глоссарий",
					"en": "Glossary"
				}
			},
			{
				"id": "archive",
				"modifier": "archive-reports",
				"targetBlank": false,
				"url": "${rootUrl}/archive/",
				"text": {
					"ru": "Предыдущие отчеты",
					"en": "Previous reports"
				}
			},
			{
				"id": "disclaimer",
				"modifier": "disclaimer",
				"targetBlank": false,
				"url": "${rootUrl}/disclaimer/",
				"text": {
					"ru": "Ограничение ответственности",
					"en": "Disclaimer"
				}
			}
		]
	},
	"download-pdf": {
		"panelType": "compact",
		"title": {
			"ru": "Скачать PDF",
			"en": "Download PDF"
		},
		"slideType": "vertical",
		"buttonWithText": true,
		"offlineHideButtons": [],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"pdfPages",
				"pdfSpreads"
			],
			"en": [
				"pdfPages",
				"pdfSpreads"
			]
		},
		"links": [
			{
				"id": "pdfPages",
				"modifier": "pdf-pages",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_pages_nornik_2019.pdf",
				"text": {
					"ru": "Постранично",
					"en": "Page by page"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-pages"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-pages"
					}
				}
			},
			{
				"id": "pdfSpreads",
				"modifier": "pdf-spreads",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_spreads_nornik_2019.pdf",
				"text": {
					"ru": "Разворотами",
					"en": "Spreads"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-spreads"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-spreads"
					}
				}
			}
		]
	}
};