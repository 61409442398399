module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tracking/base' && templateVars.config ?
				templateVars.config :
				{
	"printOnlyGoogle": true,
	"systems": {
		"ar": {
			"ru": {
				"yandex": {
					"id": 62740888,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-T6QSB5S"
				}
			},
			"en": {
				"yandex": {
					"id": 62740888,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-T6QSB5S"
				}
			}
		}
	}
};