module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'svg/base' && templateVars.config ?
				templateVars.config :
				{
	"fontWeights": [
		{
			"weightName": "thin",
			"entryPart": "thin",
			"weight": 100
		},
		{
			"weightName": "extralight",
			"entryPart": "extralight",
			"weight": 200
		},
		{
			"weightName": "light",
			"entryPart": "light",
			"weight": 300
		},
		{
			"weightName": "medium",
			"entryPart": "medium",
			"weight": 500
		},
		{
			"weightName": "semibold",
			"entryPart": "semibold",
			"weight": 600
		},
		{
			"weightName": "bold",
			"entryPart": "bold",
			"weight": 700
		},
		{
			"weightName": "extrabold",
			"entryPart": "extrabold",
			"weight": 800
		},
		{
			"weightName": "black",
			"entryPart": "black",
			"weight": 900
		}
	]
};