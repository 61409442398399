class CStrategyEvolution_acceleration {
	constructor() {}

	init() {
		this.page = $('.product-acceleration');

		if (this.page.length) {

			AR.events.on('svgRenderedAll', () => {
				let windowH = document.documentElement.clientHeight;
				let blocks = $('.js-accel');

				AR
					.components
					.cTooltips_base
					.createTooltips($('#accel_btn'), {
						side: 'bottom',
						content: $('.tooltip-content-1'),
						functionPosition: function (instance, helper, position) {
							position.coord.top -= 8;
							position.coord.left -= (position.size.width / 2 - 8);
							return position;
						}
					}, false);

				$(blocks[0]).addClass('block-show');
				$(blocks[0]).velocity('transition.fadeIn');

				$(blocks[1]).addClass('block-show');
				$(blocks[1]).velocity('transition.slideDownBigIn', {delay: 1000});

				function animateSvg() {
					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper').height();

					$(blocks).each(function (i, block) {
						let offsetTop = $(block).offset().top;

						if (!$(block).hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {

								$(block).addClass('block-show');
								$(block).velocity('transition.slideDownBigIn');
							}
						}
					});

					let check = [...$(blocks)].every(el => {
						return el.classList.contains('block-show');
					});

					if (check) {
						$(window).off('scroll mousewheel', animateSvg);
					}
				}

				$(window).on('scroll mousewheel', animateSvg);

			});
		}
	}
}

AR.waitComponents([], () => {
	const cStrategyEvolution_acceleration = new CStrategyEvolution_acceleration();

	// Вызов метода, инициализирующего все существующие события
	cStrategyEvolution_acceleration.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cStrategyEvolution_acceleration, 'cStrategyEvolution_acceleration');
});
