const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
const CCookieLaw = require('../../index.js');
const cookie = require('cookie_js');

/**
 * Реализует логику варианта double-buttons компонента cookie-law
 */
class CCookieLaw_double extends CCookieLaw {
	constructor(config, text) {
		super(config, text);
	}

	// Инициализация всех существующих событий (клик на кнопки - "Я принимаю", "Подробнее", появление блока при готовом документе)
	init() {
		return new Promise((resolve) => {
			// Если куки с названием - "shown_cookies_law" не имеет параметр - true, и в структуре не стоит параметр запрещающий показ плашки
			if (cookie('shown_cookies_law') != 'true' && !templateVars.page.disableCookieLaw) {
				// После того, как документ будет готов
				$('document').ready(() => {
					// Получить ссылку, для кнопки - "Подробнее"
					this.moreLink = this.getUrl(this.config.linkToPage);

					// Получаем шаблон (данная переменная используется в качестве параметра при генерации шаблона в базовом классе - CCookieLaw)
					const notyTemplate = (options) => {
						return `<div class="b-noty__body noty_body">
						<span class="b-noty__title">${options.title}</span>
						<span class="b-noty__text">${options.message}</span>
						<span class="b-noty__buttons">
							<button class="btn b-cookie-law__btn b-cookie-law__btn--apply js-apply-btn">${options.buttonApplyText}</button>
							<a href="${this.moreLink}" class="btn b-cookie-law__btn b-cookie-law__btn--also js-also-btn">${options.buttonAlsoText}</a>
						</span>
					</div>`;
					};
					// Вызвать метод - createNoty (формирование шаблона блока)
					this.createNoty(notyTemplate)
						.then(() => {
							// Получаем соданную после инициализации плашку
							this.$cookieLawPlate = $('#cookie-law');

							if (this.$cookieLawPlate.length) {
								// Получить кнопку - "Подробнее"
								this.$alsoButton = this.$cookieLawPlate.find('.js-also-btn');
								// Получить кнопку - "Я принимаю"
								this.$applyButton = this.$cookieLawPlate.find('.js-apply-btn');
								// Провесить обработчик для кнопки - "Я принимаю"
								this.$applyButton.on('click', $.proxy(this, 'onClickApplyButton'));
								// Провесить обработчик для кнопки - "Подробнее"
								this.$alsoButton.on('click', $.proxy(this, 'onClickAlsoButton'));
							}

							resolve();
						});
				});
			} else {
				resolve();
			}
		});
	}
}

AR.waitComponents(['cNotification_base'], () => {
	const cCookieLaw_double = new CCookieLaw_double(config, text);
	// Вызов метода, инициализирующего все существующие события
	cCookieLaw_double
		.init()
		.then(() => {
			// Добавление в глобальный объект AR.components
			AR.pushComponent(cCookieLaw_double, 'cCookieLaw_double');
		});
});
