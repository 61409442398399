/**
 * Trigger a callback when 'this' image is loaded:
 * @param {Function} callback
 from http://stackoverflow.com/questions/3877027/jquery-callback-on-image-load-even-when-the-image-is-cached/20351043#20351043
 */
global.jQuery.fn.imgLoad = function (callback) {
	return this.each(function () {
		if (callback) {
			if (this.complete || /*for IE 10-*/ $(this).height() > 0) {
				callback.apply(this);
			} else {
				$(this).on('load', function () {
					callback.apply(this);
				});
			}
		}
	});
};
