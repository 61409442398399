class CStrategyEvolution_ecology {
	constructor() {}

	init() {

		this.page = $('.safety-ecology');
		if (this.page.length) {

			AR.events.on('svgRenderedAll', () => {
				let windowH = document.documentElement.clientHeight;
				let blocks = $('.js-ecology');

				this.creatTooltips();

				// border и title первой SVG
				let border = $('#ecology-1-border');
				let title = $('#ecology-1-title');
				border
					.velocity({
						opacity: 0
					}, {
						display: 'none'
					})
					.velocity('fadeIn', {
						delay: 0,
						duration: 1000,
					});

				title
					.velocity({
						opacity: 0
					}, {
						display: 'none'
					})
					.velocity('fadeIn', {
						delay: 500,
						duration: 1000,
					});

				// border и title второй SVG
				let $ecologyBox2 = $('.js-ecology-box2').get().reverse();

				$(blocks[0]).addClass('block-show');
				$(blocks[0]).velocity('transition.slideDownBigIn', {delay: 1000});

				$(window).on('scroll mousewheel', animateSvg);

				function animateSvg() {
					let scrollTop = Boolean(window.bodyInstance) ? window.bodyInstance.scroll().position.y : $(window).scrollTop();
					let layoutH = $('#layout-wrapper').height();

					$(blocks).each(function (i, block) {
						let offsetTop = $(block).offset().top;

						if (!$(block).hasClass('block-show')) {
							if (offsetTop < (windowH / 1.5) || (layoutH - (scrollTop + windowH)) < 10) {
								$(block).addClass('block-show');
								$(block).velocity('transition.slideDownBigIn');
							}
						}


						let check;

						if ($('html').hasClass('browser-ie')) {
							blocks.each(function () {
								check = $(this).hasClass('block-show');
							});
						} else {
							check = [...blocks].every(el => {//IE не переваривает такие конструкции
								//console.log(el.classList);
								return el.classList.contains('block-show');
							});
						}

						if (check) {
							$(window).off('scroll mousewheel', animateSvg);
						}
					});

					if ($ecologyBox2.length) {
						$ecologyBox2.forEach(function (el, i) {
							let offsetTop = $('.js-nort-prog__border').offset().top;
							let $block = $(el);

							if (!$block.hasClass('block-show')) {
								if ((offsetTop < (windowH / 1.5)) || (layoutH - (scrollTop + windowH)) < 10) {
									$block
										.velocity('fadeIn', {
											delay: i * 500,
											duration: 1500,
										});

									$block.addClass('block-show');
								}
							}
						});
					}
				}
			});
		}
	}

	creatTooltips() {
		let tooltips = [
			{
				btn: '#ecology-1-btn',
				content: '.js-tool-cont__eco-1-btn'
			},
			{
				btn: '#ecology-21-btn-1',
				content: '.js-tool-cont__eco-21-btn-1'
			},
			{
				btn: '#ecology-21-btn-2',
				content: '.js-tool-cont__eco-21-btn-2'
			},
			{
				btn: '#ecology-21-btn-3',
				content: '.js-tool-cont__eco-21-btn-3'
			},
			{
				btn: '#ecology-22-btn-1',
				content: '.js-tool-cont__eco-22-btn-1'
			},
			{
				btn: '#ecology-22-btn-2',
				content: '.js-tool-cont__eco-22-btn-2'
			},
		];

		$.each(tooltips, function (i, tool) {
			AR
				.components
				.cTooltips_base
				.createTooltips($(tool.btn), {
					side: 'bottom',
					content: $(tool.content),
					functionPosition: function (instance, helper, position) {
						position.coord.top -= 8;
						position.coord.left -= (position.size.width / 2 - 8);
						return position;
					}
				}, false);
		});
	}
}

AR.waitComponents(['cTooltips_base'], () => {
	const cStrategyEvolution_ecology = new CStrategyEvolution_ecology();

	// Вызов метода, инициализирующего все существующие события
	cStrategyEvolution_ecology.init();

	// Добавление в глобальный объект AR.components
	AR.pushComponent(cStrategyEvolution_ecology, 'cStrategyEvolution_ecology');
});
