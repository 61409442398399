/**
 * Класс реализует базовую логику для компонента tracking
 */
class CTracking {
	constructor() {

	}

	/**
	 * Выводить сообщение об ошибке в консль
	 * @param msg - текст сообщения
	 * @param force - выводить ли сообщение, несмотря на production-mode
	 */
	sendError(msg, force = false) {
		if ((!templateVars.production && !templateVars.test && !templateVars.offline) || force) {
			console.error(`[${this.constructor.name}]:`, msg);
		}
	}

	/**
	 * Выводить сообщение в консль
	 * @param msg - текст сообщения
	 * @param force - выводить ли сообщение, несмотря на production-mode
	 */
	sendMessage(msg, force = false) {
		if ((!templateVars.production && !templateVars.test && !templateVars.offline) || force) {
			console.warn(`[${this.constructor.name}]:`, msg);
		}
	}
}

module.exports = CTracking;

AR.waitComponents([], () => {
	const cTracking = new CTracking();

	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTracking, 'cTracking');
});
