const Emitter = require('component-emitter');
const Promise = require('bluebird');

//Методы для проверки положения объекта в поле видимости
const checkViewport = require('./additionals/viewport.js');

//Вызов коллбека при загрузке изображений
require('./additionals/imageLoad.js');
//Снимаем фокус с элемента после click
require('./additionals/blurOnLeave.js');
//Обработка ссылок на странице
require('./additionals/links.js');
//Удаление классов по маске
require('./additionals/removeClassWild.js');
//Обработка состояния focus на кнопках
require('./additionals/buttonsCleanFocus.js');

const AR = {
	//Язык страницы (en, ru)
	lang: templateVars.lang,
	//Поддерживаемые фичи
	features: {
		svg: $('html').hasClass('feature-svg')
	},
	//Хранилище компонентов
	components: {},
	tools: {
		//клонирование объекта без привязки по ссылке
		cloneObject: function (object = {}) {
			return JSON.parse(JSON.stringify(object));
		},
		//адаптивный ли сайт
		isResponsive: () => Boolean(templateVars.responsive),
		//проверка  на попадание элементов в область просмотра пользователя
		checkViewport: checkViewport
	},
	/**
	 * Локализованый текст
	 * @param  {Object} textObj Текст на нескольких языках в формате {ru: 'русский', en: 'english'}
	 * @return {String} Строка на текущем языке
	 */
	local: (textObj) => {
		return textObj[AR.lang];
	},
	/**
	 * Добавление инстаса компонента в глабольное хранилище компонентов
	 * @param component - инстанс компонента
	 * @param id - id компонента
	 */
	pushComponent: (component, id) => {
		if (!AR.components[id]) {
			AR.components[id] = component;

			AR.events.emit(`on${id.charAt(0).toUpperCase() + id.slice(1)}HasBeenCreated`, component);
		} else {
			if (!templateVars.production) {
				console.error(`Компонент с ID ${id} уже добавлен`);
			}
		}
	},
	/**
	 * Обертка для ожидания подгрузки переданных компонентов.
	 * @param components - список компонентов, например ['cNotification_base', 'cLogo_base']
	 * @param callback - вызывается после подгрузки всех переданных компонентов
	 */
	waitComponents: (components = [], callback) => {
		if (components.length) {
			const componentsPromises = [];

			//обходим массив всех переданных компонентов
			components.forEach((id) => {
				//если компоненту еще не загружен
				if (!AR.components[id]) {
					//завязываем промис на событие его загрузки
					const wait = new Promise((resolve, reject) => {
						AR.events.on(`on${id.charAt(0).toUpperCase() + id.slice(1)}HasBeenCreated`, (component) => {
							resolve();
						});

						AR.events.on(`on${id.charAt(0).toUpperCase() + id.slice(1)}HasBeenFailed`, (component) => {
							reject();
						});
					});

					componentsPromises.push(wait);
				}
			});

			if (componentsPromises.length) {
				//ждем пока загрузятся все необходимые компоненты
				Promise.all(componentsPromises)
					.catch((error) => {
						if (!templateVars.production) {
							console.error(error);
						}
					})
					.finally(() => {
						callback();
					})
					.catch((error) => {
						if (!templateVars.production) {
							console.error(error);
						}
					});
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	//Интерфейс для работы с событиями
	events: new Emitter,
	// Получить dom элемент или nodelist в виде массива
	getElemsArr: (elements) => {
		if (elements) {
			if (typeof elements !== 'string' && elements.length) {
				return Array.from(elements);
			} else {
				return [elements];
			}
		} else {
			return [];
		}
	},
	// Рекурсивное объединение объектов
	rExtend: function () {
		for (var i = 1; i < arguments.length; i++) {
			for (var key in arguments[i]) {
				if (arguments[i].hasOwnProperty(key)) {
					if (typeof arguments[0][key] === 'object' && typeof arguments[i][key] === 'object') {
						AR.rExtend(arguments[0][key], arguments[i][key]);
					} else {
						arguments[0][key] = arguments[i][key];
					}
				}
			}
		}
		return arguments[0];
	},
	getEsm(module) {
		if (typeof module.default && module !== 'function') {
			return module.default;
		}
		return module;
	}
};

AR.events.on('svgRenderedAll', checkViewport);

// Вынос модуля в global
global.AR = AR;
module.exports = AR;
