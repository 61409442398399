/**
 * Класс реализует базовую логику для компонента captcha
 */
class CCaptcha {
	constructor() {}

	addValidator() {
		window.Parsley
			.addValidator('captcha', {
				requirementType: 'boolean',
				validateString: function (value, requirement) {
					return requirement;
				}
			});
	}
}

module.exports = CCaptcha;
