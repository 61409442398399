const text = require('./text.yaml')[templateVars.lang];
const CFigureButtons = require('../../index.js');
const config = require('./config.yaml');

/**
 * Класс реализует вариант компонета base для компонента figure-buttons
 */
class CFigureButtons_base extends CFigureButtons {
	constructor() {
		super();
		this.text = text;
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		if (this.config.createTooltips) {
			AR.events.on('onButtonHasAdded', ([$button, type]) => {
				// по загрузке документа
				// простановка тултипов
				if (AR.components.cTooltips_base) {
					this.placeTooltip($button, type);
				} else {
					AR.events.on('onCTooltips_baseHasBeenCreated', () => {
						this.placeTooltip($button, type);
					});
				}
			});
		}
	}

	/**
	 * Добавить тултип на кнопку
	 * @param {object} $element кнопка
	 * @param {string} type тип кнопки
	 */
	placeTooltip($element, type) {
		const tooltipsterConfig = {
			content: this.text[`${type}Button`],
			...this.config.tooltipster
		};

		AR
			.components
			.cTooltips_base
			.createTooltips($element, tooltipsterConfig, false);
	}
}

AR.waitComponents([], () => {
	const cFigureButtons_base = new CFigureButtons_base();
	// Вызов метода со всеми событиями
	cFigureButtons_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cFigureButtons_base, 'cFigureButtons_base');
});
